import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-select-fi-fc',
    templateUrl: './select-fi-fc.component.html',
    styleUrls: ['./select-fi-fc.component.scss']
})
export class SelectFIFCComponent {
    constructor(public dialogRef: MatDialogRef<SelectFIFCComponent>) {}

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog(fi: boolean) {
        this.dialogRef.close(fi);
    }
}
