import {
    Component,
    Input,
    OnInit,
    OnChanges,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { AssignmentService } from '../../../../services/assignment.service';
import { ConfigService } from '../../../../services/config.service';
import { DialogService } from '../../../../services/dialog.service';
import { FavoriteService } from '../../../../services/favorite.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { LibraryService } from '../../../../services/library.service';
import { LoginService } from '../../../../services/login.service';

import { Content } from '../../../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-content-update',
    templateUrl: './content-update.component.html',
    styleUrls: ['./content-update.component.scss']
})
export class ContentUpdateComponent implements OnInit, OnChanges {
    @Input() draft: Content;
    @Input() parent: Content;
    @Input() index: number;
    @Input() locked = false;
    @Input() editionOrderMode: boolean;
    @Input() search: string;

    @Output() clonedUpdate: EventEmitter<Content> = new EventEmitter();
    @Output() removedChild: EventEmitter<Content> = new EventEmitter();

    subscriptions = new Subscription();
    opened: boolean;
    openedBySearch: boolean;

    constructor(
        private assignmentService: AssignmentService,
        private configService: ConfigService,
        private dialogService: DialogService,
        private favoriteService: FavoriteService,
        private flashMessageService: FlashMessageService,
        private libraryService: LibraryService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.editionOrderMode = false;
        if (this.parent) {
            this.draft.parentid = this.parent.id;
        }
        this.subscriptions.add(
            this.libraryService.foldAll.subscribe(() => {
                this.opened = false;
                this.openedBySearch = false;
            })
        );
        if (this.draft.status === 'approved') {
            this.locked = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.editionOrderMode &&
            changes.editionOrderMode.previousValue !== undefined &&
            changes.editionOrderMode.previousValue !== changes.editionOrderMode.currentValue
        ) {
            if (changes.editionOrderMode.currentValue) {
                if (!this.draft.orderNumber) {
                    this.draft.orderNumber = this.index + 1;
                }
            }
        }
        if (this.childrenMatchSearch(this.draft.children)) {
            this.openedBySearch = true;
        } else {
            this.openedBySearch = false;
        }
    }

    isSecondLevel() {
        return this.parent && !this.parent.parentid;
    }

    isFirstElement(index: number): boolean {
        return index === 0;
    }

    isLocked() {
        return this.locked;
    }

    isFirstLevel() {
        return !this.parent;
    }

    isActivity(): boolean {
        return !this.draft.level;
    }

    displayElement(content: Content) {
        if (!this.search) {
            return true;
        } else {
            return this.matchSearch(content) || this.childrenMatchSearch(content.children);
        }
    }

    childrenMatchSearch(contents: Array<Content>) {
        if (!this.search) {
            return false;
        }
        for (const content of contents) {
            if (
                content.title.toLowerCase().includes(this.search.toLowerCase()) ||
                content.description.toLowerCase().includes(this.search.toLowerCase()) ||
                content.keywords.findIndex(
                    (keyword) => keyword.toLowerCase() === this.search.toLowerCase()
                ) !== -1
            ) {
                return true;
            } else {
                if (this.childrenMatchSearch(content.children)) {
                    return true;
                }
            }
        }
        return false;
    }

    matchSearch(content: Content): boolean {
        if (!this.search) {
            return false;
        }
        if (
            content.title.toLowerCase().includes(this.search.toLowerCase()) ||
            content.description.toLowerCase().includes(this.search.toLowerCase()) ||
            content.keywords.findIndex(
                (keyword) => keyword.toLowerCase() === this.search.toLowerCase()
            ) !== -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    getIcon(): string {
        if (this.draft.status === 'approved') {
            return this.libraryService.getIcon(this.draft);
        } else {
            return this.libraryService.getDraftIcon(this.draft);
        }
    }

    getTypeLabel(): string {
        return this.libraryService.getTypeLabel(this.draft);
    }

    getDraftTooltip(): string {
        return `<strong>Titre : </strong>${this.draft.title}</br>
        ${this.draft.id ? '<strong>ID : </strong>' : ''}${this.draft.id || ''}${
            this.draft.ucode ? '<strong> Code : </strong>' : ''
        }${this.draft.ucode || ''}</br>
        ${this.draft.description ? '<strong>Description : </strong>' : ''}${
            this.draft.description || ''
        }</br>
        ${this.getDuration()}`;
    }

    getDuration() {
        if (
            this.draft.duration !== '00:00' &&
            !!this.draft.duration &&
            (this.draft.level === 'module' || this.draft.level === 'sequence' || !this.draft.level)
        ) {
            const timeArray = this.draft.duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            if (!this.isIncompleteDuration()) {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '')
                );
            } else {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '') +
                    "(durée d'activité(s) manquante(s) dans l'assemblage)"
                );
            }
        }
        return '';
    }

    isIncompleteDuration() {
        if (this.draft.level === 'module' || this.draft.level === 'sequence') {
            return this.checkIncompleteDurationRecursive(this.draft);
        }
        return false;
    }

    checkIncompleteDurationRecursive(entry) {
        if (
            (!entry.duration || entry.duration === '00:00') &&
            (!entry.content_duration || entry.content_duration === '00:00')
        ) {
            return true;
        }
        for (const i in entry.children) {
            if (entry.children[i]) {
                const checkChildrenIncompleteDuration = this.checkIncompleteDurationRecursive(
                    entry.children[i]
                );
                if (checkChildrenIncompleteDuration) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    increaseOrder($event: any) {
        $event.stopImmediatePropagation();
        this.draft.orderNumber++;
    }

    decreaseOrder($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft.orderNumber > 1) {
            this.draft.orderNumber--;
        }
    }

    getFicheTooltip(): string {
        if (this.isFirstLevel()) {
            return 'Editer la fiche';
        } else {
            return 'Voir la fiche';
        }
    }

    showMediaIcon(): boolean {
        return this.isFirstLevel() && (this.draft.type === 'media' || this.draft.type === 'quiz');
    }

    showOrdoOnIcon(): boolean {
        return (
            this.isFirstLevel() &&
            !this.isActivity() &&
            !this.editionOrderMode &&
            this.draft.children &&
            this.draft.children.length > 1
        );
    }

    showOrdoOffIcon(): boolean {
        return (
            this.isFirstLevel() &&
            this.draft.ordered &&
            !this.isActivity() &&
            !this.editionOrderMode
        );
    }

    showCloneIcon(): boolean {
        return this.isFirstLevel();
    }

    canPublishQuiz(): boolean {
        return this.draft.quizIsPublishable !== false;
    }

    canPublish(): boolean {
        return (
            (this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.nationalTeacher ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().additionalRoles.validator ||
                this.loginService.getUser().roles.siteTeacher ||
                !this.isActivity() ||
                this.draft.type === 'devoir') &&
            this.canPublishQuiz() &&
            this.isFirstLevel()
        );
    }

    canSendToValidation(): boolean {
        return (
            !(
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.nationalTeacher ||
                this.loginService.getUser().roles.siteTeacher ||
                this.draft.type === 'devoir'
            ) &&
            this.canPublishQuiz() &&
            this.isFirstLevel() &&
            this.isActivity()
        );
    }

    showHistoryIcon() {
        return this.draft.rejected && this.isFirstLevel();
    }

    viewFiche($event: any) {
        $event.stopImmediatePropagation();
        if (this.isFirstLevel()) {
            this.subscriptions.add(
                this.libraryService.getContent(this.draft.id).subscribe((content: Content) => {
                    this.dialogService.openContentCreation(content);
                })
            );
        } else {
            this.dialogService.openContentDetails(this.draft.id);
        }
    }

    viewGuide($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft.guide) {
            const popup = window.open(this.draft.guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    goToMedia($event: any) {
        $event.stopImmediatePropagation();
        window.open(this.configService.getEasiMediaFrontEndPoint(), '_blank');
    }

    toggleOrdoOn($event: any) {
        $event.stopImmediatePropagation();
        this.draft.ordered = true;
        if (!this.opened) {
            this.getChildren($event);
        }
        this.editionOrderMode = true;
    }

    toggleOrdoOff($event: any) {
        $event.stopImmediatePropagation();
        this.draft.ordered = false;
        if (!this.draft.children) {
            this.subscriptions.add(
                this.libraryService.getContent(this.draft.id).subscribe((data: any) => {
                    this.draft.children = data.children;
                    this.draft.children.map((child: Content) => {
                        delete child.orderNumber;
                        return child;
                    });
                    this.subscriptions.add(
                        this.libraryService.updateAssembly(this.draft, undefined).subscribe(() => {
                            this.editionOrderMode = false;
                            this.flashMessageService.flash(
                                "L'ordonnancement du contenu a été supprimé"
                            );
                        })
                    );
                })
            );
        } else {
            this.draft.children.map((child: Content) => {
                delete child.orderNumber;
                return child;
            });
            this.subscriptions.add(
                this.libraryService.updateAssembly(this.draft, undefined).subscribe(() => {
                    this.editionOrderMode = false;
                    this.flashMessageService.flash("L'ordonnancement du contenu a été supprimé");
                })
            );
        }
    }

    getOrdoTooltip() {
        if (!this.draft.ordered) {
            return 'Ordonner le contenu';
        } else {
            return "Modifier l'ordonnancement";
        }
    }

    cloneDraft($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.libraryService.duplicateDraft(this.draft).subscribe((data: any) => {
                this.clonedUpdate.emit({
                    ...this.draft,
                    id: data.id,
                    desiredPublicationMode: data.desiredPublicationMode,
                    title: 'Copie de ' + this.draft.title,
                    favorite: false
                });
                this.flashMessageService.flash(
                    "Le contenu a été dupliqué dans l'espace de création"
                );
            })
        );
    }

    deleteDraft($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService
                .openConfirmationDialog(
                    'Supprimer le brouillon',
                    'Etes-vous sûr de vouloir supprimer le brouillon "' + this.draft.title + '" ?'
                )
                .subscribe((data: boolean) => {
                    if (data) {
                        this.subscriptions.add(
                            this.libraryService.deleteDraft(this.draft).subscribe(() => {
                                this.libraryService.emitDeletedDraft(this.draft);

                                this.flashMessageService.flash('Le contenu a été supprimé');
                            })
                        );
                    }
                })
        );
    }

    publishDraft($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft.publishable) {
            this.dialogService.openSelectPublication(this.draft).subscribe((data: any) => {
                if (data) {
                    this.draft.publicationMode = data.publicationMode;
                    this.libraryService.approveContent(this.draft, data.comment).subscribe(() => {
                        this.flashMessageService.flash(
                            'Le contenu a été publié dans la bibliothèque'
                        );
                        this.libraryService.emitDeletedDraft(this.draft);
                        this.libraryService.emitRefreshLibrary();
                    });
                }
            });
        } else {
            this.dialogService
                .openContentCreation({ ...this.draft, with_publish_option: true })
                .subscribe((data: any) => {
                    if (data) {
                        this.dialogService
                            .openSelectPublication(this.draft)
                            .subscribe((confirmation: any) => {
                                if (confirmation) {
                                    this.draft.publicationMode = confirmation.publicationMode;
                                    this.libraryService
                                        .approveContent(this.draft, confirmation.comment)
                                        .subscribe(() => {
                                            this.flashMessageService.flash(
                                                'Le contenu a été publié dans la bibliothèque'
                                            );
                                            this.libraryService.emitDeletedDraft(this.draft);
                                            this.libraryService.emitRefreshLibrary();
                                        });
                                }
                            });
                    }
                });
        }
    }

    sendDraftToValidation($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService.openSelectValidator(this.draft).subscribe((data: any) => {
                if (data) {
                    this.subscriptions.add(
                        this.dialogService
                            .openSelectPublication(this.draft)
                            .subscribe((publicationMode: any) => {
                                if (data && publicationMode) {
                                    const params = {
                                        id: this.draft.id,
                                        publicationMode: publicationMode.publicationMode,
                                        validators: data.validators,
                                        urgent: data.urgent,
                                        comment: data.comment
                                    };
                                    this.subscriptions.add(
                                        this.libraryService
                                            .sendContentToValidation(params)
                                            .subscribe(() => {
                                                this.flashMessageService.flash(
                                                    'Le contenu a été communiqué au(x) valideur(s)'
                                                );
                                                this.libraryService.emitDeletedDraft(this.draft);
                                            })
                                    );
                                }
                            })
                    );
                }
            })
        );
    }

    openHistoryDialog($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentHistory(this.draft);
    }

    removeChild($event: any) {
        $event.stopImmediatePropagation();
        this.removedChild.emit(this.draft);
    }

    deleteChild(childToRemove: Content) {
        const tmpContent = JSON.parse(JSON.stringify(this.draft));
        tmpContent.children.splice(
            tmpContent.children.findIndex((content) => content.id === childToRemove.id),
            1
        );
        this.subscriptions.add(
            this.libraryService.updateAssembly(tmpContent, undefined).subscribe(() => {
                this.draft = tmpContent;
                this.flashMessageService.flash('Contenu retiré');
            })
        );
    }

    addToFavorites($event): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.favoriteService.addContentToFavorites(this.draft).subscribe((data: any) => {
                this.draft.favorite = true;
                this.flashMessageService.flash(
                    `Le contenu ${this.draft.title} a été ajouté à vos favoris`
                );
            })
        );
    }

    removeFromFavorites($event: any): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.favoriteService.removeContentFromFavorites(this.draft).subscribe((data: any) => {
                this.draft.favorite = false;
                this.flashMessageService.flash(
                    `Le contenu ${this.draft.title} a été retiré de vos favoris`
                );
            })
        );
    }

    openActivity($event): void {
        $event.stopImmediatePropagation();
        this.libraryService.openActivity(this.draft);
    }

    editActivity($event): void {
        $event.stopImmediatePropagation();
        window.open(
            `${this.configService.getFrontEndPoint()}/quizEditor/#/${this.draft.id}`,
            '_blank'
        );
    }

    getChildren($event: any) {
        if ($event) {
            $event.stopImmediatePropagation();
        }
        if (!this.draft.children) {
            this.subscriptions.add(
                this.libraryService.getContent(this.draft.id).subscribe((data: any) => {
                    this.draft.children = data.children;
                    this.opened = !this.opened;
                })
            );
        } else {
            this.opened = !this.opened;
        }
    }

    getSortedChildren() {
        if (this.draft.children) {
            return this.draft.children.sort((a: Content, b: Content) => {
                if (a.position < b.position) {
                    return -1;
                }
                if (a.position > b.position) {
                    return 1;
                }
                return 0;
            });
        }
    }

    startDrag() {
        this.assignmentService.setDraggedElement(this.draft);
        this.assignmentService.setDraggedMode('MOVE');
    }

    endDrag() {
        this.assignmentService.clearDraggedElement();
        this.assignmentService.clearDraggedMode();
    }

    preventDrop() {
        if (this.assignmentService.getDraggedElement()) {
            return !this.canDropContent();
        } else {
            return false;
        }
    }

    canDropContent() {
        let canDrop = false;
        if (this.isFirstLevel()) {
            if (this.assignmentService.getDraggedMode() === 'COPY') {
                if (this.assignmentService.getDraggedElement() && !this.draft.hasprice) {
                    return this.assignmentService.canBeChild(this.draft) && !this.locked;
                }
                if (this.assignmentService.getDraggedElement().hasprice === 2) {
                    return (
                        this.assignmentService.canBeChild(this.draft) &&
                        !this.locked &&
                        this.loginService.getUser().roles.nationalAdmin
                    );
                }
            } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
                return this.draft.id === this.assignmentService.getDraggedElement().parentid;
            }
        }
        return false;
    }

    dropContent(index) {
        if (!this.canDropContent()) {
            return;
        }
        const element = {
            ...this.assignmentService.getDraggedElement()
        };
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (!this.draft.children) {
                this.subscriptions.add(
                    this.libraryService.getContent(this.draft.id).subscribe((data: any) => {
                        this.draft.children = data.children;
                        this.addChildrenToDraft(element, index);
                    })
                );
            } else {
                this.addChildrenToDraft(element, index);
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            if (index === undefined) {
                index = this.draft.children.length;
            }
            this.updateChildrenPosition(element, index);
        }
    }

    addChildrenToDraft(element, index) {
        const body = JSON.parse(JSON.stringify(this.draft));
        if (index === undefined) {
            index = this.draft.children.length;
        }
        element.position = this.assignmentService.calculatePosition(this.draft, index);
        if (this.draft.ordered) {
            element.orderNumber = 1;
        }
        body.children.push(element);
        this.subscriptions.add(
            this.libraryService.updateAssembly(body, undefined).subscribe(() => {
                this.draft.children.push(element);
                this.flashMessageService.flash("Le contenu a été ajouté dans l'assemblage");
            })
        );
    }

    updateChildrenPosition(element, index) {
        const position = this.assignmentService.calculatePosition(this.draft, index, element);
        if (position) {
            this.draft.children.map((data: Content) => {
                if (data.id === element.id) {
                    data.position = position;
                }
                return data;
            });
            this.subscriptions.add(
                this.libraryService.updateAssembly(this.draft, undefined).subscribe(() => {
                    this.flashMessageService.flash('Le contenu a été déplacé');
                })
            );
        }
    }

    getForbiddenDropTooltipMessage(): string {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (!this.canDropContent()) {
                if (!this.isFirstLevel()) {
                    return 'Impossible de déplacer ce contenu ici';
                }

                if (
                    this.assignmentService.getDraggedElement().hasprice === 2 &&
                    !this.loginService.getUser().roles.nationalAdmin
                ) {
                    return 'Vous ne pouvez pas utiliser les enfants de contenus payants dans vos créations';
                }
                if (this.locked) {
                    return 'Vous ne pouvez rien déposer sur un contenu issu de la bibliothèque';
                }
                switch (this.draft.category) {
                    case 'positionnement':
                    case 'preparation':
                    case 'ressource':
                    case 'travail':
                    case 'evaluation':
                    case 'corrige':
                    case 'tp':
                    case 'presentiel':
                    case 'guide':
                    case 'url':
                    case 'devoir':
                        return 'Vous ne pouvez rien déposer sur une activité';
                    default: {
                        switch (this.draft.level) {
                            case 'parcours':
                                return "Seuls les blocs, les compétences et les modules peuvent être déposés à la racine d'un parcours";
                            case 'bloc':
                                return "Seuls les compétences et les modules peuvent être déposés à la racine d'un bloc";
                            case 'competence':
                                return "Seuls les modules, les séquences et les activités peuvent être déposés à la racine d'une compétence";
                            case 'module':
                                return 'Seules les séquences et les activités peuvent être déposées dans un module';
                            case 'sequence':
                                return 'Seules les activités peuvent être déposées dans une séquence';
                            case 'activity':
                                return 'Vous ne pouvez rien déposer sur une activité.';
                            default:
                                return undefined;
                        }
                    }
                }
            } else {
                return undefined;
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            if (!this.canDropContent()) {
                return 'Impossible de déplacer ce contenu ici';
            }
        }
    }

    toggleIcon() {
        this.opened = !this.opened;
    }

    getEditionOrderMode() {
        if (this.editionOrderMode && this.isFirstLevel()) {
            return true;
        } else {
            return false;
        }
    }

    confirmOrdo($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.libraryService.updateAssembly(this.draft, undefined).subscribe((data: any) => {
                this.editionOrderMode = false;
                this.flashMessageService.flash("L'ordonnancement du contenu a été mis à jour");
            })
        );
    }

    cancelOrdo($event) {
        $event.stopImmediatePropagation();
        this.draft.children.map((child: Content) => {
            delete child.orderNumber;
            return child;
        });
        this.draft.ordered = false;
        this.editionOrderMode = false;
    }
}
