<div class="nav-menu">
    <div
        class="admin-container"
        *ngFor="let tab of adminTabs"
        [ngClass]="{ active: isActiveTab(tab.key) }"
        (click)="goToTab(tab)"
    >
        <div class="icon-AdminListes"></div>
        <div class="label">{{ tab.label }}</div>
    </div>
</div>
<div class="main-container">
    <app-domains [ngClass]="{ hidden: !isActiveTab('domains') }"></app-domains>
    <app-providers [ngClass]="{ hidden: !isActiveTab('providers') }"></app-providers>
</div>
