import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { LoginService } from './login.service';

import { Content } from '../structures/content';
import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class FavoriteService {
    constructor(private http: HttpClient, private loginService: LoginService) {}

    contentAddedToFavorites: Subject<Content> = new Subject();
    contentRemovedFromFavorites: Subject<Content> = new Subject();

    @log() getFavoritesContents(filters: any) {
        let params: any = {
            offset: filters.offset,
            limit: filters.limit
        };

        if (!filters.localStructure) {
            if (!this.loginService.getUser().roles.nationalAdmin) {
                params.localStructure = '|' + this.loginService.getUser().localStructure;
            }
        }

        params = this.parseParamsForGetContents(filters, params);

        return this.http.get(`/users/favorite/content`, { params });
    }

    @log() getFavoritesContentsCount(filters: any) {
        let params: any = {
            offset: filters.offset,
            limit: filters.limit
        };

        if (!filters.localStructure) {
            if (!this.loginService.getUser().roles.nationalAdmin) {
                params.localStructure = '|' + this.loginService.getUser().localStructure;
            }
        }

        params = this.parseParamsForGetContents(filters, params);

        return this.http.get(`/users/favorite/content?count`, { params });
    }

    /**
     * adds/removes one content from users favorites
     * @param content the clicked content
     */
    @log() addContentToFavorites(content): Observable<boolean> {
        return this.http.post<boolean>(`/users/favorite`, {
            type: 'content',
            id: content.id,
            action: 'add'
        });
    }

    /**
     * adds/removes one content from users favorites
     * @param content the clicked content
     */
    @log() removeContentFromFavorites(content): Observable<boolean> {
        return this.http.post<boolean>(`/users/favorite`, {
            type: 'content',
            id: content.id,
            action: 'delete'
        });
    }

    parseParamsForGetContents(filters: object, params: any) {
        for (const prop in filters) {
            if (
                Object.prototype.hasOwnProperty.call(filters, prop) &&
                filters[prop] &&
                filters[prop] !== '' &&
                filters[prop].length > 0
            ) {
                if (
                    prop === 'timecreatedstart' ||
                    prop === 'timecreatedend' ||
                    prop === 'timemodifiedstart' ||
                    prop === 'timemodifiedend'
                ) {
                    params = {
                        ...params,
                        [prop]: filters[prop]
                    };
                } else if (prop === 'searchTerm') {
                    params = {
                        ...params,
                        search: filters[prop]
                    };
                } else if (Array.isArray(filters[prop])) {
                    params = {
                        ...params,
                        [prop]: filters[prop].join('|')
                    };
                } else if (typeof filters[prop] === 'object' && typeof filters[prop] !== null) {
                    let req = '';
                    for (const key in filters[prop]) {
                        if (filters[prop].hasOwnProperty(key)) {
                            req += filters[prop][key] + '|';
                        }
                    }
                    params = {
                        ...params,
                        [prop]: req.substring(0, req.length - 1)
                    };
                } else {
                    params = {
                        ...params,
                        [prop]: filters[prop]
                    };
                }
            }
        }
        return params;
    }

    @log() getSites() {
        return this.http.get(`/users/favorite/site`);
    }

    @log() getUsers() {
        return this.http.get(`/users/favorite/user`);
    }

    @log() getGroups() {
        return this.http.get(`/users/favorite/group`);
    }

    @log() getStudentGroups() {
        return this.http.get(`/users/favorite/group?type=learner`);
    }

    @log() addSiteToFavorites(site) {
        return this.http.post(`/users/favorite`, {
            type: 'site',
            id: site.id,
            action: 'add'
        });
    }

    @log() removeSiteFromFavorites(site) {
        return this.http.post(`/users/favorite`, {
            type: 'site',
            id: site.id,
            action: 'delete'
        });
    }

    @log() addUserToFavorites(user) {
        return this.http.post(`/users/favorite`, {
            type: 'user',
            id: user.id,
            action: 'add'
        });
    }

    @log() removeUserFromFavorites(user) {
        return this.http.post(`/users/favorite`, {
            type: 'user',
            id: user.id,
            action: 'delete'
        });
    }

    @log() addGroupToFavorites(group) {
        return this.http.post(`/users/favorite`, {
            type: 'group',
            id: group.id,
            action: 'add'
        });
    }

    @log() removeGroupFromFavorites(group) {
        return this.http.post(`/users/favorite`, {
            type: 'group',
            id: group.id,
            action: 'delete'
        });
    }
    emitContentAddedToFavorites(content: Content) {
        this.contentAddedToFavorites.next(content);
    }

    emitContentRemovedFromFavorites(content: Content) {
        this.contentRemovedFromFavorites.next(content);
    }
}
