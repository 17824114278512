<div id="more-actions" [ngClass]="moreActionClass" (click)="closeComponent()">
    <ng-container *ngIf="moreActionOrigin === 'library'">
        <div
            class="item"
            (click)="element.viewContent($event)"
            *ngIf="element.isVisibleIconForAction('viewContent')"
        >
            <span class="icon-Fiche"></span>
            Voir la fiche
        </div>
        <div
            class="item"
            (click)="element.getParentOf($event)"
            *ngIf="element.isVisibleIconForAction('getParentOf')"
        >
            <span class="icon-parent"></span>
            Afficher les parents
        </div>
        <div
            class="item"
            (click)="element.viewGuide($event)"
            *ngIf="element.isVisibleIconForAction('viewGuide')"
        >
            <span class="icon-guide"></span>
            Voir le guide
        </div>
        <div
            class="item"
            (click)="element.openAdvancedAssignation($event)"
            *ngIf="element.isVisibleIconForAction('openAdvancedAssignation')"
        >
            <span class="icon-Assignationmultiple"></span>
            Assignation avancée
        </div>
        <div
            class="item"
            (click)="element.rateActivity($event)"
            *ngIf="element.isVisibleIconForAction('rateActivity')"
        >
            <span class="icon-thumbup"></span>
            Noter
        </div>
        <div
            class="item"
            (click)="element.reportActivityError($event)"
            *ngIf="element.isVisibleIconForAction('reportActivityError')"
        >
            <span class="icon-flag"></span>
            Signaler une anomalie
        </div>
        <div
            class="item"
            (click)="element.openViewReport($event)"
            *ngIf="element.isVisibleIconForAction('openViewReport')"
        >
            <span class="icon-flag-plein"></span>
            Voir le signalement
        </div>

        <div
            class="item"
            (click)="element.editContent($event)"
            *ngIf="element.isVisibleIconForAction('editContent')"
        >
            <span class="icon-Nouvelleversioncontenu"></span>
            Modifier
        </div>
        <div
            class="item"
            (click)="element.copyContent($event)"
            *ngIf="element.isVisibleIconForAction('copyContent')"
        >
            <span class="icon-Cloner"></span>
            Copier
        </div>
        <div
            class="item"
            (click)="element.downloadH5P($event)"
            *ngIf="element.isVisibleIconForAction('downloadH5P')"
        >
            <span class="icon-download"></span>
            Télécharger le fichier H5P
        </div>
        <div
            class="item"
            (click)="element.openReporting($event)"
            *ngIf="element.isVisibleIconForAction('openReporting')"
        >
            <span class="icon-reporting"></span>
            Voir le reporting
        </div>
        <div
            class="item"
            (click)="element.disableContent($event)"
            *ngIf="element.isVisibleIconForAction('disableContent')"
        >
            <span class="icon-DesactiverContenu"></span>
            Désactiver
        </div>
        <div
            class="item"
            (click)="element.enableContent($event)"
            *ngIf="element.isVisibleIconForAction('enableContent')"
        >
            <span class="icon-Reactiver"></span>
            Activer
        </div>

        <div
            class="item"
            (click)="element.archiveContent($event)"
            *ngIf="element.isVisibleIconForAction('archiveContent')"
        >
            <span class="icon-Miseenapplcation"></span>
            Archiver
        </div>
        <div
            class="item"
            (click)="element.unarchiveContent($event)"
            *ngIf="element.isVisibleIconForAction('unarchiveContent')"
        >
            <span class="icon-Miseenapplcation"></span>
            Désarchiver
        </div>

        <div
            class="item"
            (click)="element.deleteContent($event)"
            *ngIf="element.isVisibleIconForAction('deleteContent')"
        >
            <span class="icon-bin"></span>
            Supprimer
        </div>
        <div
            class="item"
            (click)="element.exportQuiz($event)"
            *ngIf="element.isVisibleIconForAction('exportQuiz')"
        >
            <span class="icon-download"></span>
            Exporter
        </div>
        <div
            class="item"
            (click)="element.nationalizeContent($event)"
            *ngIf="element.isVisibleIconForAction('nationalizeContent')"
        >
            <span class="icon-national"></span>
            Copier au national
        </div>
    </ng-container>
    <ng-container *ngIf="moreActionOrigin === 'user'">
        <div class="item" (click)="element.openExchangeDialog($event)">
            <span class="icon-echanges"></span>
            Echanges
        </div>
        <div class="item" *ngIf="!element.user.favorite" (click)="element.addToFavorites($event)">
            <span class="icon-favoris"></span>
            Ajouter aux favoris
        </div>
        <div
            class="item"
            *ngIf="element.user.favorite"
            (click)="element.removeFromFavorites($event)"
        >
            <span class="icon-Retirerdesfavoris"></span>
            Retirer des favoris
        </div>
        <div class="item" (click)="element.getUserReporting($event)">
            <span class="icon-reporting"></span>
            Télécharger le rapport de l'apprenant
        </div>
        <div class="item" (click)="element.openTestReporting($event)">
            <span class="icon-radar"></span>
            Rapport des tests de l'apprenant
        </div>
        <div class="item" (click)="element.openUserReporting($event)">
            <span class="icon-Suividesconnexions"></span>
            Suivi de l'apprenant
        </div>
    </ng-container>
    <ng-container *ngIf="moreActionOrigin === 'group'">
        <div class="item" *ngIf="!element.group.favorite" (click)="element.addToFavorites($event)">
            <span class="icon-favoris"></span>
            Ajouter aux favoris
        </div>
        <div
            class="item"
            *ngIf="element.group.favorite"
            (click)="element.removeFromFavorites($event)"
        >
            <span class="icon-Retirerdesfavoris"></span>
            Retirer des favoris
        </div>
        <div class="item" (click)="element.openExchangeDialog($event)">
            <span class="icon-echanges"></span> Echanges
        </div>
        <div class="item" (click)="element.getGroupReporting($event)">
            <span class="icon-reporting"></span>
            Télécharger le rapport du groupe
        </div>
        <div class="item" (click)="element.openGroupTestReporting($event)">
            <span class="icon-radar"></span>
            Rapport des tests du groupe
        </div>
        <div class="item" (click)="element.openGroupReporting($event)">
            <span class="icon-Suividesconnexions"></span>
            Suivi du groupe
        </div>
    </ng-container>
    <ng-container *ngIf="moreActionOrigin === 'libraryHeader'">
        <div class="item" (click)="element.toggleLibraryView($event)">
            <span class="icon-contenusbibliotheque"></span>
            Bibliothèque
            <span class="icon-Valider" *ngIf="element.isLibraryView()"></span>
        </div>
        <div class="item" (click)="element.toggleCatalogView($event)">
            <span class="icon-Applications" [ngClass]="{ beating: !hasSeenCatalog() }"></span>
            Catalogue
            <span class="icon-Valider" *ngIf="element.isCatalogView"></span>
        </div>
        <div class="item" (click)="element.toggleFavoritesView($event)">
            <span class="icon-contenusfavoris"></span>
            Contenus favoris
            <span class="icon-Valider" *ngIf="element.isFavoritesView"></span>
        </div>
        <div class="item" (click)="element.toggleDisabledView($event)">
            <span class="icon-DesactiverContenu"></span>
            Contenus désactivés
            <span class="icon-Valider" *ngIf="element.isDisabledView"></span>
        </div>
        <div class="item" (click)="element.toggleArchiveView($event)">
            <span class="icon-archiver"></span>
            Contenus archivés
            <span class="icon-Valider" *ngIf="element.isArchiveView"></span>
        </div>
        <div class="item" (click)="element.toggleReportView($event)">
            <span class="icon-flag"></span>
            Contenus signalés
            <span class="icon-Valider" *ngIf="element.isReportView"></span>
        </div>
        <div class="item" *ngIf="isAdmin()" (click)="element.togglePreviousVersionView($event)">
            <span class="icon-Nouvelleversioncontenu"></span>
            Versions précédentes
            <span class="icon-Valider" *ngIf="element.isPreviousVersionView"></span>
        </div>
    </ng-container>
</div>
