import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject, forkJoin } from 'rxjs';

import { LibraryService } from '../../services/library.service';
import { UserService } from '../../services/user.service';
import { LoadingService } from '../../services/loading.service';

import { Assignment } from '../../structures/assignment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-personnalize-group-assignment',
    templateUrl: './personnalize-group-assignment.component.html',
    styleUrls: ['./personnalize-group-assignment.component.scss']
})
export class PersonnalizeGroupAssignmentComponent implements OnInit {
    subscriptions = new Subscription();

    startDate: Date;
    endDate: Date;
    startTime: string;
    endTime: string;

    searchInput: string;

    initial_assignment: Assignment;

    constructor(
        public dialogRef: MatDialogRef<PersonnalizeGroupAssignmentComponent>,
        @Inject(MAT_DIALOG_DATA) public assignment: Assignment,
        private libraryService: LibraryService,
        private userService: UserService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.searchInput = '';
        this.initial_assignment = JSON.parse(JSON.stringify(this.assignment));
    }

    getIcon() {
        return this.libraryService.getIcon(this.assignment);
    }

    getPersonnalizationCountMessage() {
        return this.assignment.active_users + '/' + this.assignment.users;
    }

    assignToAll() {
        if (this.assignment.active_users === this.assignment.users) {
            for (const i in this.assignment.users_status) {
                if (this.assignment.users_status[i].status) {
                    this.assignment.users_status[i].status = 'hidden';
                }
            }
            this.assignment.active_users = 0;
        } else {
            for (const i in this.assignment.users_status) {
                if (this.assignment.users_status[i].status) {
                    this.assignment.users_status[i].status = 'visible';
                }
            }
            this.assignment.active_users = this.assignment.users;
        }
    }

    getAssignAllMessage() {
        if (this.assignment.active_users === this.assignment.users) {
            return 'Désassigner à tout le groupe';
        } else {
            return 'Assigner à tout le groupe';
        }
    }

    showAssignIcon() {
        return this.assignment.active_users === this.assignment.users;
    }

    isModifiedStatus(user) {
        for (const i in this.assignment.users_status) {
            if (this.assignment.users_status[i].id === user.id) {
                return (
                    this.assignment.users_status[i].status !==
                    this.initial_assignment.users_status[i].status
                );
            }
        }
        return false;
    }

    cancelSearch() {
        this.searchInput = '';
    }

    getUserName(user) {
        return user.lastname.toUpperCase() + ' ' + user.firstname;
    }

    inSearchResult(user) {
        const reg = new RegExp(this.accentFold(this.searchInput), 'i');

        const firstLast = this.accentFold(user.firstname + ' ' + user.lastname.toUpperCase());
        const lastFirst = this.accentFold(user.lastname.toUpperCase() + ' ' + user.firstname);

        if (firstLast.search(reg) > -1 || lastFirst.search(reg) > -1) {
            return true;
        }

        return false;
    }

    accentFold(string: string) {
        return string.replace(
            /([àáâãäå])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
            function (str, a, c, e, i, n, o, s, u, y, ae) {
                if (a) {
                    return 'a';
                } else if (c) {
                    return 'c';
                } else if (e) {
                    return 'e';
                } else if (i) {
                    return 'i';
                } else if (n) {
                    return 'n';
                } else if (o) {
                    return 'o';
                } else if (s) {
                    return 's';
                } else if (u) {
                    return 'u';
                } else if (y) {
                    return 'y';
                } else if (ae) {
                    return 'ae';
                }
            }
        );
    }

    toggleAssignmentVisibility(user) {
        if (user.status === 'visible') {
            user.status = 'hidden';
            this.assignment.active_users--;
        } else {
            user.status = 'visible';
            this.assignment.active_users++;
        }
    }

    isAssignmentHidden(user: any) {
        return user.status !== 'visible';
    }

    applyPersonnalization = function () {
        const newStatus = [];

        for (const i in this.assignment.users_status) {
            if (
                this.assignment.users_status[i].status !==
                this.initial_assignment.users_status[i].status
            ) {
                newStatus.push(this.assignment.users_status[i]);
            }
        }

        const observableArray = [];

        for (const j in newStatus) {
            if (newStatus[j]) {
                if (newStatus[j].status !== 'visible') {
                    observableArray.push(
                        this.userService.hideUserAssignment(newStatus[j].assignmentId)
                    );
                } else {
                    observableArray.push(
                        this.userService.showUserAssignment(newStatus[j].assignmentId)
                    );
                }
            }
        }
        if (observableArray.length === 0) {
            this.dialogRef.close();
        }

        this.loadingService.startLoading('personnalizeGroupAssignment', 'applyPersonnalization');
        this.subscriptions.add(
            forkJoin(observableArray).subscribe(
                () => {
                    this.loadingService.stopLoading(
                        'personnalizeGroupAssignment',
                        'applyPersonnalization'
                    );

                    this.dialogRef.close(true);
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('personnalizeGroupAssignment');
                }
            )
        );
    };

    isLoading() {
        return this.loadingService.isLoading('personnalizeGroupAssignment');
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
