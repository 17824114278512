import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { User } from '../structures/user';
import { Content } from '../structures/content';
import { Group } from '../structures/group';
import { Assignment } from '../structures/assignment';
import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class AssignmentService {
    refreshTracking: Subject<Array<Assignment>> = new Subject();
    refreshDisponibility: Subject<Array<Assignment>> = new Subject();

    assigning: Subject<boolean> = new Subject();

    contentDragged: Content;
    draggedMode: string;

    constructor(private http: HttpClient) {}

    setDraggedElement(content: any) {
        this.contentDragged = content;
    }

    getDraggedElement(): any {
        return this.contentDragged;
    }

    clearDraggedElement() {
        this.contentDragged = undefined;
    }

    setDraggedMode(mode: string) {
        this.draggedMode = mode;
    }

    getDraggedMode(): string {
        return this.draggedMode;
    }

    clearDraggedMode() {
        this.draggedMode = undefined;
    }

    @log() assignContentToUser(
        content: Content,
        user: User,
        position?: number,
        parent?: Assignment,
        FI?: boolean
    ) {
        const body: any = {
            contentId: content.id,
            userId: user.id,
            position: position ? position : undefined
        };
        if (content.hasprice) {
            body.FI = FI;
        }

        if (parent && parent.assignmentId) {
            body.parentId = parent.assignmentId;
        }

        this.emitAssigning(true);

        return this.http.post(`/assignments?individual`, body).pipe(
            tap(
                (data) => {
                    this.emitAssigning(false);
                },
                (error) => {
                    this.emitAssigning(false);
                }
            )
        );
    }

    @log() assignContentToGroup(
        content: Content,
        group: Group,
        position?: number,
        parent?: Assignment,
        FI?: boolean
    ) {
        const body: any = {
            contentId: content.id,
            group: group.name,
            structureid: group.structureid,
            groupid: group.id,
            position: position
        };

        if (content.hasprice) {
            body.FI = FI;
        }

        if (parent && parent.assignmentId) {
            body.parentId = parent.assignmentId;
        }

        this.emitAssigning(true);

        return this.http.post(`/assignments?group`, body).pipe(
            tap(
                (data) => {
                    this.emitAssigning(false);
                },
                (error) => {
                    this.emitAssigning(false);
                }
            )
        );
    }

    @log() moveGroupAssignment(assignment: Assignment, position: number) {
        const params: any = {
            newPosition: position
        };

        return this.http.post(`/assignments/${assignment.assignmentId}?group`, {}, { params });
    }

    @log() moveUserAssignment(assignment: Assignment, position: number) {
        const params: any = {
            newPosition: position
        };

        return this.http.post(`/assignments/${assignment.assignmentId}?individual`, {}, { params });
    }

    @log() getUserFilter(userId: number) {
        return this.http.get(`/users/${userId}/assignments/filters`);
    }

    @log() getGroupFilter(groupId: number) {
        return this.http.get(`/groups/${groupId}/assignments/filters`);
    }

    /**
     * envoie un mail de rappel à l'apprenant, ou aux apprenants du groupe qui n'ont pas terminé
     */
    @log() remindAssignment(assignment: Assignment): Observable<boolean> {
        const assignmentType = assignment.enrolmentSource !== 'individual' ? 'group' : 'user';
        return this.http.post<boolean>(
            `/assignments/${assignment.assignmentId}/${assignmentType}/remind_content`,
            {}
        );
    }

    @log() getCertificate(assignment: Assignment, user: User): any {
        return this.http.get(`/assemblies/${assignment.id}/certificate/user/${user.id}`, {
            params: {},
            observe: 'response',
            responseType: 'blob'
        });
    }

    calculatePosition(
        parentAssignment: Assignment,
        index: number,
        targetAssignment?: Assignment
    ): number {
        const GAP = 1000000;
        if (!parentAssignment) {
            return GAP;
        }
        if (targetAssignment) {
            let currentIndex = -1;
            for (const i in parentAssignment.children) {
                if (parentAssignment.children[i].id === targetAssignment.id) {
                    currentIndex = +i;
                }
            }
            if (currentIndex === index || currentIndex === index - 1) {
                return;
            }
        }

        if (parentAssignment.children.length === 0) {
            return GAP;
        }
        if (index === 0) {
            for (let i = 0; i < parentAssignment.children.length; i++) {
                const element = parentAssignment.children[i];
                if (element.position > 0) {
                    return Math.trunc(parentAssignment.children[i].position / 2);
                }
            }
        }
        if (index === parentAssignment.children.length) {
            return Math.trunc(
                parentAssignment.children[parentAssignment.children.length - 1].position + GAP
            );
        }
        return Math.trunc(
            (parentAssignment.children[index - 0].position +
                parentAssignment.children[index - 1].position) /
                2
        );
    }

    canBeChild(parent: Assignment): boolean {
        const child = this.getDraggedElement();
        const levelsHierarchies = [
            'parcours',
            'bloc',
            'competence',
            'module',
            'sequence',
            'activity'
        ];

        const childLevel = child.level ? child.level : 'activity';
        const parentLevel = parent.level ? parent.level : 'activity';

        const childIndex = levelsHierarchies.indexOf(childLevel);
        const parentIndex = levelsHierarchies.indexOf(parentLevel);

        if (parentLevel === 'bloc' || parentLevel === 'parcours') {
            if (childLevel === 'activity' || childLevel === 'sequence') {
                return false;
            }
        }

        return childIndex > -1 && parentIndex > -1 && childIndex > parentIndex;
    }

    emitRefreshTracking(assignments: Array<Assignment>) {
        this.refreshTracking.next(assignments);
    }

    emitRefreshDisponibility(assignments: Array<Assignment>) {
        this.refreshDisponibility.next(assignments);
    }

    emitAssigning(assigning: boolean) {
        this.assigning.next(assigning);
    }
}
