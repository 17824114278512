<div class="header">
    <div class="icon-JustificationGauche"></div>
    <div class="title">{{ getTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content">
    <div class="legend">
        Pour des raisons de performances, la modification apportée ne sera effective que dans 5
        minutes.
    </div>
    <input class="domain-input" type="text" placeholder="Nom du domaine" [(ngModel)]="domain" />
</mat-dialog-content>
<div class="footer">
    <div class="button" (click)="action()">
        <div *ngIf="!isLoading()" class="button-label">{{ getFooter() }}</div>
        <app-loading *ngIf="isLoading()"></app-loading>
    </div>
</div>
