import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

import { UserService } from 'src/app/services/user.service';
import { LibraryService } from 'src/app/services/library.service';
import { LoadingService } from 'src/app/services/loading.service';

import { User } from 'src/app/structures/user';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-edit-author',
    templateUrl: './edit-author.component.html',
    styleUrls: ['./edit-author.component.scss']
})
export class EditAuthorComponent implements OnInit {
    users: Array<User> = [];
    search: string;

    constructor(
        public dialogRef: MatDialogRef<EditAuthorComponent>,
        private userService: UserService,
        private libraryService: LibraryService,
        private loadingService: LoadingService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.search = '';
        this.getUsers();
    }

    getUsers() {
        const filter: any = {
            structureid: this.data.structureid || +this.data.userstructure,
            offset: 0,
            role: 'internalTeacher|externalTeacher|localAdmin',
            search: this.search
        };

        this.loadingService.startLoading('editAuthorComponent', 'getUsers');
        this.userService.getUsersLight(filter).subscribe((users) => {
            this.users = users.filter((user) => user.id !== this.data.author);
            this.loadingService.stopLoading('editAuthorComponent', 'getUsers');
        });
    }

    markAsAuthor(user: User) {
        if (this.data.level) {
            this.data.authorid = user.id;
            this.libraryService.updateAssembly(this.data).subscribe((data) => {
                this.dialogRef.close(user);
            });
        } else {
            this.data.authorid = user.id;
            this.libraryService.updateActivity(this.data).subscribe((data) => {
                this.dialogRef.close(user);
            });
        }
    }

    cancelSearch() {
        this.search = '';
        this.getUsers();
    }

    getUserIcon(user) {
        if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    isLoading() {
        return this.loadingService.isLoading('editAuthorComponent');
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
