<div class="header">
    <div class="icon-acces-devoirs"></div>
    <div class="label">Remise de devoir</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading('mainViewUserTracking')">
    <div class="entry-container">
        <div class="entry">
            <div class="icon-remise-devoir"></div>
            <div class="entry-title">{{ assignment.title }}</div>
            <div class="entry-dates" *ngIf="assignment.timeend">
                Devoir à rendre du
                {{ getDevoirDates(assignment.timestart) | date: 'dd/MM/yyyy' }} au
                {{ getDevoirDates(assignment.timeend) | date: 'dd/MM/yyyy' }}
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="search">
        <div class="search-stats">
            Déposé par
            <span class="numbers">{{ getNumberOfCompleted() }}/{{ tracking.length }}</span>
            apprenants
            <a
                class="download-link"
                [href]="getDownloadAllDevoirsLink()"
                *ngIf="isGroupAssignment() && getNumberOfCompleted() >= 1"
            >
                <span class="icon-download" tooltip="Télécharger tous les devoirs"></span>
            </a>
            <span
                class="icon-Miseenapplcation"
                tooltip="Rendre un corrigé général"
                *ngIf="isGroupAssignment() && !assignment.correction_file"
                (click)="openUploadDevoirCorrection()"
            ></span>
            <span
                class="icon-Miseenapplcation"
                tooltip="Voir le corrigé général"
                *ngIf="isGroupAssignment() && assignment.correction_file"
                (click)="openCorrectionFile(assignment.correction_file)"
            ></span>
        </div>
        <div class="search-container">
            <input type="text" [(ngModel)]="searchInput" placeholder="Chercher..." />
            <span class="icon-search" ng-show="searchInput.length == 0"></span>
            <span
                class="icon-close"
                (click)="cancelSearch()"
                ng-show="searchInput.length > 0"
            ></span>
        </div>
    </div>
    <div class="top" [ngClass]="{ anonymous: isAnonymous() }">
        <!-- <div class="blank"></div> -->
        <div class="apprenant">Apprenant</div>
        <div class="hash" *ngIf="isAnonymous()">Nom du fichier</div>
        <div class="date">Date de dépôt</div>
        <!-- <div class="blank-large"></div> -->
        <div class="note">Note</div>
    </div>
    <div class="learner-container">
        <ng-container *ngFor="let user of tracking">
            <div
                class="learner"
                *ngIf="inSearchResult(user)"
                [ngClass]="{ anonymous: isAnonymous() }"
            >
                <span class="learner-wrapper">
                    <span class="icon-apprenant" *ngIf="!isAnonymous()"></span>
                    <span class="icon-logAs" *ngIf="isAnonymous()"></span>
                    <span class="learner-name">
                        {{ user.lastname.toUpperCase() }} {{ user.firstname }}
                    </span>
                </span>
                <span class="hash" *ngIf="user.hash && isAnonymous()">{{ user.hash }}</span>
                <span class="upload-date">{{
                    user.submission_time && getDevoirDates(user.submission_time)
                        | date: 'dd/MM/yyyy'
                }}</span>

                <div class="icons">
                    <span
                        class="icon-Corrige"
                        tooltip="Déposer le devoir corrigé"
                        *ngIf="
                            user.status === 'completed' &&
                            user.score !== null &&
                            !user.correction_file
                        "
                        (click)="openUploadDevoirCorrection(user)"
                    ></span>
                    <div
                        class="icon-message"
                        *ngIf="
                            user.status === 'completed' &&
                            user.score !== null &&
                            !user.correction_file
                        "
                        tooltip="Envoyer un rappel à l'apprenant"
                        (click)="sendReminder()"
                    ></div>
                    <span
                        class="icon-Corrige"
                        tooltip="Voir le devoir corrigé"
                        *ngIf="
                            user.status === 'completed' &&
                            user.score !== null &&
                            user.correction_file
                        "
                        (click)="openCorrectionFile(user.correction_file)"
                    ></span>
                    <span
                        class="icon-rafraichir"
                        tooltip="Proposer un nouveau dépôt"
                        *ngIf="user.submitted_file"
                        (click)="giveNewAttempt(user)"
                    ></span>
                    <a
                        [href]="user.submitted_file"
                        target="_blank"
                        *ngIf="user.status === 'completed' && user.submitted_file"
                        ><span class="icon-devoir-actif" [tooltip]="'Voir le devoir'"></span
                    ></a>
                    <span
                        class="note"
                        *ngIf="
                            user.status === 'completed' &&
                            !isBeingEvaluated(user.userid) &&
                            user.score !== null &&
                            user.score !== 'Masqué'
                        "
                        (click)="createNoteInput(user.userid, user.score)"
                        [tooltip]="isAnonymous() ? 'Note' : 'Modifier la note'"
                        >{{ user.score }}/100</span
                    >
                    <span
                        class="note temporary-score"
                        *ngIf="
                            user.status === 'completed' &&
                            user.submitted_file &&
                            !isBeingEvaluated(user.userid) &&
                            user.temporary_score !== null &&
                            user.temporary_score !== 'Masqué' &&
                            user.score === null
                        "
                        (click)="createNoteInput(user.userid, user.temporary_score)"
                        tooltip="Modifier la note"
                        >{{ user.temporary_score }}/100</span
                    >
                    <span
                        class="note"
                        *ngIf="
                            user.status === 'completed' &&
                            !isBeingEvaluated(user.userid) &&
                            user.score === 'Masqué' &&
                            user.submitted_file !== null
                        "
                        >Masqué</span
                    >
                    <span
                        class="to-note"
                        *ngIf="isReadyToBeNoted(user)"
                        (click)="createNoteInput(user.userid)"
                        >A noter</span
                    >
                    <span class="notation-input" *ngIf="isBeingEvaluated(user.userid)">
                        <span class="icon-NoterMoins" (click)="decreaseNote(user.userid)"></span>
                        <input
                            type="number"
                            name=""
                            step="1"
                            value="{{ notes[user.userid] }}"
                            (keyup)="onNoteChange($event, user.userid)"
                        />
                        <div class="label">/ 100</div>
                        <span class="icon-NoterPlus" (click)="increaseNote(user.userid)"></span>
                    </span>
                    <div
                        class="icon-message"
                        *ngIf="user.status === null || user.status === 'opened'"
                        (click)="reminderWork(user)"
                        [tooltip]="'Envoyer un rappel'"
                    ></div>
                </div>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content" *ngIf="isLoading('mainViewUserTracking')">
    <app-loading></app-loading>
</mat-dialog-content>
<div class="footer">
    <div class="final-notes-checkbox-container" *ngIf="!isAnonymous() && isGroupAssignment()">
        <div class="checkbox-label">
            Afficher les notes, les devoirs corrigés et le corrigé général aux apprenants. Afficher
            les notes dans la vue formateur et dans le tracking.
        </div>
        <div
            class="checkbox"
            [ngClass]="saveNotesAsDefinitive ? 'icon-selected' : 'icon-select'"
            (click)="toggleNotesDefinitive()"
        ></div>
    </div>
    <div
        class="button"
        (click)="saveNotes()"
        *ngIf="!isLoading('userTrackingButton') && (!isAnonymous() || !assignment.is_revealed)"
    >
        Enregistrer
    </div>
    <div
        class="button anonymous-definitive-discover"
        [ngClass]="{ disabled: isRevealButtonDisabled() }"
        *ngIf="isAnonymous() && !assignment.is_revealed"
        (click)="openAnonymousRevealConfirmation()"
    >
        Révéler l’identité des apprenants et afficher les notes
    </div>
    <div class="button" *ngIf="isLoading('userTrackingButton')"><app-loading></app-loading></div>
</div>
