<div class="header">
    <div class="icon-creer-assemblage"></div>
    <div class="title">{{ getTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()" *ngIf="!isImportStarted()"></div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading('massImportMainView')">
    <div class="import">
        <div class="label">Fichier CSV</div>
        <div class="value" appFileDropzone (fileDropped)="uploadFile($event)">
            {{ getCurrentFileLabel() }}
        </div>
        <div
            class="icon-Importer"
            (click)="openSelectFile()"
            [tooltip]="'Sélectionner un fichier CSV'"
        ></div>
        <input class="hidden" type="file" #fileInput (change)="uploadFile($event.target.files)" />
    </div>
    <div class="content-header" *ngIf="file">
        <span class="content-header-element default-column">Code</span>
        <span class="content-header-element default-column">Nom</span>
        <span class="content-header-element small-column" *ngIf="isActivityImport()">Type</span>
        <span class="content-header-element small-column" *ngIf="!isActivityImport()">Niveau</span>
        <span class="content-header-element small-column">Créé</span>
        <span class="content-header-element small-column">Publié</span>
        <span class="content-header-element large-column">Commentaire</span>
    </div>
    <div class="content-body" *ngIf="file">
        <div class="content-body-line" *ngFor="let line of arrayContent; index as i">
            <div class="content-body-cell default-column">{{ getCellValue(line, 'code') }}</div>
            <div class="content-body-cell default-column">{{ getCellValue(line, 'titre*') }}</div>
            <div class="content-body-cell small-column" *ngIf="isActivityImport()">
                {{
                    getCellValue(line, 'type*') === 'nathan'
                        ? 'Nathan'
                        : getFileType(getCellValue(line, 'url*'))
                }}
            </div>
            <div class="content-body-cell small-column" *ngIf="!isActivityImport()">
                {{ getCellValue(line, 'type*') }}
            </div>
            <div
                class="content-body-cell small-column"
                [ngClass]="{
                    'icon-select': getCreatedStatus(i) === 1,
                    'icon-selected': getCreatedStatus(i) === 2,
                    'icon-Supprimerdugroupe': getCreatedStatus(i) === 3,
                    'icon-Warning': getCreatedStatus(i) === 4
                }"
            ></div>
            <div
                class="content-body-cell small-column"
                [ngClass]="{
                    'icon-select': getPublishedStatus(i) === 1,
                    'icon-selected': getPublishedStatus(i) === 2,
                    'icon-Supprimerdugroupe': getPublishedStatus(i) === 3,
                    'icon-Warning': getPublishedStatus(i) === 4
                }"
            ></div>
            <div class="content-body-cell large-column">{{ getComment(i) }}</div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content loading" *ngIf="isLoading('massImportMainView')">
    <app-loading></app-loading>
</mat-dialog-content>
<div class="footer">
    <div
        class="button"
        (click)="startImport()"
        *ngIf="!isImportStarted() && !isLoading('massImportFooterView')"
    >
        Lancer l'import
    </div>
    <div class="button" *ngIf="isLoading('massImportFooterView')"><app-loading></app-loading></div>
    <div class="button" *ngIf="isImportFinished()" (click)="closeDialog()">Fermer</div>
</div>
