import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { Site } from '../structures/site';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class SiteService {
    constructor(
        private http: HttpClient,
    ) {}

    /**
     * @deprecated consider using getSite instead, for a transmission via easi-shared
     */
    @log() getSiteDeprecated(id: any): Observable<any> { return this.http.get(`/sites/${id}`); }

    @log() getSite(siteid: number): Observable<Site> {
        const site$: Subject<Site> = new Subject();
        const params: any = {
            events: true,
            from: 'training',
            siteid
        };
        const timeStamp = Date.now();
        (<HTMLIFrameElement>document.getElementById('header-container')).contentWindow.postMessage(
            { getSite: true, params, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                site$.next(event.data.site);
            }
        });
        return site$.asObservable();
    }

    @log() getSites(filters: any): Observable<Site[]> {
        const params: any = {
            offset: filters.offset ? filters.offset : 0,
            limit: filters.limit ? filters.limit : 30,
            ...filters
        };

        return this.http.get<Site[]>(`/sites`, { params });
    }

    @log() getSitesCount(filters: any): Observable<{ count: number }> {
        const params: any = { count: true, ...filters };

        return this.http.get<{ count: number }>(`/sites`, { params });
    }
}
