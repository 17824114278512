<mat-expansion-panel
    class="group-container"
    [hideToggle]="true"
    (opened)="toggleIcon()"
    (closed)="toggleIcon()"
    (click)="getUsersAndCompletion()"
    dndDropzone
    (dndDrop)="checkAssignContentToGroup()"
>
    <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
        <div class="group-header">
            <div class="icon" [ngClass]="getIcon()"></div>
            <div class="label">
                <div class="name">
                    {{ getGroupName() }}
                    <div class="count" *ngIf="group.users">
                        {{ getStudentCountAndCompletion() }}
                    </div>
                </div>
            </div>
            <div class="icons">
                <div class="hover-icons">
                    <div
                        class="icon-contenusassignes"
                        [tooltip]="'Voir les contenus assignés communs au groupe'"
                        (click)="goToGroupAssignment($event)"
                    ></div>
                    <div
                        class="icon-easi-cloud-line"
                        *ngIf="displayCloudIcon"
                        [tooltip]="'Voir le cloud du groupe (new)'"
                        (click)="goToNextCloudv2($event)"
                    ></div>

                    <div
                        class="icon-Voirplus"
                        tooltip="Voir toutes les actions"
                        [more-actions]="this"
                        [more-actions-origin]="'group'"
                        [more-actions-class]="className"
                        (click)="$event.stopImmediatePropagation()"
                    ></div>
                </div>
                <div class="default-icons">
                    <div
                        class="icon-favoris"
                        [tooltip]="'Groupe favori'"
                        *ngIf="group.favorite"
                    ></div>
                    <div class="icon-down" [ngClass]="{ open: opened }"></div>
                </div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="users-container">
            <div
                class="user"
                *ngFor="let user of group.users"
                dndDropzone
                (dndDrop)="checkAssignContentToUser(user)"
            >
                <div
                    [ngClass]="[getUserIcon(user), getConnectionStatusIcon(user)]"
                    [tooltip]="getConnectionStatusTooltip(user)"
                ></div>
                <div class="label">
                    <div class="name">
                        {{ getUserName(user) }}
                        <div class="completion">{{ getUserCompletion(user) }}</div>
                    </div>
                </div>
                <div class="icons">
                    <div class="hover-icons">
                        <div
                            class="icon-easi-call-line"
                            (click)="callUser(user)"
                            *ngIf="canCallUser(user)"
                            tooltip="Appeler l'utilisateur"
                        ></div>
                        <div
                            class="icon-Messages"
                            (click)="chatUser(user)"
                            *ngIf="canChatUser(user)"
                            tooltip="Envoyer un message à l'utilisateur"
                        ></div>
                        <div
                            class="icon-FicheUser"
                            (click)="openUserDialog(user)"
                            *ngIf="!hasIncompleteEvents(user) && canOpenUserDialog(user)"
                            [tooltip]="'Voir la fiche'"
                        ></div>
                        <div
                            class="icon-contenusassignes"
                            (click)="goToUserAssignment(user)"
                            [tooltip]="'Voir les contenus assignés'"
                        ></div>
                        <div
                            class="icon-reporting"
                            (click)="getUserReporting(user)"
                            [tooltip]="'Télécharger le rapport de l\'apprenant'"
                        ></div>
                        <div
                            class="icon-radar"
                            [tooltip]="'Rapport des tests de l\'apprenant'"
                            (click)="openUserTestReporting(user)"
                        ></div>
                        <div
                            class="icon-Suividesconnexions"
                            [tooltip]="'Suivi de l\'apprenant'"
                            (click)="openUserGroupReporting(user)"
                        ></div>
                    </div>
                    <div class="default-icons">
                        <div
                            class="icon-FicheUser with-warning"
                            *ngIf="hasIncompleteEvents(user) && canOpenUserDialog(user)"
                            (click)="openUserDialog(user)"
                            tooltip="Activation du compte non finalisée : consulter la fiche pour plus d'information"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</mat-expansion-panel>
