import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

/**
 * Composant gérant l'affichage de la fenêtre
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-copy-media',
    templateUrl: './copy-media.component.html',
    styleUrls: ['./copy-media.component.scss']
})
export class CopyMediaComponent {
    constructor(
        public dialogRef: MatDialogRef<CopyMediaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    goTo(path: string) {
        if (path === 'media') {
            this.dialogRef.close('media');
        } else if (path === 'OK') {
            this.dialogRef.close('OK');
        } else if (path === 'training') {
            this.dialogRef.close('training');
        }
    }

    isMedia() {
        return this.data.isMedia;
    }

    isCopy() {
        return this.data.isCopy;
    }

    /**
     * Ferme la fenêtre
     */
    closeDialog() {
        this.dialogRef.close(false);
    }
}
