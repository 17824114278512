import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../../services/dialog.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { LibraryService } from '../../../../services/library.service';
import { LoginService } from '../../../../services/login.service';

import { Content } from '../../../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-waiting-for-approval',
    templateUrl: './waiting-for-approval.component.html',
    styleUrls: ['./waiting-for-approval.component.scss']
})
export class WaitingForApprovalComponent {
    @Input() content: Content;

    @Output() contentRemoved: EventEmitter<Content> = new EventEmitter();
    @Output() contentModified: EventEmitter<Content> = new EventEmitter();

    subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService,
        private libraryService: LibraryService,
        private loginService: LoginService
    ) {}

    getIcon(): string {
        return this.libraryService.getIcon(this.content);
    }

    getContentTooltip(): string {
        return `<strong>Titre : </strong>${this.content.title}</br>
        ${this.content.id ? '<strong>ID : </strong>' : ''}${this.content.id || ''}${
            this.content.ucode ? '<strong> Code : </strong>' : ''
        }${this.content.ucode || ''}</br>
        ${this.content.description ? '<strong>Description : </strong>' : ''}${
            this.content.description || ''
        }</br>
        ${this.getDuration()}`;
    }

    getDuration() {
        if (
            this.content.duration !== '00:00' &&
            !!this.content.duration &&
            (this.content.level === 'module' ||
                this.content.level === 'sequence' ||
                !this.content.level)
        ) {
            const timeArray = this.content.duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            if (!this.isIncompleteDuration()) {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '')
                );
            } else {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '') +
                    "(durée d'activité(s) manquante(s) dans l'assemblage)"
                );
            }
        }
        return '';
    }

    isIncompleteDuration() {
        if (this.content.level === 'module' || this.content.level === 'sequence') {
            return this.checkIncompleteDurationRecursive(this.content);
        }
        return false;
    }

    checkIncompleteDurationRecursive(entry) {
        if (
            (!entry.duration || entry.duration === '00:00') &&
            (!entry.content_duration || entry.content_duration === '00:00')
        ) {
            return true;
        }
        for (const i in entry.children) {
            if (entry.children[i]) {
                const checkChildrenIncompleteDuration = this.checkIncompleteDurationRecursive(
                    entry.children[i]
                );
                if (checkChildrenIncompleteDuration) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    isLocalAdmin() {
        return this.loginService.getUser().roles.localAdmin;
    }

    isAuthor() {
        return this.loginService.getUser().id === this.content.author;
    }

    canDelegate() {
        return this.content.waitingForMyApproval;
    }

    viewFiche($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentDetails(this.content.id);
    }

    viewGuide($event: any) {
        $event.stopImmediatePropagation();
        if (this.content.guide) {
            const popup = window.open(this.content.guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    rejectContent($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService.openRejectContent(this.content).subscribe((data: string) => {
                if (data) {
                    this.subscriptions.add(
                        this.libraryService.rejectContent(this.content, data).subscribe(
                            () => {
                                this.flashMessageService.flash('Le contenu a été rejeté');
                                this.contentRemoved.emit(this.content);
                            }
                        )
                    );
                }
            })
        );
    }

    getRejectContentTooltip() {
        if (this.isLocalAdmin() && !this.content.waitingForMyApproval) {
            return 'Forcer le refus de la validation';
        } else {
            return 'Refuser la validation';
        }
    }

    approveContent($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService.openApproveContent(this.content).subscribe((data: string) => {
                if (data) {
                    this.subscriptions.add(
                        this.libraryService.approveContent(this.content, data).subscribe(
                            () => {
                                this.flashMessageService.flash('Le contenu a été approuvé');
                                this.contentRemoved.emit(this.content);
                            }
                        )
                    );
                }
            })
        );
    }

    getValidateContentTooltip() {
        if (this.isLocalAdmin() && !this.content.waitingForMyApproval) {
            return 'Forcer la validation du contenu';
        } else {
            return 'Valider le contenu';
        }
    }

    addValidator($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openAddValidator(this.content).subscribe((data: any) => {
            if (data) {
                const params = {
                    id: this.content.id,
                    user_id: data.validators,
                    comment: data.comment
                };

                this.subscriptions.add(
                    this.libraryService.addValidator(params).subscribe(
                        () => {
                            this.flashMessageService.flash('Les valideurs ont été ajoutés');
                        }
                    )
                );
            }
        });
    }

    delegateValidator($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService.openDelegateValidation(this.content).subscribe((data: any) => {
                if (data) {
                    const params = {
                        id: this.content.id,
                        user_id: data.validator,
                        comment: data.comment
                    };

                    this.subscriptions.add(
                        this.libraryService.delegateValidation(params).subscribe(
                            () => {
                                this.flashMessageService.flash(
                                    'La validation du contenu a été déléguée'
                                );
                                if (this.loginService.getUser().roles.localAdmin) {
                                    this.content.waitingForMyApproval = false;
                                    this.contentModified.emit(this.content);
                                } else {
                                    this.contentRemoved.emit(this.content);
                                }
                            }
                        )
                    );
                }
            })
        );
    }

    openHistoryDialog($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentHistory(this.content);
    }

    openActivity($event): void {
        $event.stopImmediatePropagation();
        this.libraryService.openActivity(this.content);
    }
}
