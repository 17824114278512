import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { LoadingModule } from './loading.module';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { VideoPlayerComponent } from '../components/video-player/video-player.component';
import { H5PPlayerComponent } from '../components/h5p-player/h5p-player.component';

@NgModule({
    declarations: [VideoPlayerComponent, H5PPlayerComponent],
    imports: [
        CommonModule,
        MatSlideToggleModule,
        LoadingModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule
    ],
    exports: [VideoPlayerComponent, H5PPlayerComponent]
})
export class PlayerModule {}
