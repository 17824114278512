import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MoreActionsComponent } from './more-actions.component';
import { MoreActionsDirective } from './more-actions.directive';




@NgModule({
    declarations: [MoreActionsComponent, MoreActionsDirective],
    imports: [
        CommonModule,
        OverlayModule,
    ],
    exports: [
        MoreActionsComponent, MoreActionsDirective,
    ]
})
export class MoreActionsModule { }