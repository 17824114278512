<div class="header">
    <div class="icon-Publier"></div>
    <div class="title">Choix des valideurs</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading()">
    <div class="entry-container">
        <div class="entry">
            <div class="entry-icon" [ngClass]="getIcon()"></div>
            <div class="entry-title">{{ getTitle() }}</div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="main-container">
        <div class="main-content-selected-list">
            <div class="legend">Valideurs actuels</div>
            <div *ngFor="let validator of selectedValidators" class="validator">
                <div
                    class="icon"
                    [ngClass]="
                        validator.additionalRoles.validator ? 'icon-formateurinterne' : 'icon-adminlocal'
                    "
                ></div>
                <div class="label">{{ getValidatorName(validator) }}</div>
            </div>
        </div>

        <div class="separator"></div>

        <div class="main-content-header">
            <div class="label">Ajouter un ou des valideurs</div>
            <div class="validator-count">({{ getSelectedValidatorCount() }})</div>
            <div class="search-container">
                <input
                    class="library-search-field"
                    [(ngModel)]="search"
                    placeholder="Chercher..."
                />
                <span class="icon-search"></span>
                <span class="icon-Fermerdetails" *ngIf="search" (click)="cancelSearch()"></span>
            </div>
        </div>

        <div class="separator"></div>

        <div class="main-content-body">
            <div
                *ngFor="let validator of validators | userSearch: search"
                class="validator"
                [ngClass]="{ selected: isValidatorSelected(validator) }"
            >
                <div
                    class="icon"
                    [ngClass]="!validator.selected ? 'icon-select' : 'icon-selected'"
                    (click)="toggleValidator(validator)"
                ></div>
                <div
                    class="icon"
                    [ngClass]="
                        validator.additionalRoles.validator ? 'icon-formateurinterne' : 'icon-adminlocal'
                    "
                ></div>
                <div class="label">{{ getValidatorName(validator) }}</div>
            </div>
        </div>

        <div class="separator"></div>

        <div class="main-content-footer">
            <div class="main-content-footer-label">
                Message à destination du créateur du contenu et des autres
                valideurs *
            </div>
            <textarea [(ngModel)]="comment" placeholder="Saisir votre message"></textarea>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content" *ngIf="isLoading()">
    <app-loading></app-loading>
</mat-dialog-content>
<div class="footer">
    <div
        class="button"
        (click)="sendToValidation()"
        [ngClass]="{ disabled: !canSendToValidation() }"
    >
        Envoyer
    </div>
</div>
