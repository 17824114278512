<div class="header">
    <div class="title" [innerHTML]="getTitle()"></div>
</div>
<mat-dialog-content class="content">
    <div class="main-message" [innerHTML]="getMainMessage()"></div>
    <div class="sub-message" *ngIf="getSubMessage() && isAdmin()">
        <input type="checkbox" [(ngModel)]="recursiveAction" />
        <span [innerHTML]="getSubMessage()"></span>
    </div>
    <div class="comment" *ngIf="data.withComment">
        <div class="label">Commentaire :</div>
        <textarea
            [(ngModel)]="comment"
            placeholder="Saisissez ici votre commentaire (128 caractères maximum)"
            maxlength="128"
        ></textarea>
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="confirm button" (click)="action()">OUI</div>
    <div class="cancel button" (click)="closeDialog()">NON</div>
</div>
