import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-reveal-anonymous-devoir-confirmation',
    templateUrl: './reveal-anonymous-devoir-confirmation.component.html',
    styleUrls: ['./reveal-anonymous-devoir-confirmation.component.scss']
})
export class RevealAnonymousDevoirConfirmationComponent {
    @Output() openConfirmationDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<RevealAnonymousDevoirConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: { allDevoirsReady: boolean }
    ) {}

    firstQuestionConfirmed = false;

    /**
     * Détermine le message à afficher dans le corps de la fenêtre
     * @returns {String} Le message à afficher dans le corps de la fenêtre
     */
    getMainMessage(): String {
        if (this.firstQuestionConfirmed && !this.data.allDevoirsReady) {
            return `Certains apprenants n’ont pas rendu de devoir ou n’ont pas de note.<br />
            Que voulez-vous faire ?<br /><br />
            Attention, ce choix est définitif et ne pourra pas être modifié à postériori`;
        }
        return `Vous allez révéler l’identité des apprenants.<br />
        Vous ne pourrez plus changer les notes, ni proposer de nouvelles tentatives.<br />
        Aucun devoir ne pourra être rendu suite à cette action.<br />
        Les notes, les devoirs corrigés et le corrigé général seront affichés aux apprenants.<br />
        Les notes seront affichées dans la vue formateur et dans le tracking.<br /><br />
        Etes-vous sûr de vouloir révéler l’identité des apprenants ?`;
    }

    action(): void {
        if (this.data.allDevoirsReady) {
            this.dialogRef.close(true);
        } else {
            this.firstQuestionConfirmed = true;
        }
    }

    noteToZero(): void {
        this.dialogRef.close({ missingNotes: 0 });
    }
    giveNoNote(): void {
        this.dialogRef.close({ missingNotes: null });
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close(false);
    }
}
