import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../../services/config.service';
import { DialogService } from '../../../../services/dialog.service';
import { FavoriteService } from '../../../../services/favorite.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { LibraryService } from '../../../../services/library.service';
import { LoginService } from '../../../../services/login.service';

import { Content } from '../../../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-activity-creation',
    templateUrl: './activity-creation.component.html',
    styleUrls: ['./activity-creation.component.scss']
})
export class ActivityCreationComponent {
    @Input() activity: Content;
    @Input() search: string;

    @Output() clonedActivity: EventEmitter<Content> = new EventEmitter();

    subscriptions = new Subscription();

    constructor(
        private configService: ConfigService,
        private dialogService: DialogService,
        private favoriteService: FavoriteService,
        private flashMessageService: FlashMessageService,
        private libraryService: LibraryService,
        private loginService: LoginService
    ) {}

    getIcon(): string {
        return this.libraryService.getDraftIcon(this.activity);
    }

    getTypeLabel(): string {
        return this.libraryService.getTypeLabel(this.activity);
    }

    getDraftTooltip(): string {
        return `<strong>Titre : </strong>${this.activity.title}</br>
        ${this.activity.id ? '<strong>ID : </strong>' : ''}${this.activity.id || ''}${
            this.activity.ucode ? '<strong> Code : </strong>' : ''
        }${this.activity.ucode || ''}</br>
        ${this.activity.description ? '<strong>Description : </strong>' : ''}${
            this.activity.description || ''
        }</br>
        ${this.getDuration()}`;
    }

    getDuration() {
        if (
            this.activity.duration !== '00:00' &&
            !!this.activity.duration &&
            (this.activity.level === 'module' ||
                this.activity.level === 'sequence' ||
                !this.activity.level)
        ) {
            const timeArray = this.activity.duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            return (
                'Durée estimée : ' +
                (time.hours ? time.hours + 'h' : '') +
                (time.minutes ? time.minutes + 'mn' : '')
            );
        }
        return '';
    }

    showMediaIcon(): boolean {
        return this.activity.type === 'media' || this.activity.type === 'quiz';
    }

    showCloneIcon(): boolean {
        return this.activity.type !== 'media' && this.activity.type !== 'quiz';
    }

    canPublishQuiz(): boolean {
        return this.activity.quizIsPublishable !== false;
    }

    canPublish(): boolean {
        return (
            (this.loginService.getUser().additionalRoles.validator ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.nationalTeacher ||
                this.loginService.getUser().roles.siteTeacher ||
                this.activity.type === 'devoir') &&
            this.canPublishQuiz()
        );
    }

    canSendToValidation(): boolean {
        return (
            !(
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.nationalTeacher ||
                this.loginService.getUser().roles.siteTeacher ||
                this.activity.type === 'devoir'
            ) && this.canPublishQuiz()
        );
    }

    showHistoryIcon() {
        return this.activity.rejected;
    }

    viewFiche($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentCreation(this.activity);
    }

    viewGuide($event: any) {
        $event.stopImmediatePropagation();
        if (this.activity.guide) {
            const popup = window.open(this.activity.guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    goToMedia($event: any) {
        $event.stopImmediatePropagation();
        window.open(this.configService.getEasiMediaFrontEndPoint(), '_blank');
    }

    cloneDraft($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.libraryService.duplicateDraft(this.activity).subscribe((data: any) => {
                this.clonedActivity.emit({
                    ...this.activity,
                    id: data.id,
                    title: 'Copie de ' + this.activity.title,
                    favorite: false
                });
                this.flashMessageService.flash(
                    "Le contenu a été dupliqué dans l'espace de création"
                );
            })
        );
    }

    deleteDraft($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService
            .openConfirmationDialog(
                'Supprimer le brouillon',
                'Etes-vous sûr de vouloir supprimer le brouillon "' + this.activity.title + '" ?'
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.subscriptions.add(
                        this.libraryService.deleteDraft(this.activity).subscribe(() => {
                            this.libraryService.emitDeletedDraft(this.activity);
                            this.flashMessageService.flash('Le contenu a été supprimé');
                        })
                    );
                }
            });
    }

    publishDraft($event: any) {
        $event.stopImmediatePropagation();
        if (this.activity.publishable) {
            this.dialogService.openSelectPublication(this.activity).subscribe((data: any) => {
                if (data) {
                    this.activity.publicationMode = data.publicationMode;
                    this.libraryService
                        .approveContent(this.activity, data.comment)
                        .subscribe(() => {
                            this.flashMessageService.flash(
                                'Le contenu a été publié dans la bibliothèque'
                            );
                            this.libraryService.emitDeletedDraft(this.activity);
                            this.libraryService.emitRefreshLibrary();
                        });
                }
            });
        } else {
            this.dialogService
                .openContentCreation({ ...this.activity, with_publish_option: true })
                .subscribe((content: Content) => {
                    if (content !== undefined) {
                        this.activity = { ...content };
                    }
                    if (this.activity.publishable) {
                        this.dialogService
                            .openSelectPublication(this.activity)
                            .subscribe((data: any) => {
                                if (data) {
                                    this.activity.publicationMode = data.publicationMode;
                                    this.libraryService
                                        .approveContent(this.activity, data.comment)
                                        .subscribe(() => {
                                            this.flashMessageService.flash(
                                                'Le contenu a été publié dans la bibliothèque'
                                            );
                                            this.libraryService.emitDeletedDraft(this.activity);
                                            this.libraryService.emitRefreshLibrary();
                                        });
                                }
                            });
                    }
                });
        }
    }

    sendDraftToValidation($event: any) {
        $event.stopImmediatePropagation();
        if (this.activity.publishable) {
            this.dialogService.openSelectValidator(this.activity).subscribe((data: any) => {
                if (data) {
                    this.subscriptions.add(
                        this.dialogService
                            .openSelectPublication(this.activity)
                            .subscribe((publicationMode: any) => {
                                if (data && publicationMode) {
                                    const params = {
                                        id: this.activity.id,
                                        publicationMode: publicationMode.publicationMode,
                                        validators: data.validators,
                                        urgent: data.urgent,
                                        comment: data.comment
                                    };
                                    this.subscriptions.add(
                                        this.libraryService
                                            .sendContentToValidation(params)
                                            .subscribe(() => {
                                                this.flashMessageService.flash(
                                                    'Le contenu a été communiqué au(x) valideur(s)'
                                                );
                                                this.libraryService.emitDeletedDraft(this.activity);
                                            })
                                    );
                                }
                            })
                    );
                }
            });
        } else {
            this.dialogService
                .openContentCreation({ ...this.activity, with_publish_option: true })
                .subscribe((content: Content) => {
                    if (content !== undefined) {
                        this.activity = { ...content };
                    }
                    if (this.activity.publishable) {
                        this.dialogService
                            .openSelectValidator(this.activity)
                            .subscribe((data: any) => {
                                if (data) {
                                    this.dialogService
                                        .openSelectPublication(this.activity)
                                        .subscribe((publicationMode: any) => {
                                            if (data && publicationMode) {
                                                const params = {
                                                    id: this.activity.id,
                                                    publicationMode:
                                                        publicationMode.publicationMode,
                                                    validators: data.validators,
                                                    urgent: data.urgent,
                                                    comment: data.comment
                                                };
                                                this.libraryService
                                                    .sendContentToValidation(params)
                                                    .subscribe(() => {
                                                        this.flashMessageService.flash(
                                                            'Le contenu a été communiqué au(x) valideur(s)'
                                                        );
                                                        this.libraryService.emitDeletedDraft(
                                                            this.activity
                                                        );
                                                    });
                                            }
                                        });
                                }
                            });
                    }
                });
        }
    }

    matchSearch(): boolean {
        if (!this.search) {
            return false;
        }
        if (
            this.activity.title.toLowerCase().includes(this.search.toLowerCase()) ||
            this.activity.description.toLowerCase().includes(this.search.toLowerCase()) ||
            this.activity.keywords.findIndex(
                (keyword) => keyword.toLowerCase() === this.search.toLowerCase()
            ) !== -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    openHistoryDialog($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentHistory(this.activity);
    }

    addToFavorites($event): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.favoriteService.addContentToFavorites(this.activity).subscribe((data: any) => {
                this.activity.favorite = true;
                this.flashMessageService.flash(
                    `Le contenu ${this.activity.title} a été ajouté à vos favoris`
                );
            })
        );
    }

    removeFromFavorites($event: any): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.favoriteService
                .removeContentFromFavorites(this.activity)
                .subscribe((data: any) => {
                    this.activity.favorite = false;
                    this.flashMessageService.flash(
                        `Le contenu ${this.activity.title} a été retiré de vos favoris`
                    );
                })
        );
    }

    openActivity($event): void {
        $event.stopImmediatePropagation();
        this.libraryService.openActivity(this.activity);
    }

    editActivity($event): void {
        $event.stopImmediatePropagation();
        window.open(
            `${this.configService.getFrontEndPoint()}/quizEditor/#/${this.activity.id}`,
            '_blank'
        );
    }
}
