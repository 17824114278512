import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../../services/dialog.service';
import { LibraryService } from '../../../../services/library.service';
import { FlashMessageService } from '../../../../services/flash-message.service';

import { Content } from '../../../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-sent-to-validation',
    templateUrl: './sent-to-validation.component.html',
    styleUrls: ['./sent-to-validation.component.scss']
})
export class SentToValidationComponent {
    @Input() content: Content;
    @Input() parent: Content;
    @Input() index: number;
    @Input() editionOrderMode: boolean;
    @Output() contentMovedToDraft: EventEmitter<Content> = new EventEmitter();

    subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private flashMessageService: FlashMessageService
    ) {}

    getIcon(): string {
        return this.libraryService.getIcon(this.content);
    }

    getContentTooltip(): string {
        return `<strong>Titre : </strong>${this.content.title}</br>
        ${this.content.id ? '<strong>ID : </strong>' : ''}${this.content.id || ''}${
            this.content.ucode ? '<strong> Code : </strong>' : ''
        }${this.content.ucode || ''}</br>
        ${this.content.description ? '<strong>Description : </strong>' : ''}${
            this.content.description || ''
        }</br>
        ${this.getDuration()}`;
    }

    getDuration() {
        if (
            this.content.duration !== '00:00' &&
            !!this.content.duration &&
            (this.content.level === 'module' ||
                this.content.level === 'sequence' ||
                !this.content.level)
        ) {
            const timeArray = this.content.duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            if (!this.isIncompleteDuration()) {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '')
                );
            } else {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '') +
                    "(durée d'activité(s) manquante(s) dans l'assemblage)"
                );
            }
        }
        return '';
    }

    isIncompleteDuration() {
        if (this.content.level === 'module' || this.content.level === 'sequence') {
            return this.checkIncompleteDurationRecursive(this.content);
        }
        return false;
    }

    checkIncompleteDurationRecursive(entry) {
        if (
            (!entry.duration || entry.duration === '00:00') &&
            (!entry.content_duration || entry.content_duration === '00:00')
        ) {
            return true;
        }
        for (const i in entry.children) {
            if (entry.children[i]) {
                const checkChildrenIncompleteDuration = this.checkIncompleteDurationRecursive(
                    entry.children[i]
                );
                if (checkChildrenIncompleteDuration) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    viewFiche($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentDetails(this.content.id);
    }

    viewGuide($event: any) {
        $event.stopImmediatePropagation();
        if (this.content.guide) {
            const popup = window.open(this.content.guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    editValidators($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService.openSelectValidator(this.content).subscribe((data: any) => {
                if (data) {
                    this.subscriptions.add(
                        this.libraryService.updateValidators(this.content, data).subscribe(
                            () => {
                                this.flashMessageService.flash(
                                    'La liste des valideurs a été mise à jour'
                                );
                            }
                        )
                    );
                }
            })
        );
    }

    cancelValidation($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.libraryService.cancelValidation(this.content).subscribe(
                () => {
                    this.contentMovedToDraft.emit(this.content);
                    this.flashMessageService.flash(
                        'La demande de validation du contenu a été annulé'
                    );
                }
            )
        );
    }

    sendReminder($event: any) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.libraryService.sendReminder(this.content).subscribe(
                () => {
                    this.flashMessageService.flash('Un rappel a été envoyé aux valideurs');
                }
            )
        );
    }

    openHistoryDialog($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentHistory(this.content);
    }

    openActivity($event): void {
        $event.stopImmediatePropagation();
        this.libraryService.openActivity(this.content);
    }
}
