import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[appFileDropzone]'
})
export class FileDropzoneDirective {
    @Output() fileDropped: EventEmitter<Array<File>> = new EventEmitter();

    @HostBinding('class.fileover') fileOver: boolean;

    @HostListener('dragover', ['$event']) onDragOver($event) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    @HostListener('dragenter', ['$event']) onDragEnter($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.fileOver = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.fileOver = false;
    }

    @HostListener('drop', ['$event']) onDrop(evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        this.fileOver = false;
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
