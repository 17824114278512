import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { EasiHttpParams } from './easi-http-params';

@Injectable()
export class EndpointInterceptor implements HttpInterceptor {

  constructor(private configService: ConfigService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!(request.params instanceof EasiHttpParams) || !request.params.overrideBaseUrl) {
      request = request.clone({
        url: `${this.configService.getEndPoint()}${request.url}`
      });
    }

    return next.handle(request);
  }
}
