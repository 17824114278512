<div class="header">
    <div class="icon-Contenupayant"></div>
    <div class="title">Assignation de contenu payant</div>
</div>
<mat-dialog-content class="content">
    <div class="label">
        Certains de ces contenus sont payants. Attention, la tarification étant différente pour la
        formation initiale et la formation continue, vous devez le préciser ici.
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="confirm button" (click)="closeDialog(true)">Formation initiale</div>
    <div class="confirm button" (click)="closeDialog(false)">Formation continue</div>
</div>
