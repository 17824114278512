<div class="waiting-for-approval-container">
    <div class="icon" [ngClass]="getIcon()"></div>
    <div class="title" [tooltip]="getContentTooltip()" tooltipPosition="below-right">
        <div class="name">{{ content.title }}</div>
    </div>
    <div class="icons">
        <div class="hover-icons">
            <div class="icon-Fiche" (click)="viewFiche($event)" [tooltip]="'Voir la fiche'"></div>
            <div
                class="icon-guide"
                *ngIf="content.guide"
                (click)="viewGuide($event)"
                [tooltip]="'Voir le guide'"
            ></div>
            <div
                class="icon-rejeter"
                *ngIf="!isAuthor()"
                (click)="rejectContent($event)"
                [tooltip]="getRejectContentTooltip()"
            ></div>
            <div
                class="icon-valideur"
                *ngIf="!isAuthor()"
                (click)="approveContent($event)"
                [tooltip]="getValidateContentTooltip()"
            ></div>
            <div
                class="icon-ajout-valideur"
                *ngIf="!isLocalAdmin()"
                (click)="addValidator($event)"
                [tooltip]="'Ajouter un valideur'"
            ></div>
            <div
                class="icon-deleguer-validation"
                *ngIf="canDelegate()"
                (click)="delegateValidator($event)"
                [tooltip]="'Déléguer ma validation'"
            ></div>
            <div
                class="icon-historique"
                (click)="openHistoryDialog($event)"
                [tooltip]="'Voir l\'historique'"
            ></div>
            <div
                class="icon-voir"
                (click)="openActivity($event)"
                [tooltip]="'Voir l\'activité'"
            ></div>
        </div>
        <div class="default-icons">
            <div
                class="icon-Timer"
                *ngIf="content.urgent"
                [tooltip]="'Cette demande est urgente'"
            ></div>
        </div>
    </div>
</div>
