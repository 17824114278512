<mat-expansion-panel
    class="content"
    [hideToggle]="true"
    expanded="opened"
    (opened)="toggleIcon()"
    (closed)="toggleIcon()"
>
    <mat-expansion-panel-header
        class="content-header"
        [collapsedHeight]="'50px'"
        [expandedHeight]="'50px'"
        [ngClass]="getContentClass()"
    >
        <div class="icon" [ngClass]="getIcon()"></div>
        <div class="title">{{ content.title }}</div>
        <div *ngIf="isAssembly()" class="icon-down" [ngClass]="{ open: !opened }"></div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-assembly-confirmation-entry
            class="expansion-panel-child"
            *ngFor="let child of content.children"
            [content]="child"
            [mode]="mode"
        ></app-assembly-confirmation-entry>
    </ng-template>
</mat-expansion-panel>
