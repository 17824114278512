import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

import { LoginService } from '../../services/login.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-content-selection',
    templateUrl: './content-selection.component.html',
    styleUrls: ['./content-selection.component.scss']
})
export class ContentSelectionComponent {
    constructor(
        private dialogRef: MatDialogRef<ContentSelectionComponent>,
        private loginService: LoginService
    ) {}

    select(contentType: string) {
        this.dialogRef.close(contentType);
    }

    isAdmin() {
        return (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.nationalAdmin
        );
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
