<div class="header">
    <span class="icon-rejeter"></span>
    <span class="title">Rejeter la validation</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <div class="entry-container">
        <div class="entry">
            <span class="entry-icon" [ngClass]="getIcon()"></span>
            <span class="entry-title">{{ getTitle() }}</span>
        </div>
    </div>
    <div class="separator"></div>
    <div class="main-body">
        <div class="main-body-label">
            Merci de justifier votre refus *<br />
            Ce message sera visible par l'auteur du contenu, mais aussi par les autres valideurs.
        </div>
        <textarea [(ngModel)]="comment" placeholder="Ecrivez ici votre message"></textarea>
    </div>
</div>
<div class="footer">
    <div class="button" (click)="rejectContent()" [ngClass]="{ disabled: !canRejectContent() }">
        Rejeter la validation
    </div>
</div>
