import { ApplicationRef, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { LoginService } from './services/login.service';
import { ConfigService } from './services/config.service';
import { DialogService } from './services/dialog.service';

import { RoutingModule } from './modules/routing.module';

import { AdministrationModule } from './modules/administration.module';
import { PanelModule } from './modules/panel.module';
import { AssignmentModule } from './modules/assignment.module';
import { CreationSpaceModule } from './modules/creation-space.module';
import { LibraryModule } from './modules/library.module';
import { PlayerModule } from './modules/player.module';
import { LoadingModule } from './modules/loading.module';

import { CookieService } from 'ngx-cookie-service';
import { isMobile, isIE } from 'mobile-device-detect';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { LogInterceptor } from './interceptors/log.interceptor';
import { EndpointInterceptor } from './interceptors/endpoint.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

@NgModule({
    declarations: [AppComponent, HeaderComponent, FooterComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        AdministrationModule,
        PanelModule,
        AssignmentModule,
        CreationSpaceModule,
        LibraryModule,
        PlayerModule,
        PanelModule,
        RoutingModule,
        LoadingModule
    ],
    providers: [
        MatNativeDateModule,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ]
})
export class AppModule {
    constructor(
        private loginService: LoginService,
        private configService: ConfigService,
        private dialogService: DialogService
    ) {}

    ngDoBootstrap(app) {
        if (isIE) {
            window.location.href = 'https://cdn.easi-training.fr/assets/html/ie.html';
        }
        const getConfig = new XMLHttpRequest();
        getConfig.onload = (data: any) => {
            const response = JSON.parse(data.target.response);
            this.configService.setConfig(response);
            this.loginService.postCookies().subscribe((dataFromPost: number) => {
                this.loginService.getCookies().subscribe((dataFromGet: number) => {
                    if (dataFromPost === dataFromGet) {
                        if (response.profile) {
                            this.getProfileConfigSetConfigAndBootstrap(response, app);
                        } else {
                            this.setConfigAndBootStrap(response, app);
                        }
                    } else {
                        this.dialogService.openError(
                            'Veuillez autoriser les cookies pour poursuivre votre navigation sur easi'
                        );
                    }
                });
            });
        };
        getConfig.open('get', `./assets/config/config.json?${Date.now()}`, true);
        getConfig.send();
    }

    private setConfigAndBootStrap(config: object, app: ApplicationRef): void {
        this.configService.setConfig(config);
        this.loginService.getCurrentUserTraining().subscribe(() => {
            app.bootstrap(AppComponent);
        });
    }

    private getProfileConfigSetConfigAndBootstrap(
        response: { profile: string },
        app: ApplicationRef
    ): void {
        const getProfileConfig = new XMLHttpRequest();

        getProfileConfig.onload = (data2: any) => {
            const profileResponse = JSON.parse(data2.target.response);
            this.setConfigAndBootStrap(
                {
                    ...response,
                    ...profileResponse
                },
                app
            );
        };
        getProfileConfig.open('get', `./assets/config/${response.profile}.json?${Date.now()}`);
        getProfileConfig.send();
    }
}
