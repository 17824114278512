import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class DevoirService {
    constructor(
        private http: HttpClient,
    ) {}

    /**
     * score_if_not_submitted : true => 0/100 false: pas de note
     */
    @log() gradeGroupWork(
        assignmentId: number,
        action: 'grade' | 'cancel',
        score_if_not_submitted?: 'zero' | 'noNote'
    ): Observable<boolean> {
        const body: any = {
            action
        };
        if (score_if_not_submitted !== undefined) {
            body.score_if_not_submitted = score_if_not_submitted === 'zero' ? true : false;
        }

        return this.http.post<boolean>(`/assignments/${assignmentId}/group/grade`, body);
    }

    @log() gradeUserWork(assignmentId: number, action: 'grade' | 'cancel'): Observable<boolean> {
        return this.http.post<boolean>(`/assignments/${assignmentId}/user/grade`, { action });
    }
}
