import { ReportStatus } from 'src/app/structures/content';

export const levelsArray = [
    {
        title: 'parcours',
        key: 'parcours',
        icon: 'icon-parcours-cercle'
    },
    {
        title: 'bloc',
        key: 'bloc',
        icon: 'icon-bloc-cercle'
    },
    {
        title: 'competence',
        key: 'competence',
        icon: 'icon-competence-cercle'
    },
    {
        title: 'module',
        key: 'module',
        icon: 'icon-module-cercle'
    },
    {
        title: 'sequence',
        key: 'sequence',
        icon: 'icon-sequence-cercle'
    },
    {
        title: 'activity',
        key: 'activity',
        icon: 'icon-activite-cercle'
    }
];

export const typesArray = [
    {
        title: 'Fichier HTML',
        key: 'html'
    },
    {
        title: 'Fichier Office',
        key: 'office'
    },
    {
        title: 'Fichier PDF',
        key: 'pdf'
    },
    {
        title: 'Fichier (Autres)',
        key: 'fichier'
    },
    {
        title: 'Lien URL',
        key: 'url'
    },
    {
        title: 'Manuel numérique (Hachette)',
        key: 'hachette'
    },
    {
        title: 'Manuel numérique (Num4Skills)',
        key: 'n4s'
    },
    {
        title: 'Manuel numérique (Nathan)',
        key: 'nathan'
    },
    {
        title: 'H5P',
        key: 'h5pactivity'
    },
    {
        title: 'Média - Machine',
        key: 'machine'
    },
    {
        title: 'Média - Frise chronologique',
        key: 'timeline'
    },
    {
        title: 'Média - Présentation interactive',
        key: 'coursepresentation'
    },
    {
        title: 'Média - Vidéo interactive',
        key: 'interactivevideo'
    },
    {
        title: 'Test',
        key: 'quiz'
    },
    {
        title: 'Test cognitif N4S',
        key: 'testcognitifn4s'
    },
    {
        title: 'Objet 3D',
        key: '3D'
    },
    {
        title: 'Partenariat',
        key: 'partenariat'
    },
    {
        title: 'Paquetage SCORM',
        key: 'scorm'
    },
    {
        title: 'Présentiel',
        key: 'presentiel'
    },
    {
        title: 'Vidéo',
        key: 'video'
    }
];

export const ratesArray = [
    {
        title: '1 à 2',
        key: '1-2',
        icon: 'icon-thumbup-plein',
        queryValues: {
            note_avg_min: 1,
            note_avg_max: 2
        }
    },
    {
        title: '2 à 3',
        key: '2-3',
        icon: 'icon-thumbup-plein',
        queryValues: {
            note_avg_min: 2,
            note_avg_max: 3
        }
    },
    {
        title: '3 à 4',
        key: '3-4',
        icon: 'icon-thumbup-plein',
        queryValues: {
            note_avg_min: 3,
            note_avg_max: 4
        }
    },
    {
        title: '4 à 5',
        key: '4-5',
        icon: 'icon-thumbup-plein',
        queryValues: {
            note_avg_min: 4,
            note_avg_max: 5
        }
    }
];

export const librariesArray = [
    {
        title: 'Bibliothèque locale',
        key: 'local',
        icon: 'icon-local'
    },
    {
        title: 'Bibliothèque nationale',
        key: 'national',
        icon: 'icon-national'
    }
];

export const catalogArray = [
    {
        title: 'Catalogue local',
        key: 'local',
        icon: 'icon-local'
    },
    {
        title: 'Catalogue national',
        key: 'national',
        icon: 'icon-national'
    }
];

export const reportStatusList = [
    {
        title: 'Nouveau',
        key: ReportStatus.created
    },
    {
        title: 'En cours de traitement',
        key: ReportStatus.inprogress
    },
    {
        title: 'Ne sera pas corrigé',
        key: ReportStatus.canceled
    },
    {
        title: 'Corrigé',
        key: ReportStatus.closed
    }
];
