<div class="header" [ngStyle]="{ 'background-color': getContentColor() }">
    <div class="title">{{ getDialogTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading() && content">
    <div class="training-of-teachers-message" *ngIf="isTrainingOfTeachers()">
        Activité dédiée à la formation de formateur<br />
        Ne peut être lancée qu'à partir de learning
    </div>
    <div class="separator" *ngIf="isTrainingOfTeachers()"></div>
    <div class="comment-container" *ngIf="getContentComment()">
        <div class="content-status">{{ getContentStatus() }}</div>
        <div class="comment">{{ getContentComment() }}</div>
    </div>
    <div class="separator" *ngIf="getContentComment()"></div>
    <div class="category">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Catégorie</div>
        <div class="label">
            <div class="icon" [ngClass]="getCategoryIcon()"></div>
            <div class="text">{{ getContentCategory() }}</div>
        </div>
    </div>
    <div class="duration" *ngIf="showDuration()">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">
            <div class="text">Durée estimée</div>
            <div
                class="icon-Warning"
                *ngIf="isIncompleteDuration()"
                [tooltip]="'Durées d\'activités manquantes dans l\'assemblage'"
                [tooltipPosition]="'right'"
            ></div>
        </div>
        <div class="label">{{ getContentDuration() }}</div>
    </div>
    <div class="separator"></div>
    <div class="origin">
        <div class="icon" [ngClass]="getOriginIcon()"></div>
        <div class="label" [innerHTML]="getContentOrigin()"></div>
    </div>
    <div class="separator"></div>
    <div class="title">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Titre</div>
        <div class="label">{{ getContentTitle() }}</div>
    </div>
    <div class="description">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Description</div>
        <div class="label" [innerHTML]="getContentDescription()"></div>
    </div>
    <div class="domains">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Domaine</div>
        <div class="label">
            <div
                class="domain"
                *ngFor="let domain of getContentDomains()"
                [ngStyle]="{ 'background-color': getContentColor() }"
            >
                {{ domain }}
            </div>
        </div>
    </div>
    <div class="keywords">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Mots-clés</div>
        <div class="label">
            <div
                class="keyword"
                *ngFor="let keyword of getContentKeywords()"
                [ngStyle]="{ 'background-color': getContentColor() }"
            >
                {{ keyword }}
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="version">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Version</div>
        <div class="label">
            {{ getContentVersion() }}
            <div class="creation-date">{{ getContentCreationDate() }}</div>
        </div>
    </div>
    <div class="guide">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Guide</div>
        <div class="label">{{ getContentGuide() }}</div>
    </div>
    <div class="code">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Code</div>
        <div class="label">{{ getContentCode() }}</div>
    </div>
    <div class="taxonomy">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Taxonomie</div>
        <div class="label">{{ getContentTaxonomy() }}</div>
    </div>
    <div class="providers">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Fournisseur</div>
        <div class="label">{{ getContentProvider() }}</div>
    </div>
    <div class="copyright">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Copyright</div>
        <div class="label">{{ getContentCopyright() }}</div>
    </div>
    <div class="price" *ngIf="showPrice()">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Prix € HT</div>
        <div class="label">{{ getContentPrice() }}</div>
    </div>
    <div class="author">
        <div class="legend" [ngStyle]="{ color: getContentColor() }">Auteur</div>
        <div class="label">
            {{ getContentAuthor() }}
            <div class="edit" (click)="editAuthor()" *ngIf="canEditAuthor()">Modifier l'auteur</div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content loading" *ngIf="isLoading()">
    <app-loading></app-loading>
</mat-dialog-content>
<div class="footer" *ngIf="canEditContent()" [ngStyle]="{ 'background-color': getContentColor() }">
    <div
        class="button"
        (click)="openEditContentDialog()"
        *ngIf="!isLoading()"
        [ngStyle]="{ color: getContentColor() }"
    >
        Modifier la fiche
    </div>
</div>
