import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ConfigService } from '../../services/config.service';

/**
 * Composant gérant l'affichage du bandeau easi-shared
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    constructor(
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private configService: ConfigService,
        private element: ElementRef
    ) {}

    iframe_url: SafeResourceUrl;

    ngOnInit() {
        this.initResizeIframe();
        this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.configService.getEasiSharedEndPoint() + '/index.html'
        );
    }

    /**
     * Charge le script resize-iframe.js nécessaire au fonctionnement d'easi-shared
     */
    initResizeIframe(): HTMLScriptElement {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = this.configService.getEasiSharedEndPoint() + '/resize-iframe.js';
        this.renderer.appendChild(this.element.nativeElement, script);
        return script;
    }
}
