import { Injectable } from '@angular/core';

/**
 * Service gérant la configuration de la plateforme
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config: any;

    setConfig(config: any) {
        this.config = config;
    }

    getConfig(): any {
        return this.config;
    }

    getEndPoint(): string {
        return this.config.endpoint.training.backend;
    }

    getFrontEndPoint(): string {
        return this.config.endpoint.training.front;
    }

    getRedirectEndPoint(): string {
        return this.config.endpoint.training.redirect;
    }

    getEasiSharedEndPoint(): string {
        return this.config.endpoint.shared.front;
    }

    getEasiMediaFrontEndPoint(): string {
        return this.config.endpoint.media.front;
    }

    getReportFrontEndpoint(): string {
        return this.config.endpoint.report.front;
    }
    
    getReportOldFrontEndpoint(): string {
        return this.config.endpoint.report_old.front;
    }

    getReportingFrontEndpoint(): string {
        return this.config.endpoint.reporting.front;
    }

    getQuizPlayerFrontEndpoint(): string {
        return this.config.endpoint.quizPlayer.front;
    }

    getStudentFrontEndpoint(): string {
        return this.config.endpoint.student.front;
    }
}
