<div class="teacher-container">
    <div
        id="left-content"
        #leftContent
        [ngClass]="{ reduced: closedLeft, extended: closedRight, 'manual-resizing': isResizing() }"
    >
        <app-tabs></app-tabs>
        <router-outlet></router-outlet>
    </div>

    <div id="volet-separateur" #voletSeparateur [ngClass]="{ hidden: closedLeft || closedRight }">
        <div #resizeIcon class="icon-resize" (mousedown)="startResize()"></div>
    </div>

    <div
        id="right-content"
        #rightContent
        [ngClass]="{ reduced: closedRight, extended: closedLeft, 'manual-resizing': isResizing() }"
    >
        <app-library></app-library>
    </div>
</div>
