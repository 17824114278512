import { Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { LibraryService } from '../../services/library.service';
import { LoadingService } from '../../services/loading.service';

import { Content } from '../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-content-history',
    templateUrl: './content-history.component.html',
    styleUrls: ['./content-history.component.scss']
})
export class ContentHistoryComponent implements OnInit {
    subscriptions = new Subscription();

    historyDate: Array<any>;

    constructor(
        public dialogRef: MatDialogRef<ContentHistoryComponent>,
        @Inject(MAT_DIALOG_DATA) private content: Content,
        private libraryService: LibraryService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.historyDate = [];
        this.loadingService.startLoading('contentHistory', 'getContentHistory');
        this.subscriptions.add(
            this.libraryService.getContentHistory(this.content).subscribe(
                (data: any) => {
                    data.map((element: any) => {
                        const index = this.dateAlreadyExists(element);
                        if (index > -1) {
                            this.addToDate(index, element);
                        } else {
                            this.createDate(element);
                        }
                    });
                    this.loadingService.stopLoading('contentHistory');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('contentHistory');
                }
            )
        );
    }

    dateAlreadyExists(element: any) {
        for (const i in this.historyDate) {
            if (this.historyDate[i].date === element.timecreated) {
                return +i;
            }
        }
        return -1;
    }

    createDate(element: any) {
        this.historyDate.push({
            date: element.timecreated,
            elements: [element]
        });
    }

    addToDate(index, element) {
        this.historyDate[index].elements.push(element);
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    getDateLabel(date: any) {
        return new Date(date).toLocaleDateString();
    }

    getElementType = function (element, type) {
        return element.action === type;
    };

    getElementRoleIcon(item) {
        const roles = item.roles;
        if (roles.nationalAdmin) {
            return 'icon-adminnational';
        } else if (roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (roles.learner) {
            return 'icon-apprenant';
        } else if (roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (roles.siteTeacher) {
            return 'icon-formateur-site';
        }
    }

    isLinkedElement(element: any) {
        return element.linked_to !== null;
    }

    getAddCountForElement(id: number) {
        let count = 0;
        this.historyDate.map((date: any) => {
            date.elements.map((element: any) => {
                if (element.linked_to === id && element.action === 'add') {
                    count++;
                }
            });
        });
        return count;
    }

    isLoading() {
        return this.loadingService.isLoading('contentHistory');
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
