import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CookieService } from 'ngx-cookie-service';

import { ConfigService } from '../../services/config.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-redirect-media',
    templateUrl: './redirect-media.component.html',
    styleUrls: ['./redirect-media.component.scss']
})
export class RedirectMediaComponent {
    constructor(
        private dialogRef: MatDialogRef<RedirectMediaComponent>,
        private cookieService: CookieService,
        private configService: ConfigService
    ) {}

    isCookie() {
        return this.cookieService.check('redirectMedia');
    }

    toggleCookie() {
        if (this.isCookie()) {
            this.cookieService.delete('redirectMedia', '/');
        } else {
            const expires = new Date();
            expires.setFullYear(expires.getFullYear() + 1);
            this.cookieService.set('redirectMedia', 'true', {
                expires: expires,
                path: '/',
                sameSite: 'None',
                secure: true
            });
        }
    }

    goToMedia() {
        window.open(this.configService.getEasiMediaFrontEndPoint(), '_blank');
        this.closeDialog();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
