import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { GroupService } from '../../../../services/group.service';
import { ConfigService } from '../../../../services/config.service';
import { DialogService } from '../../../../services/dialog.service';
import { ReportingService } from '../../../../services/reporting.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { AssignmentService } from '../../../../services/assignment.service';
import { LoginService } from 'src/app/services/login.service';

import { Group } from '../../../../structures/group';
import { User } from '../../../../structures/user';
import { Assignment } from '../../../../structures/assignment';

import * as FileSaver from 'file-saver';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { C } from '@angular/cdk/keycodes';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class GroupComponent {
    @Input() group: Group;
    @Input() displayCloudIcon: boolean;
    @Input() nextcloudLink: string;

    @Output() favoriteAdded: EventEmitter<any> = new EventEmitter();
    @Output() favoriteRemoved: EventEmitter<any> = new EventEmitter();

    opened: boolean;
    subscriptions$: Subscription = new Subscription();

    className = 'group-' + Math.floor(Math.random() * 999999);

    constructor(
        private groupService: GroupService,
        private configService: ConfigService,
        private dialogService: DialogService,
        private reportingService: ReportingService,
        private flashMessageService: FlashMessageService,
        private assignmentService: AssignmentService,
        private loginService: LoginService,
        private router: Router
    ) {}

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isLocalAdmin() {
        return this.loginService.getUser().roles.localAdmin;
    }

    isAccountManager() {
        return this.loginService.getUser().additionalRoles.accountManager;
    }

    getIcon(): string {
        if (this.group.type === 'teacher') {
            return 'icon-groupe';
        } else {
            return 'icon-groupe';
        }
    }

    getGroupName(): string {
        return this.group.name;
    }

    getStudentCountAndCompletion(): string {
        const usersTitle = this.group.type === 'teacher' ? 'formateur' : 'apprenant';
        const accord = this.group.users.length > 1 ? 's' : '';
        if (
            this.group.users &&
            this.group.completion !== undefined &&
            this.group.completion !== null
        ) {
            return `(${this.group.users.length} ${usersTitle}${accord}, ${this.group.completion}%)`;
        } else if (this.group.users) {
            return `(${this.group.users.length} ${usersTitle}${accord})`;
        }
    }

    addToFavorites($event) {
        $event.stopImmediatePropagation();
        if (this.group.type === 'learner') {
            this.favoriteAdded.emit({ type: 'student-group', element: this.group });
        } else {
            this.favoriteAdded.emit({ type: 'teacher-group', element: this.group });
        }
    }

    goToNextCloudv2($event) {
        $event.stopImmediatePropagation();
        window.open(this.nextcloudLink, '_blank');
    }

    removeFromFavorites($event) {
        $event.stopImmediatePropagation();
        if (this.group.type === 'learner') {
            this.favoriteRemoved.emit({ type: 'student-group', element: this.group });
        } else {
            this.favoriteRemoved.emit({ type: 'teacher-group', element: this.group });
        }
    }

    openExchangeDialog($event) {
        $event.stopImmediatePropagation();
        this.dialogService.openExchange(this.group);
    }

    goToGroupAssignment($event) {
        $event.stopImmediatePropagation();
        if (this.loginService.getUser().roles.tutor) {
            this.router.navigate(['/tutor/group/' + this.group.id], {
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate(['/teacher/group/' + this.group.id], {
                queryParamsHandling: 'merge'
            });
        }
    }

    getGroupReporting($event) {
        $event.stopImmediatePropagation();
        this.dialogService.openSelectGroupReportingDialog().subscribe((data) => {
            if (data === 'individual') {
                this.reportingService
                    .getCSVReporting({ groupId: this.group.id, type: 'users' })
                    .subscribe((csvText: string) => {
                        const date =
                            (new Date().getDate() < 10
                                ? '0' + new Date().getDate()
                                : new Date().getDate()) +
                            '-' +
                            (new Date().getMonth() + 1 < 10
                                ? '0' + (new Date().getMonth() + 1)
                                : new Date().getMonth() + 1) +
                            '-' +
                            new Date().getFullYear();
                        const csvName = 'reporting_' + this.group.name + '_' + date + '.csv';
                        const blob = new Blob(['\ufeff', csvText], {
                            type: 'text/plain;charset=iso-8859-1;'
                        });
                        FileSaver.saveAs(blob, csvName);

                        this.flashMessageService.flash("L'export CSV est terminé");
                    });
            } else if (data === 'group') {
                this.reportingService
                    .getCSVReporting({ groupId: this.group.id })
                    .subscribe((csvText: string) => {
                        const date =
                            (new Date().getDate() < 10
                                ? '0' + new Date().getDate()
                                : new Date().getDate()) +
                            '-' +
                            (new Date().getMonth() + 1 < 10
                                ? '0' + (new Date().getMonth() + 1)
                                : new Date().getMonth() + 1) +
                            '-' +
                            new Date().getFullYear();
                        const csvName = 'reporting_' + this.group.name + '_' + date + '.csv';
                        const blob = new Blob(['\ufeff', csvText], {
                            type: 'text/plain;charset=iso-8859-1;'
                        });
                        FileSaver.saveAs(blob, csvName);

                        this.flashMessageService.flash("L'export CSV est terminé");
                    });
            }
        });
    }

    openGroupTestReporting($event) {
        $event.stopImmediatePropagation();
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?groupId=' +
                this.group.id +
                '&select=all',
            '_blank'
        );
    }

    toggleIcon() {
        this.opened = !this.opened;
    }

    getUsersAndCompletion(): void {
        if (!this.group.users) {
            this.subscriptions$.add(
                this.groupService.getGroup(this.group.id).subscribe((data: Group) => {
                    this.group.users = data.users.sort((a, b) =>
                        a.lastname.toUpperCase().localeCompare(b.lastname.toUpperCase())
                    );
                    this.groupService
                        .getGroupCompletion(this.group.id)
                        .subscribe((completion: any) => {
                            this.group.completion = completion.completion;
                            this.group.users.map((user) => {
                                completion.users_completions.map((toto: any) => {
                                    if (toto.id === user.id) {
                                        user.completion = toto.completion;
                                    }
                                });
                                return user;
                            });
                        });
                })
            );
        }
    }

    getUserIcon(user: User) {
        if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    getUserName(user: User) {
        return `${user.lastname.toUpperCase()} ${user.firstname}`;
    }

    getUserCompletion(user: User) {
        if (user.completion !== undefined) {
            return `(${user.completion}%)`;
        }
    }

    getConnectionStatusIcon(user: User) {
        if (user.connectionStatus === 'offline') {
            return '';
        } else if (user.connectionStatus === 'online') {
            return 'green';
        } else if (user.connectionStatus === 'idle') {
            return 'orange';
        } else if (user.connectionStatus === 'donotdisturb') {
            return 'red';
        }
    }

    getConnectionStatusTooltip(user: User) {
        if (user.connectionStatus === 'offline') {
            return 'Hors ligne';
        } else if (user.connectionStatus === 'online') {
            return 'En ligne';
        } else if (user.connectionStatus === 'idle') {
            return 'Absent';
        } else if (user.connectionStatus === 'donotdisturb') {
            return 'Ne pas déranger';
        }
    }

    canCallUser(user: User) {
        return (
            user.id !== this.loginService.getUser().id &&
            this.getConnectionStatusIcon(user) === 'green'
        );
    }

    canChatUser(user: User) {
        return (
            user.id !== this.loginService.getUser().id &&
            this.getConnectionStatusIcon(user) === 'green'
        );
    }

    callUser(user: User) {
        const message: any = {
            from: this.loginService.getUser().id,
            to: user.id,
            users: {
                toName: user.lastname.toUpperCase() + ' ' + user.firstname
            },
            callid: null,
            status: 'new-call'
        };
        (<HTMLIFrameElement>document.getElementById('header-container')).contentWindow.postMessage(
            message,
            '*'
        );
    }

    chatUser(user: User) {
        const message: any = {
            startChat: JSON.stringify(user)
        };
        (<HTMLIFrameElement>document.getElementById('footer-container')).contentWindow.postMessage(
            message,
            '*'
        );
    }

    hasIncompleteEvents(user: User): boolean {
        return !user.events.CGU || !user.events.emailverified || !user.events.passwordverified;
    }

    canOpenUserDialog(user: User): boolean {
        if (this.isNationalAdmin()) {
            return true;
        } else if (this.isLocalAdmin()) {
            return !user.roles.nationalAdmin && !user.roles.localAdmin;
        } else if (this.isAccountManager()) {
            return user.roles.learner || user.roles.prospect || user.roles.tutor;
        } else {
            return user.roles.learner || user.roles.prospect;
        }
    }

    openUserDialog(user: User) {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage({ viewUser: user.id }, '*');
    }

    goToUserAssignment(user: User) {
        if (this.loginService.getUser().roles.tutor) {
            this.router.navigate(['/tutor/student/' + user.id], {
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate(['/teacher/student/' + user.id], {
                queryParamsHandling: 'merge'
            });
        }
    }

    getUserReporting(user: User) {
        this.subscriptions$.add(
            this.reportingService
                .getCSVReporting({ userId: user.id })
                .subscribe((csvText: string) => {
                    const date =
                        (new Date().getDate() < 10
                            ? '0' + new Date().getDate()
                            : new Date().getDate()) +
                        '-' +
                        (new Date().getMonth() + 1 < 10
                            ? '0' + (new Date().getMonth() + 1)
                            : new Date().getMonth() + 1) +
                        '-' +
                        new Date().getFullYear();
                    const csvName =
                        'reporting_' +
                        user.lastname.toUpperCase() +
                        '_' +
                        user.firstname +
                        '_' +
                        date +
                        '.csv';
                    const blob = new Blob(['\ufeff', csvText], {
                        type: 'text/plain;charset=iso-8859-1;'
                    });
                    FileSaver.saveAs(blob, csvName);

                    this.flashMessageService.flash("L'export CSV est terminé");
                })
        );
    }

    openUserTestReporting(user: User) {
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?studentId=' +
                user.id +
                '&select=all',
            '_blank'
        );
    }

    openGroupReporting() {
        window.open(
            this.configService.getReportFrontEndpoint() + '#/?groupId=' + this.group.id,
            '_blank'
        );
    }

    openUserGroupReporting(user: User) {
        window.open(
            this.configService.getReportFrontEndpoint() + '#/?studentId=' + user.id,
            '_blank'
        );
    }

    openHistoryDialog(user: User) {
        this.dialogService.openUserHistory(user);
    }

    checkAssignContentToGroup() {
        const draggedElement = JSON.parse(
            JSON.stringify(this.assignmentService.getDraggedElement())
        );
        if (draggedElement.hasprice) {
            this.subscriptions$.add(
                this.dialogService.openSelectFIFCDialog().subscribe((FI: boolean) => {
                    this.assignContentToGroup(draggedElement, FI);
                })
            );
        } else {
            this.assignContentToGroup(draggedElement, undefined);
        }
    }

    assignContentToGroup(element: Assignment, FI: boolean) {
        this.subscriptions$.add(
            this.assignmentService
                .assignContentToGroup(element, this.group, undefined, undefined, FI)
                .subscribe((data: any) => {
                    if (data.show_warning_cron) {
                        this.dialogService.openInfo(
                            "Pour ne pas vous bloquer dans votre navigation et vous permettre d'utiliser EASi sans interruption, Cette demande sera traitée par nos systèmes dans les meilleurs délais."
                        );
                        this.assignmentService.clearDraggedElement();
                        this.assignmentService.clearDraggedMode();
                        return;
                    }
                    this.flashMessageService.flash(
                        `Le contenu <b>${
                            element.title
                        }</b> a été assigné au groupe <b>${this.getGroupName()}</b>`
                    );
                    if (element.type === 'devoir') {
                        this.subscriptions$.add(
                            this.dialogService
                                .openDevoirConfirmation()
                                .subscribe((userInput: any) => {
                                    if (userInput) {
                                        this.groupService
                                            .markAssignmentAsAnonymous(data.assignment.assignmentId)
                                            .subscribe(() => {
                                                this.flashMessageService.flash(
                                                    `L'assignation a été marqué comme anonyme`
                                                );
                                            });
                                    }
                                })
                        );
                    }
                    this.dialogService
                        .openAddDisponibility(data.assignment)
                        .subscribe((data2: any) => {
                            if (data2) {
                                this.flashMessageService.flash(
                                    'La restriction calendaire a été ajoutée'
                                );
                            }
                        });
                    this.assignmentService.clearDraggedElement();
                    this.assignmentService.clearDraggedMode();
                })
        );
    }

    checkAssignContentToUser(user: User) {
        const draggedElement = JSON.parse(
            JSON.stringify(this.assignmentService.getDraggedElement())
        );
        if (draggedElement.hasprice) {
            this.subscriptions$.add(
                this.dialogService.openSelectFIFCDialog().subscribe((FI: boolean) => {
                    this.assignContentToUser(draggedElement, user, FI);
                })
            );
        } else {
            this.assignContentToUser(draggedElement, user, undefined);
        }
    }

    assignContentToUser(element: Assignment, user: User, FI: boolean) {
        this.subscriptions$.add(
            this.assignmentService
                .assignContentToUser(element, user, undefined, undefined, FI)
                .subscribe((assignment: any) => {
                    this.dialogService
                        .openAddDisponibility(assignment.assignment)
                        .subscribe((data: any) => {
                            if (data) {
                                this.flashMessageService.flash(
                                    'La restriction calendaire a été ajoutée'
                                );
                            }
                        });
                    this.flashMessageService.flash(
                        `Le contenu <b>${
                            element.title
                        }</b> a été assigné à l\'utilisateur <b>${this.getUserName(user)}</b>`
                    );
                    this.assignmentService.clearDraggedElement();
                    this.assignmentService.clearDraggedMode();
                })
        );
    }
}
