import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

import { LibraryService } from '../../services/library.service';

import { Content } from '../../structures/content';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-reject-content',
    templateUrl: './reject-content.component.html',
    styleUrls: ['./reject-content.component.scss']
})
export class RejectContentComponent implements OnInit {
    comment: string;

    constructor(
        public dialogRef: MatDialogRef<RejectContentComponent>,
        @Inject(MAT_DIALOG_DATA) public content: Content,
        private libraryService: LibraryService
    ) {}

    ngOnInit() {
        this.comment = '';
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    canRejectContent() {
        return this.comment.length > 0;
    }

    rejectContent() {
        if (this.canRejectContent()) {
            this.dialogRef.close(this.comment);
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
