<div class="header">
    <div class="icon-historique"></div>
    <div class="title">Choix des valideurs</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading()">
    <div class="entry-container">
        <div class="entry">
            <div class="entry-icon" [ngClass]="getIcon()"></div>
            <div class="entry-title">{{ getTitle() }}</div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="main-container">
        <div *ngFor="let date of historyDate">
            <div class="date-container">{{ getDateLabel(date.date) }}</div>
            <div
                class="element"
                [ngClass]="{
                    'inline-element': getElementType(element, 'add') && isLinkedElement(element)
                }"
                *ngFor="let element of date.elements"
            >
                <div *ngIf="getElementType(element, 'submit')">
                    <div class="author">
                        <span [ngClass]="getElementRoleIcon(element.user)"></span>
                        <span class="author-name">
                            {{ element.user.lastname.toUpperCase() }} {{ element.user.firstname }}
                        </span>
                    </div>
                    <span class="label">
                        a soumis le contenu à
                        {{ getAddCountForElement(element.id) }} valideur(s)</span
                    >
                </div>
                <div *ngIf="getElementType(element, 'add') && isLinkedElement(element.target)">
                    <div class="target">
                        <span [ngClass]="getElementRoleIcon(element.target)"></span>
                        <span class="target-name">
                            {{ element.target.lastname.toUpperCase() }}
                            {{ element.target.firstname }}
                        </span>
                    </div>
                </div>
                <div *ngIf="getElementType(element, 'add') && !isLinkedElement(element.user)">
                    <div class="author">
                        <span [ngClass]="getElementRoleIcon(element.user)"></span>
                        <span class="author-name">
                            {{ element.user.lastname.toUpperCase() }}
                            {{ element.user.firstname }}
                        </span>
                    </div>
                    <span class="label"> a ajouté comme valideur</span>
                    <div class="target">
                        <span [ngClass]="getElementRoleIcon(element.target)"></span>
                        <span class="target-name">
                            {{ element.target.lastname.toUpperCase() }}
                            {{ element.target.firstname }}
                        </span>
                    </div>
                </div>
                <div *ngIf="getElementType(element, 'remove')">
                    <div class="author">
                        <span [ngClass]="getElementRoleIcon(element.user)"></span>
                        <span class="author-name">
                            {{ element.user.lastname.toUpperCase() }}
                            {{ element.user.firstname }}
                        </span>
                    </div>
                    <span class="label"> a retiré comme valideur</span>
                    <div class="target">
                        <span [ngClass]="getElementRoleIcon(element.target)"></span>
                        <span class="target-name">
                            {{ element.target.lastname.toUpperCase() }}
                            {{ element.target.firstname }}
                        </span>
                    </div>
                </div>
                <div *ngIf="getElementType(element, 'approve')">
                    <div class="author">
                        <span [ngClass]="getElementRoleIcon(element.user)"></span>
                        <span class="author-name">
                            {{ element.user.lastname.toUpperCase() }}
                            {{ element.user.firstname }}
                        </span>
                    </div>
                    <span class="label"> a validé le contenu</span>
                </div>
                <div *ngIf="getElementType(element, 'reject')">
                    <div class="author">
                        <span [ngClass]="getElementRoleIcon(element.user)"></span>
                        <span class="author-name">
                            {{ element.user.lastname.toUpperCase() }}
                            {{ element.user.firstname }}
                        </span>
                    </div>
                    <span class="label"> a rejeté le contenu</span>
                </div>
                <div *ngIf="getElementType(element, 'delegate')">
                    <div class="author">
                        <span [ngClass]="getElementRoleIcon(element.user)"></span>
                        <span class="author-name">
                            {{ element.user.lastname.toUpperCase() }}
                            {{ element.user.firstname }}
                        </span>
                    </div>
                    <span class="label"> a délégué sa validation à</span>
                    <div class="target">
                        <span [ngClass]="getElementRoleIcon(element.target)"></span>
                        <span class="target-name">
                            {{ element.target.lastname.toUpperCase() }}
                            {{ element.target.firstname }}
                        </span>
                    </div>
                </div>
                <div *ngIf="getElementType(element, 'comment')">
                    <div class="comment-container">
                        <div class="author">
                            <span [ngClass]="getElementRoleIcon(element.user)"></span>
                            <span class="author-name">
                                {{ element.user.lastname.toUpperCase() }}
                                {{ element.user.firstname }}
                            </span>
                        </div>
                        <div class="comment-message">{{ element.value }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content loading" *ngIf="isLoading()">
    <app-loading></app-loading>
</mat-dialog-content>
