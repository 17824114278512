<div class="header">
    <div class="title">Assignation d'une remise de devoir</div>
</div>
<div class="content">
    Choisissez un type de remise de devoir :<br /><br />
    Dans le cas d'une remise anonyme, le nom des utilisateurs ne sera révélé qu'une fois l'ensemble
    des devoirs notés.<br />
    La remise anonyme peut être utilisée dans le cadre d'un concours par exemple.<br /><br />
    <b>Cette action est irréversible</b>
</div>
<div class="footer">
    <div class="button" (click)="action()">Remise anonyme</div>
    <div class="button" (click)="closeDialog()">Remise standard</div>
</div>
