import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
    providedIn: 'root'
})
export class TeacherGuard {
    constructor(private loginService: LoginService, private router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.loginService.getUser().roles.tutor) {
            return this.router.createUrlTree(['/tutor/panel']);
        }

        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.loginService.getUser().roles.nationalTeacher) {
            if (state.url !== '/teacher/creation') {
                return this.router.createUrlTree(['/teacher/creation']);
            }
        }
        return true;
    }
}
