import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { LibraryService } from '../../../services/library.service';
import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';
import { DialogService } from '../../../services/dialog.service';

import { Content } from '../../../structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-validation-space',
    templateUrl: './validation-space.component.html',
    styleUrls: ['./validation-space.component.scss']
})
export class ValidationSpaceComponent implements OnInit {
    subscriptions = new Subscription();

    sentToValidation: Array<Content>;
    waitingForApproval: Array<Content>;
    adminWaitingForApproval: Array<Content>;

    constructor(
        private loginService: LoginService,
        private libraryService: LibraryService,
        private dialogService: DialogService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.initContent();
    }

    initContent() {
        if (this.showSendToValidation()) {
            this.loadingService.startLoading('validationSpace', 'getSentToValidation');
            this.subscriptions.add(
                this.libraryService.getSentToValidation().subscribe(
                    (data: Array<Content>) => {
                        this.sentToValidation = data;
                        this.loadingService.stopLoading('validationSpace', 'getSentToValidation');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('validationSpace', 'getSentToValidation');
                    }
                )
            );
        }
        if (this.showWaitingApproval()) {
            this.loadingService.startLoading('validationSpace', 'getWaitingForApproval');
            this.subscriptions.add(
                this.libraryService.getWaitingForApproval().subscribe(
                    (data: Array<Content>) => {
                        if (this.loginService.getUser().roles.localAdmin) {
                            this.waitingForApproval = data.filter(
                                (content) => content.waitingForMyApproval
                            );
                            this.adminWaitingForApproval = data.filter(
                                (content) => !content.waitingForMyApproval
                            );
                        } else {
                            this.waitingForApproval = data;
                        }
                        this.loadingService.stopLoading('validationSpace', 'getWaitingForApproval');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('validationSpace', 'getWaitingForApproval');
                    }
                )
            );
        }
    }

    getSentToValidationCount() {
        if (this.sentToValidation) {
            return this.sentToValidation.length;
        }
    }

    getWaitingForApprovalCount() {
        if (this.waitingForApproval) {
            return this.waitingForApproval.length;
        }
    }

    getAdminWaitingForApprovalCount() {
        if (this.adminWaitingForApproval) {
            return this.adminWaitingForApproval.length;
        }
    }

    showSendToValidation() {
        return (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.internalTeacher ||
            this.loginService.getUser().roles.externalTeacher ||
            this.loginService.getUser().roles.siteTeacher
        );
    }

    moveContentToDraft(movedContent: Content) {
        this.sentToValidation.splice(
            this.sentToValidation.findIndex((content: Content) => content.id === movedContent.id),
            1
        );
    }

    showWaitingApproval() {
        return (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().additionalRoles.validator
        );
    }

    showAdminWaitingForApproval() {
        return this.loginService.getUser().roles.localAdmin;
    }

    removeWaitingForApproval(data: Content) {
        this.waitingForApproval.splice(
            this.waitingForApproval.findIndex((content: Content) => content.id === data.id),
            1
        );
        this.adminWaitingForApproval.splice(
            this.adminWaitingForApproval.findIndex((content: Content) => content.id === data.id),
            1
        );
    }

    dispatchModifiedContent(modifiedContent: Content) {
        this.removeWaitingForApproval(modifiedContent);
        if (this.loginService.getUser().roles.localAdmin && !modifiedContent.waitingForMyApproval) {
            this.adminWaitingForApproval.push(modifiedContent);
        } else {
            this.waitingForApproval.push(modifiedContent);
        }
    }

    isLoading() {
        return this.loadingService.isLoading('validationSpace');
    }
}
