import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ConfigService } from '../services/config.service';

import { LoginService } from '../services/login.service';

@Injectable({
    providedIn: 'root'
})
export class DefaultGuard {
    constructor(private loginService: LoginService, private configService: ConfigService) {}

    canActivate(route: ActivatedRouteSnapshot, state: any) {
        if (
            this.loginService.isLearner() &&
            !(state.url.includes('videoPlayer') || state.url.includes('h5pPlayer'))
        ) {
            window.location.href = this.configService.getStudentFrontEndpoint();
        }
        return true;
    }
}
