<div
    class="separator-container"
    *ngIf="isFirstElement()"
    dndDropzone
    [ngClass]="{ 'not-assignable': preventAssignmentInParent() }"
    [dndDisableIf]="!canAssignContentInParent()"
    (dndDrop)="checkAssignContentToGroup(0)"
>
    <div class="separator"></div>
</div>
<mat-expansion-panel
    #assignmentsContainer
    class="assignment-container"
    *ngIf="assignment"
    [ngClass]="{
        'not-assignable': preventAssignment(),
        disabled: assignment.status === 'hidden',
        'cron-warning': assignment.show_warning_cron
    }"
    [hideToggle]="true"
    (opened)="toggleIcon()"
    (closed)="toggleIcon()"
    dndDropzone
    (dndDrop)="checkAssignContentToGroup(undefined)"
    dndDragover
>
    <mat-expansion-panel-header
        class="expansion-drag-element"
        [collapsedHeight]="'50px'"
        [expandedHeight]="'50px'"
        (dndStart)="startDrag()"
        (dndEnd)="endDrag()"
        [dndDraggable]="assignment"
    >
        <div class="header" [tooltip]="getAssignmentTooltip()" tooltipPosition="above-right">
            <div class="dragover-message" *ngIf="getForbiddenAssignmentTooltipMessage()">
                <span class="icon-Warning"></span>{{ getForbiddenAssignmentTooltipMessage() }}
            </div>
            <div class="order-number" *ngIf="assignment.orderNumber">
                {{ assignment.orderNumber }}
            </div>
            <div class="icon" [ngClass]="getIcon()" (click)="selectAssignment($event)"></div>
            <div class="title">
                <div class="name">{{ assignment.title }}</div>
                <div class="completion">({{ assignment.completion }}%)</div>
            </div>
            <div class="icons">
                <div class="hover-icons">
                    <div
                        class="icon-guide"
                        *ngIf="hasGuide()"
                        (click)="viewGuide($event)"
                        [tooltip]="'Voir le guide'"
                    ></div>
                    <div
                        class="icon-Fiche"
                        (click)="openContentDialog($event)"
                        [tooltip]="'Voir la fiche'"
                    ></div>
                    <div
                        class="icon-Valide"
                        *ngIf="enabledValidationButton() && !isTutor()"
                        (click)="validateAssignment($event)"
                        [tooltip]="'Forcer la validation'"
                    ></div>
                    <div
                        class="icon-ForcerInvalidation"
                        *ngIf="enabledInvalidationButton() && !isTutor()"
                        (click)="invalidateAssignment($event)"
                        [tooltip]="'Annuler: Forcer la validation'"
                    ></div>
                    <div
                        class="icon-voir tooltip"
                        *ngIf="isActivity()"
                        (click)="openActivity()"
                        [tooltip]="getLaunchButtonTooltip()"
                    ></div>
                    <div
                        class="icon-desassigne"
                        *ngIf="canHideAssignment() && !isTutor()"
                        (click)="hideAssignment($event)"
                        [tooltip]="'Désassigner'"
                    ></div>
                    <div
                        class="icon-assigner"
                        *ngIf="!canHideAssignment() && !isTutor()"
                        (click)="showAssignment($event)"
                        [tooltip]="'Réassigner'"
                    ></div>
                    <div
                        class="icon-radar"
                        *ngIf="isQuiz()"
                        (click)="openReportingGroup($event)"
                        [tooltip]="'Rapport du test'"
                    ></div>
                    <div
                        class="icon-bin"
                        *ngIf="!isTutor()"
                        (click)="deleteAssignment($event)"
                        [tooltip]="'Supprimer'"
                    ></div>
                    <div
                        class="icon-message"
                        *ngIf="showRemindIcon()"
                        (click)="remindWork($event)"
                        [tooltip]="'Envoyer un rappel au groupe'"
                    ></div>

                    <div
                        class="icon-formateur-referent"
                        *ngIf="!isReferent()"
                        (click)="markAsReferent($event)"
                        [tooltip]="'Devenir référent'"
                    ></div>
                    <div
                        class="icon-formateur-referent-off"
                        *ngIf="isReferent()"
                        (click)="unmarkAsReferent($event)"
                        [tooltip]="'Ne plus être référent'"
                    ></div>
                    <div
                        class="icon-calendrier"
                        *ngIf="!isTutor()"
                        (click)="openAddDisponibilityDialog($event)"
                        [tooltip]="getAddDisponibilityTooltip()"
                    ></div>
                    <div
                        class="icon-calendrier-annuler"
                        *ngIf="showDisponibilityIcon() && !heritedDisponibility && !isTutor()"
                        (click)="removeDisponibility($event)"
                        [tooltip]="'Supprimer la restriction calendaire'"
                    ></div>
                    <div
                        class="icon-reporting"
                        (click)="openGroupTrackingDialog($event)"
                        *ngIf="group.type !== 'role'"
                        [tooltip]="'Consulter le suivi'"
                    ></div>
                    <div
                        class="icon-reporting"
                        (click)="downloadGroupReport($event)"
                        *ngIf="showDownloadGroupReport()"
                        [tooltip]="'Télécharger le rapport du groupe'"
                    ></div>
                </div>
                <div class="default-icons">
                    <div class="icon-easi-agenda-line" [tooltip]="getAssignmentDate()"></div>
                    <div [ngClass]="getProgressionIcon()" [tooltip]="getStatus()"></div>
                    <div
                        class="icon-personnaliserassignationgroupe"
                        *ngIf="isAssignationPersonnalized()"
                        [tooltip]="'Cette assignation a été personnalisée'"
                    ></div>
                    <div
                        class="personnalization-count"
                        *ngIf="isAssignationPersonnalized()"
                        [tooltip]="'Assignés/effectif du groupe'"
                    >
                        {{ getPersonnalizationCount() }}
                    </div>
                    <div
                        class="icon-Warning"
                        *ngIf="getIso() < 1"
                        [tooltip]="getIsoWarning()"
                        [ngClass]="{ red: getIso() === 0, orange: getIso() === -1 }"
                    ></div>
                    <div
                        class="icon-calendrier-actif"
                        *ngIf="showDisponibilityIcon()"
                        [tooltip]="getDisponibilityTooltip()"
                        [ngClass]="getDisponibilityClass()"
                    >
                        <div class="icon-Warning" *ngIf="showWarningDisponibility()"></div>
                    </div>
                    <div
                        class="icon-formateur-referent"
                        *ngIf="!getReferentAvatar()"
                        [tooltip]="getReferentTooltip()"
                        [ngClass]="{ blue: isReferent() }"
                    ></div>
                    <div class="avatar" *ngIf="getReferentAvatar()">
                        <img
                            class="avatar"
                            [src]="getReferentAvatar()"
                            [tooltip]="getReferentTooltip()"
                        />
                    </div>
                    <div
                        class="icon-Contenupayant"
                        *ngIf="hasPrice()"
                        [tooltip]="'Contenu payant'"
                    ></div>
                    <div
                        class="icon-OrdoOn"
                        *ngIf="isOrdered()"
                        [tooltip]="'Contenu ordonné'"
                    ></div>
                    <div class="icon-AssignationDeGroupe" [tooltip]="'Assignation de groupe'"></div>
                    <div class="icon-down" *ngIf="!isActivity()" [ngClass]="{ open: opened }"></div>
                    <div class="blank" *ngIf="isActivity()"></div>
                </div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <ng-template
        matExpansionPanelContent
        *ngIf="assignment.level && assignment.level !== 'activity'"
    >
        <app-group-assignments-entry
            *ngFor="let child of getSortedChildren(); index as i"
            class="expansion-panel-child"
            [assignment]="child"
            [parent]="assignment"
            [referents]="referents"
            [group]="group"
            [index]="i"
            (assignmentDeleted)="removeAssignment($event)"
        ></app-group-assignments-entry>
    </ng-template>
</mat-expansion-panel>
<div
    class="separator-container"
    dndDropzone
    [ngClass]="{ 'not-assignable': preventAssignmentInParent() }"
    [dndDisableIf]="!canAssignContentInParent()"
    (dndDrop)="checkAssignContentToGroup(index + 1)"
>
    <div class="no-assignment-dropzone" *ngIf="index === undefined">
        Déposez ici les contenus de la bibliothèque à assigner.
    </div>
    <div class="separator"></div>
</div>
