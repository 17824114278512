import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LoginService } from '../services/login.service';
import { DialogService } from '../services/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class MediaGuard {
    constructor(
        private router: Router,
        private loginService: LoginService,
        private dialogService: DialogService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const content = {
            status: 'draft',
            localStructure: '',
            sites: [],
            enabled: true,
            hasprice: 0,
            price: 0,
            pricetxt: '',
            hidden: false,
            forceStatus: true,
            copyright: this.loginService.getUser().localStructure
                ? this.loginService.getUser().localStructure
                : 'UIAF',
            ucode: '',
            author: this.loginService.getUser().id,
            title: route.queryParams.media_title,
            description: '',
            duration: '',
            provider: '',
            taxonomy: '',
            domains: [],
            keywords: [],
            tableOfContents: false,
            is_training_of_teachers: false,
            type:
                route.queryParams.media_type === 'quiz'
                    ? 'quiz'
                    : route.queryParams.media_type === 'h5p' ||
                      route.queryParams.media_type === 'machine'
                    ? 'media'
                    : '',
            media_id: route.queryParams.media_id
        };

        this.dialogService.openContentCreation(content);

        return this.router.createUrlTree(['/teacher/panel']);
    }
}
