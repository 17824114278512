<div class="main-container">
    <div class="header-container">
        <div class="course-name">
            <div class="icon" [ngClass]="getIcon()"></div>
            <div class="title">
                {{ content?.title }}
            </div>
        </div>
        <div class="edit-section" *ngIf="canEdit()">
            <mat-slide-toggle [checked]="editing" (change)="toggleEditionMode()"
                >Activer le mode édition</mat-slide-toggle
            >
        </div>
    </div>
    <div class="iframe-container" *ngIf="!isLoading()">
        <iframe
            [src]="iframe_url"
            [height]="iframeHeight"
            #iframe
            (load)="handleLoadFrame()"
        ></iframe>
    </div>
    <app-loading *ngIf="isLoading()"></app-loading>
</div>
