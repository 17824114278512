<div [ngClass]="{ hidden: isLoading() }" [attr.data-loaded]="!isLoading()">
    <app-header></app-header>
    <app-flash-message></app-flash-message>
    <div class="content" *ngIf="!isLoading()">
        <router-outlet></router-outlet>
    </div>
</div>
<app-footer></app-footer>
<app-loading *ngIf="isLoading()" data-loaded="false"></app-loading>
<app-assigning *ngIf="isAssigning()"></app-assigning>
