<div class="header">
    <div class="icon-creer-assemblage"></div>
    <div class="title">Mise à jour mineur d'un assemblage</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<div class="content">
    <div class="title">Les modifications suivantes ont été effectuées sur cet assemblage :</div>
    <div class="properties" *ngFor="let change of changes?.properties">
        {{ change }}
    </div>
    <div class="title">
        Les modifications suivantes ont été effectuées sur les enfants de cet assemblage :
    </div>
    <div class="children-changes">
        <div class="children" *ngFor="let change of changes?.children">
            <div class="icon-activite-cercle"></div>
            <div class="text">
                {{ change }}
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div class="button" (click)="validatePublication()">Publier</div>
</div>
