import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

import { PanelService } from '../../services/panel.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-teacher',
    templateUrl: './teacher.component.html',
    styleUrls: ['./teacher.component.scss']
})
export class TeacherComponent implements OnInit, AfterViewInit {
    mouseY: number;
    mouseX: number;
    resizing: boolean;
    closedLeft: boolean;
    closedRight: boolean;
    private subscriptions$: Subscription = new Subscription();
    @ViewChild('resizeIcon') resizeIconRef: ElementRef;
    @ViewChild('leftContent') leftContentRef: ElementRef;
    @ViewChild('rightContent') rightContentRef: ElementRef;
    @ViewChild('voletSeparateur') voletSeparateurRef: ElementRef;

    @HostListener('document:mousemove', ['$event'])
    onMouseMove($event: MouseEvent): void {/** méthode set dans ngViewInit **/}

    @HostListener('document:mouseup') stopResizing() {
        this.resizing = false;
    }

    constructor(private router: Router, private panelService: PanelService, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.onMouseMove = ($event: MouseEvent): void => {
                this.mouseY = $event.pageY - 70;
                this.renderer.setStyle(this.resizeIconRef.nativeElement, 'top', this.mouseY + 'px');
                this.mouseX = $event.pageX;
                if (this.resizing) {
                    this.resizePanels(this.mouseX);
                }
        }
    }

    ngOnInit() {
        this.mouseY = 0;
        this.mouseX = 0;
        this.resizing = false;
        this.closedLeft = false;
        this.closedRight = false;

        this.subscriptions$.add(
            this.panelService.clickLeftPanel.subscribe(() => {
                if (this.closedLeft) {
                    this.closedLeft = false;
                } else {
                    this.closedLeft = true;
                    if (this.closedRight) {
                        this.closedRight = false;
                    }
                }
                this.renderer.setStyle(this.voletSeparateurRef.nativeElement, 'left', '50%');
                this.renderer.setStyle(this.leftContentRef.nativeElement, 'width', '50%');
                this.renderer.setStyle(this.rightContentRef.nativeElement, 'width', '50%');
            })
        );

        this.subscriptions$.add(
            this.panelService.clickRightPanel.subscribe(() => {
                if (this.closedRight) {
                    this.closedRight = false;
                } else {
                    this.closedRight = true;
                    if (this.closedLeft) {
                        this.closedLeft = false;
                    }
                }
                this.renderer.setStyle(this.voletSeparateurRef.nativeElement, 'left', '50%');
                this.renderer.setStyle(this.leftContentRef.nativeElement, 'width', '50%');
                this.renderer.setStyle(this.rightContentRef.nativeElement, 'width', '50%');
            })
        );
    }

    startResize() {
        this.resizing = true;
    }

    resizePanels(pos) {
        const wWidth = window.innerWidth;

        if (pos < 575 && wWidth - pos < 575) {
            pos = wWidth / 2;
        } else if (pos < 575) {
            pos = 575;
        } else if (wWidth - pos < 575) {
            pos = wWidth - 575;
        }

        this.renderer.setStyle(this.voletSeparateurRef.nativeElement, 'left', pos + 'px');
        this.renderer.setStyle(this.leftContentRef.nativeElement, 'width', pos + 'px');
        this.renderer.setStyle(this.rightContentRef.nativeElement, 'width', wWidth - pos - 2 + 'px');
    }

    getMouseYPosition() {
        return this.mouseY + 'px';
    }

    isResizing() {
        return this.resizing;
    }

    isCurrentState(state) {
        return this.router.url.includes(state);
    }
}
