import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibraryService } from 'src/app/services/library.service';
import { Content } from 'src/app/structures/content';
import { Observable, Subject, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-activity-rating',
    templateUrl: './activity-rating.component.html',
    styleUrls: ['./activity-rating.component.scss']
})
export class ActivityRatingComponent implements OnInit {
    note: 1 | 2 | 3 | 4 | 5;
    activityHasBeenUpdated = false;
    myNoteHasBeenUpdated = false;
    subscriptions$: Subscription = new Subscription();
    constructor(
        public dialogRef: MatDialogRef<ActivityRatingComponent>,
        private libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: { activity: Content }
    ) {}

    ngOnInit() {
        if (this.data.activity.my_note) {
            this.note = this.data.activity.my_note;
            if (this.data.activity.my_note_timemodified && this.data.activity.timemodified) {
                const myNoteDate = new Date(this.data.activity.my_note_timemodified);
                const activityModifiedDate = new Date(this.data.activity.timemodified);
                this.activityHasBeenUpdated = myNoteDate < activityModifiedDate;
            }
        }
    }

    getIcon(): string {
        return this.libraryService.getIcon(this.data.activity);
    }

    /**
     * donne la classe correspondant à l'icone pouce bleu en fonction de la avg_note cf. #236951
     * ex : premier pouce (1)
     * si note inférieure à 0.33 => pouce vide
     * si note entre 0.33 et 0.66 => pouce moitié
     * si note > 0.66 => pouce plein
     * @param target le numéro de l'icon pouce bleu
     */
    fillTheAverageNoteClassName(target: 1 | 2 | 3 | 4 | 5): string {
        const difference: number = +this.data.activity.note_avg + 1 / 3 - target;
        if (difference > 0) {
            return '-plein';
        } else if (difference > -0.33) {
            return '-moitie';
        } else {
            return '';
        }
    }

    canRate(): boolean {
        return !!this.note && this.note !== this.data.activity.my_note;
    }

    setNoteTo(note: 1 | 2 | 3 | 4 | 5) {
        this.note = note;
        if (this.activityHasBeenUpdated) {
            this.myNoteHasBeenUpdated = true;
        }
    }

    getValidationButtonText() {
        if (this.data.activity.my_note && this.note && this.note !== this.data.activity.my_note) {
            return 'Modifier ma note';
        } else if (!this.data.activity.my_note && this.note) {
            return 'Noter';
        } else {
            return 'Veuillez choisir une note';
        }
    }

    /**
     * Envoie ma note au WS ad hoc
     */
    rate() {
        const action: Observable<boolean> = this.data.activity.my_note
            ? this.libraryService.updateRateContent(this.data.activity.id, this.note)
            : this.libraryService.rateContent(this.data.activity.id, this.note);

        this.subscriptions$.add(
            action.subscribe((resp: boolean) => {
                this.dialogRef.close(true);
            })
        );
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
