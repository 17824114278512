import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';

import { LibraryService } from '../../services/library.service';
import { UserService } from '../../services/user.service';
import { GroupService } from '../../services/group.service';
import { LoadingService } from '../../services/loading.service';

import { Assignment } from '../../structures/assignment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-add-disponibility',
    templateUrl: './add-disponibility.component.html',
    styleUrls: ['./add-disponibility.component.scss']
})
export class AddDisponibilityComponent implements OnInit {
    subscriptions = new Subscription();

    startDate: Date;
    endDate: Date;
    startTime: string;
    endTime: string;

    LIMIT = 30;

    startDateInput: Date;
    endDateInput: Date;

    assignment: Assignment;

    constructor(
        public dialogRef: MatDialogRef<AddDisponibilityComponent>,
        @Inject(MAT_DIALOG_DATA) public initialAssignment: any,
        private libraryService: LibraryService,
        private userService: UserService,
        private groupService: GroupService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        if (Array.isArray(this.initialAssignment)) {
            this.assignment = this.initialAssignment[0];
        } else {
            this.assignment = this.initialAssignment;
        }
        if (this.assignment.timestart) {
            this.startDate = new Date(this.assignment.timestart);
            this.startTime =
                (new Date(this.assignment.timestart).getHours() < 10 ? '0' : '') +
                new Date(this.assignment.timestart).getHours() +
                ':' +
                (new Date(this.assignment.timestart).getMinutes() < 10 ? '0' : '') +
                new Date(this.assignment.timestart).getMinutes();
            if (this.assignment.timeend) {
                this.endDate = new Date(this.assignment.timeend);
                this.endTime =
                    (new Date(this.assignment.timeend).getHours() < 10 ? '0' : '') +
                    new Date(this.assignment.timeend).getHours() +
                    ':' +
                    (new Date(this.assignment.timeend).getMinutes() < 10 ? '0' : '') +
                    new Date(this.assignment.timeend).getMinutes();
            } else {
                this.endDate = null;
                this.endTime = null;
            }
        } else {
            this.startDate = new Date();
            this.startTime = '08:00';
            this.endDate = null;
            this.endTime = null;
        }
    }

    isAssignmentArray() {
        return Array.isArray(this.initialAssignment);
    }

    setEndTime() {
        if (this.endTime === null) {
            this.endTime = '18:00';
            this.assignment.lock_after_enddate = true;
            this.assignment.enddate_reminders = true;
        }
    }

    getIcon() {
        return this.libraryService.getIcon(this.assignment);
    }

    removeEndDate = function () {
        this.endDate = null;
        this.endTime = null;
        this.assignment.lock_after_enddate = false;
        this.assignment.enddate_reminders = false;
    };

    setDisponibilityToAssignment() {
        let end;
        let start;
        const observableArray = [];

        this.startDate.setHours(+this.startTime.split(':')[0]);
        this.startDate.setMinutes(+this.startTime.split(':')[1]);
        this.startDate.setSeconds(0);
        this.startDate.setMilliseconds(0);
        start = this.startDate.toISOString();

        if (this.endDate) {
            this.endDate.setHours(+this.endTime.split(':')[0]);
            this.endDate.setMinutes(+this.endTime.split(':')[1]);
            this.endDate.setSeconds(0);
            this.endDate.setMilliseconds(0);
            end = this.endDate.toISOString();
        }
        if (this.assignment.enrolmentSource === 'individual') {
            this.loadingService.startLoading('addDisponibilityDialog', 'setUserDisponibility');
            if (Array.isArray(this.initialAssignment)) {
                this.initialAssignment.map((assignment) => {
                    observableArray.push(
                        this.userService.setDisponibilityToUserAssignment(
                            assignment.assignmentId,
                            start,
                            end,
                            this.assignment.lock_after_enddate,
                            this.assignment.enddate_reminders
                        )
                    );
                });
            } else {
                observableArray.push(
                    this.userService.setDisponibilityToUserAssignment(
                        this.assignment.assignmentId,
                        start,
                        end,
                        this.assignment.lock_after_enddate,
                        this.assignment.enddate_reminders
                    )
                );
            }
            forkJoin(observableArray).subscribe(
                () => {
                    this.loadingService.stopLoading(
                        'addDisponibilityDialog',
                        'setUserDisponibility'
                    );
                    this.dialogRef.close({ timestart: start, timeend: end });
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('addDisponibilityDialog');
                }
            );
        } else {
            this.loadingService.startLoading('addDisponibilityDialog', 'setGroupDisponibility');
            if (Array.isArray(this.initialAssignment)) {
                this.initialAssignment.map((assignment) => {
                    observableArray.push(
                        this.groupService.setDisponibilityToGroupAssignment(
                            assignment.assignmentId,
                            start,
                            end,
                            this.assignment.lock_after_enddate,
                            this.assignment.enddate_reminders
                        )
                    );
                });
            } else {
                observableArray.push(
                    this.groupService.setDisponibilityToGroupAssignment(
                        this.assignment.assignmentId,
                        start,
                        end,
                        this.assignment.lock_after_enddate,
                        this.assignment.enddate_reminders
                    )
                );
            }
            forkJoin(observableArray).subscribe(
                () => {
                    this.loadingService.stopLoading(
                        'addDisponibilityDialog',
                        'setGroupDisponibility'
                    );
                    this.dialogRef.close({ timestart: start, timeend: end });
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('addDisponibilityDialog');
                }
            );
        }
    }

    toggleLockAfterEndDate($event: Event): void {
        $event.stopImmediatePropagation();
        if (!!this.endDate) {
            this.assignment.lock_after_enddate = !this.assignment.lock_after_enddate;
        }
    }
    toggleAutoRemindMail($event: Event): void {
        $event.stopImmediatePropagation();
        if (!!this.endDate) {
            this.assignment.enddate_reminders = !this.assignment.enddate_reminders;
        }
    }

    isLoading() {
        return this.loadingService.isLoading('addDisponibilityDialog');
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
