<div class="header">
    <div></div>
    <div class="title">Télécharger le rapport du groupe</div>
    <div class="icon-close" (click)="closeDialog(false)"></div>
</div>
<mat-dialog-content class="content">
    Le <b>rapport des apprenants du groupe</b> comprend l'ensemble du reporting individuel de chaque
    apprenant, concaténé dans un même fichier.<br /><br />
    Le <b>rapport du groupe</b> comprend l'ensemble du reporting des assignations réalisées sur le
    groupe.
</mat-dialog-content>
<div class="footer">
    <div class="confirm button" (click)="closeDialog('individual')">
        Rapport des apprenants du groupe
    </div>
    <div class="cancel button" (click)="closeDialog('group')">Rapport du groupe</div>
</div>
