import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { DialogService } from './dialog.service';
import { Content } from '../structures/content';
import { LibraryService } from './library.service';
import { ArchiveService } from './archive.service';

import { Assignment } from '../structures/assignment';

@Injectable({
    providedIn: 'root'
})
export class MultiselectService {
    // The array containing the selected elements
    selectedContent: Array<Content> = [];
    selectedAssignment: Array<Assignment> = [];

    constructor(
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private archiveService: ArchiveService
    ) {}

    /* ****************************  GETTERS **************************** */
    /**
     * Retourne le tableau des éléments sélectionnés
     */
    getSelectedList(): Array<Content> {
        return this.selectedContent;
    }

    /**
     * Retourne le nombre d'éléments sélectionnés
     */
    getSelectedListLength(): number {
        return this.selectedContent.length;
    }

    /**
     * Vérifie si un contenu est sélectionné
     * @param content Le contenu en question
     */
    isContentSelected(content: Content): boolean {
        return this.selectedContent.find((element) => element.id === content.id) !== undefined;
    }

    /**
     * vérifie que tous les éléments de la sélection sont désactivés
     */
    isSelectionDisabled(): boolean {
        return this.selectedContent.filter((item) => item.enabled).length <= 0;
    }

    /**
     * Vérifie que tous les élements sélectionnés sont activés
     */
    isSelectionEnabled(): boolean {
        return this.selectedContent.filter((item) => !item.enabled).length <= 0;
    }

    /* ****************************  SETTERS **************************** */

    /**
     * Ajoute un contenu à la sélection
     * @param content le contenu à ajouter
     */
    addContentToSelection(content: Content): void {
        this.selectedContent = [...this.selectedContent, content];
    }

    /**
     * Retire un contenu sélectionné de la sélection
     * @param content le contenu à ôter
     */
    removeContentFromSelection(content: Content): void {
        this.selectedContent = [...this.selectedContent.filter((item) => item.id !== content.id)];
    }

    enableSelection(): Observable<void> {
        return new Observable((observer) => {
            const title = 'Activer les contenus sélectionnés';
            const message = 'Êtes-vous sûr de vouloir activer les contenus sélectionnés ?';
            const submessage =
                "Activer les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";

            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage)
                .subscribe((success) => {
                    if (success) {
                        const observableArray = [];
                        if (success.recursive) {
                            for (const item of this.selectedContent) {
                                observableArray.push(
                                    this.libraryService.recursiveAction(item, 'enable')
                                );
                            }
                        } else {
                            for (const item of this.selectedContent) {
                                observableArray.push(this.libraryService.enableContent(item));
                            }
                        }
                        forkJoin(observableArray).subscribe(() => {
                            observer.next();
                            observer.complete();
                        });
                    }
                });
        });
    }

    disableSelection(): Observable<void> {
        return new Observable((observer) => {
            const title = 'Désactiver les contenus sélectionnés';
            const message = 'Êtes-vous sûr de vouloir désactiver les contenus sélectionnés ?';
            const submessage =
                "Désactiver les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";

            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage, true)
                .subscribe((success) => {
                    if (success) {
                        const observableArray = [];
                        if (success.recursive) {
                            for (const item of this.selectedContent) {
                                observableArray.push(
                                    this.libraryService.recursiveAction(
                                        item,
                                        'disable',
                                        success.comment
                                    )
                                );
                            }
                        } else {
                            for (const item of this.selectedContent) {
                                observableArray.push(
                                    this.libraryService.disableContent(item, success.comment)
                                );
                            }
                        }
                        forkJoin(observableArray).subscribe((data: any) => {
                            observer.next();
                            observer.complete();
                        });
                    }
                });
        });
    }

    archiveSelection(valueWanted): Observable<void> {
        return new Observable((observer) => {
            const title = `${valueWanted ? 'Archiver' : 'Désarchiver'} les contenus sélectionnés`;
            const message = `Êtes-vous sûr de vouloir ${
                valueWanted ? 'archiver' : 'désarchiver'
            } les contenus sélectionnés ?`;
            const action = valueWanted ? 'archived' : 'unarchived';
            const submessage = `${
                valueWanted ? 'Archiver' : 'Désarchiver'
            } les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d\'autres assemblages.`;

            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage, true)
                .subscribe((success) => {
                    if (success) {
                        const observableArray = [];
                        if (success.recursive) {
                            for (const item of this.selectedContent) {
                                if (action === 'archived') {
                                    observableArray.push(
                                        this.libraryService.recursiveAction(
                                            item,
                                            'archive',
                                            success.comment
                                        )
                                    );
                                } else {
                                    observableArray.push(
                                        this.libraryService.recursiveAction(item, 'unarchive')
                                    );
                                }
                            }
                        } else {
                            for (const item of this.selectedContent) {
                                if (action === 'archived') {
                                    observableArray.push(
                                        this.archiveService.archiveContent(item, success.comment)
                                    );
                                } else {
                                    observableArray.push(
                                        this.archiveService.unarchiveContent(item)
                                    );
                                }
                            }
                        }

                        forkJoin(observableArray).subscribe((data: any) => {
                            observer.next();
                            observer.complete();
                        });
                    }
                });
        });
    }

    deleteSelection() {
        return new Observable((observer) => {
            const title = 'Supprimer les contenus sélectionnés';
            const message = 'Êtes-vous sûr de vouloir supprimer les contenus sélectionnés ?';
            const submessage =
                "Supprimer les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";

            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage)
                .subscribe((success) => {
                    if (success) {
                        const observableArray = [];
                        if (success.recursive) {
                            for (const item of this.selectedContent) {
                                observableArray.push(
                                    this.libraryService.recursiveAction(item, 'delete')
                                );
                            }
                        } else {
                            for (const item of this.selectedContent) {
                                observableArray.push(this.libraryService.deleteContent(item));
                            }
                        }
                        forkJoin(observableArray).subscribe((data: any) => {
                            observer.next();
                            observer.complete();
                        });
                    }
                });
        });
    }

    assignSelection() {
        this.dialogService.openAdvancedAssignationDialog(this.selectedContent);
    }

    editSelection() {
        return new Observable((observer) => {
            const title = 'Copier les contenus sélectionnés';
            const message =
                "<span class='left'><span>Les contenus sélectionnés vont être copiés dans votre espace de création avec le préfixe \"Copie de\". </span></span><ul><li>Dans le cadre d'une copie, n'oubliez pas de personnaliser le titre avant publication en tant que nouveau contenu. </li><li>Dans le cadre d'une modification, n'oubliez pas de supprimer le préfixe \"Copie de\" avant publication mineure ou majeure.</li></ul> <span class='grey left'><span>N.B : Seuls les administrateurs, les valideurs et l'auteur d'un contenu peuvent faire des mises à jour.</span></span>";
            const submessage =
                "Copier les enfants de façon récursive.<br>Attention, certains enfants peuvent être présents dans d'autres assemblages.";

            this.dialogService
                .openLibraryConfirmationDialog(title, message, submessage)
                .subscribe((success) => {
                    if (success) {
                        const observableArray = [];
                        for (const item of this.selectedContent) {
                            observableArray.push(this.libraryService.duplicateContent(item, true));
                        }
                        forkJoin(observableArray).subscribe((data: any) => {
                            observer.next(data);
                            observer.complete();
                        });
                    }
                });
        });
    }

    /**
     * Vide la sélection
     */
    resetSelection(): void {
        this.selectedContent = [];
        this.selectedAssignment = [];
    }

    getSelectedAssignment() {
        return this.selectedAssignment;
    }

    isAssignmentSelected(assignment: Assignment): boolean {
        return (
            this.selectedAssignment.find(
                (element) => element.assignmentId === assignment.assignmentId
            ) !== undefined
        );
    }

    addAssignmentToSelection(assignment: Assignment): void {
        this.selectedAssignment = [...this.selectedAssignment, assignment];
    }

    /**
     * Retire un contenu sélectionné de la sélection
     * @param Assignment le contenu à ôter
     */
    removeAssignmentFromSelection(assignment: Assignment): void {
        this.selectedAssignment = [
            ...this.selectedAssignment.filter(
                (item) => item.assignmentId !== assignment.assignmentId
            )
        ];
    }

    addDisponibility() {
        this.dialogService.openAddDisponibility(this.selectedAssignment).subscribe((data: any) => {
            if (data) {
                this.selectedAssignment.map((assignment) => {
                    assignment.timestart = data.timestart;
                    assignment.timeend = data.timeend;
                });
                this.clearAssignmentSelection();
            }
        });
    }

    clearAssignmentSelection() {
        this.selectedAssignment = [];
    }
}
