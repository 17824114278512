<div class="group-assignments">
    <div class="header">
        <div class="icon-contenusassignes"></div>
        <div class="name">{{ getGroupName() }}</div>
        <div class="completion">{{ getGroupCompletion() }}</div>
        <div class="icons">
            <div class="search-container">
                <input
                    class="library-search-field"
                    [(ngModel)]="filters.search"
                    placeholder="Chercher..."
                    (keyup.enter)="getGroupAssignments()"
                />
                <span class="icon-search" (click)="getGroupAssignments()"></span>
                <span
                    class="icon-Fermerdetails"
                    *ngIf="filters.search.length"
                    (click)="cancelSearch()"
                ></span>
            </div>
            <div
                class="icon-Nouvelleversioncontenu"
                [tooltip]="'Rafraichir la liste des assignations'"
                (click)="getGroupAssignments()"
            ></div>
            <div
                class="icon-Suividesconnexions"
                [tooltip]="'Suivi du groupe'"
                (click)="openGroupReporting()"
            ></div>
            <div
                class="icon-reporting"
                [tooltip]="'Télécharger le rapport du groupe'"
                (click)="getGroupReporting($event)"
            ></div>
            <div
                class="icon-radar"
                [tooltip]="'Rapports des tests du groupe'"
                (click)="openGroupTestReporting($event)"
            ></div>
            <div class="icon-close" [tooltip]="'Retour'" (click)="goToStudentPanel()"></div>
        </div>
    </div>
    <div class="filters-container">
        <lib-uimm-dropdown
            [label]="'Domaine'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.domains"
            (itemsChange)="getGroupAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Catégorie'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.categories"
            (itemsChange)="getGroupAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Référent'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.referents"
            (itemsChange)="getGroupAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Statut'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.status"
            (itemsChange)="getGroupAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <div
            class="icon-Supprimerdugroupe"
            (click)="resetFilters()"
            *ngIf="showResetFilters()"
            tooltip="Annuler les filtres"
        ></div>
    </div>
    <div
        class="assignments-container"
        *ngIf="!isLoading()"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [alwaysCallback]="true"
        [scrollWindow]="false"
        (scrolled)="getNextAssignment()"
        (scrolledUp)="getPreviousAssignment()"
    >
        <ng-container *ngIf="getSortedAssignments().length > 0">
            <app-group-assignments-entry
                *ngFor="let assignment of getSortedAssignments(); index as i"
                [group]="group"
                [assignment]="assignment"
                [referents]="referents"
                [parent]="{ children: getSortedAssignments() }"
                [index]="i"
                (childrenAssigned)="addToAssignments($event)"
                (childrenMoved)="moveAssignment($event)"
                (assignmentDeleted)="removeAssignment($event)"
            >
            </app-group-assignments-entry>
        </ng-container>
        <app-group-assignments-entry
            *ngIf="getSortedAssignments().length === 0"
            [group]="group"
            (childrenAssigned)="addToAssignments($event)"
        ></app-group-assignments-entry>
    </div>
    <div class="assignments-container" *ngIf="isLoading()">
        <app-loading></app-loading>
    </div>
    <div class="multiselection-container" *ngIf="!isSelectionEmpty()">
        <!-- Réactiver -->
        <div
            class="multiselection-action position1 orange show"
            tooltip="Ajouter une restriction calendaire"
            (click)="addDisponibility()"
        >
            <span class="icon-calendrier"></span>
        </div>
    </div>
</div>
