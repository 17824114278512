import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PanelService {
    constructor() {}

    clickRightPanel: Subject<any> = new Subject();
    clickLeftPanel: Subject<any> = new Subject();
    scrollInLibrary: Subject<any> = new Subject();
    closedAllOpened: Subject<any> = new Subject();

    toggleRightPanel() {
        this.clickRightPanel.next();
    }

    toggleLeftPanel() {
        this.clickLeftPanel.next();
    }

    libraryScrolled() {
        this.scrollInLibrary.next();
    }

    closeAllOpened(el: ElementRef) {
        this.closedAllOpened.next(el);
    }
}
