<div class="sent-to-validation-container">
    <div class="icon" [ngClass]="getIcon()"></div>
    <div class="title" [tooltip]="getContentTooltip()" tooltipPosition="below-right">
        <div class="name">{{ content.title }}</div>
    </div>
    <div class="icons">
        <div class="hover-icons">
            <div class="icon-Fiche" (click)="viewFiche($event)" [tooltip]="'Voir la fiche'"></div>
            <div
                class="icon-guide"
                *ngIf="content.guide"
                (click)="viewGuide($event)"
                [tooltip]="'Voir le guide'"
            ></div>
            <div
                class="icon-valideur"
                (click)="editValidators($event)"
                [tooltip]="'Modifier les valideurs'"
            ></div>
            <div
                class="icon-rejeter"
                (click)="cancelValidation($event)"
                [tooltip]="'Annuler la demande de validation'"
            ></div>
            <div
                class="icon-message"
                (click)="sendReminder($event)"
                [tooltip]="'Envoyer un rappel aux valideurs'"
            ></div>
            <div
                class="icon-historique"
                (click)="openHistoryDialog($event)"
                [tooltip]="'Voir l\'historique'"
            ></div>
            <div
                class="icon-voir"
                (click)="openActivity($event)"
                [tooltip]="'Voir l\'activité'"
            ></div>
        </div>
        <div class="default-icons">
            <div class="icon-Attente" [tooltip]="'En attente de validation'"></div>
        </div>
    </div>
</div>
