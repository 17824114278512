<div class="domains-count">
    <b>{{ getDomainsCount() }}</b> domaines
</div>
<div class="scroll-container" *ngIf="!isLoading()">
    <div class="domain-entry" *ngFor="let domain of domains">
        <div class="icon-JustificationGauche"></div>
        <div class="label">{{ domain.name }}</div>
        <div
            class="icon-Editer"
            [tooltip]="'Éditer le domaine'"
            (click)="openEditDomain(domain)"
        ></div>
    </div>
</div>
<app-loading *ngIf="isLoading()"></app-loading>
<div class="add-domain">
    <div
        class="icon-plus"
        [tooltip]="'Créer un nouveau domaine'"
        (click)="openCreateDomain()"
    ></div>
</div>
