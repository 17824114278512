<div id="library-header">
    <div
        class="library-header-nav"
        [ngClass]="{
            favorites: isFavoritesView,
            archive: isArchiveView,
            report: isReportView,
            disabled: isDisabledView,
            previous: isPreviousVersionView,
            catalog: isCatalogView
        }"
    >
        <div class="library-header-nav-left">
            <div class="reduce-container" (click)="toggleLibrary()">
                <span class="icon icon-minimizebloc" [ngClass]="{ closed: reduced }"></span>
            </div>
            <span class="icon" [ngClass]="getHeaderIcon()"></span>
            <span class="library-header-nav-title">{{ getTitle() }}</span>
            <div
                class="icon-download"
                [tooltip]="getDownloadIconTooltip()"
                [ngClass]="{ hidden: !showDownloadButton() }"
                (click)="handleDownLoadButtonClick()"
            ></div>
            <span
                class="icon-Voirplus"
                tooltip="Voir toutes les actions"
                [ngClass]="{ beating: !hasSeenCatalog() }"
                [more-actions]="this"
                [more-actions-origin]="'libraryHeader'"
                [more-actions-class]="className"
                (click)="$event.stopImmediatePropagation()"
            ></span>
        </div>
        <div class="library-header-nav-right">
            <div class="search-container">
                <input
                    class="library-search-field"
                    [(ngModel)]="search"
                    placeholder="Chercher..."
                    (keyup.enter)="updateFilters('search')"
                />
                <span class="icon-search" (click)="updateFilters('search')"></span>
                <span class="icon-Fermerdetails" *ngIf="search" (click)="cancelSearch()"></span>
            </div>
        </div>
    </div>
    <div class="library-header-dropdowns" [ngClass]="{ report: isReportView }">
        <lib-uimm-dropdown
            [label]="'Domaine'"
            [labelWidth]="'110px'"
            [(items)]="domain"
            (itemsChange)="updateFilters('domain')"
            multiselect="true"
            *ngIf="domain && !isReportView"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Catégorie'"
            [labelWidth]="'110px'"
            [(items)]="category"
            (itemsChange)="updateFilters('category')"
            multiselect="true"
            *ngIf="category && !isReportView"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Type'"
            [labelWidth]="'110px'"
            [(items)]="type"
            (itemsChange)="updateFilters('type')"
            multiselect="true"
            *ngIf="!isReportView && !isCatalogView"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            label="Note"
            labelWidth="110px"
            labelMinWidth="110px"
            [(items)]="rating"
            (itemsChange)="updateFilters('rating')"
            multiselect="true"
            uniformWidth="true"
            *ngIf="!isReportView && !isCatalogView"
        ></lib-uimm-dropdown>
        <div
            class="library-dates-filter"
            #libraryDatesFilter
            [ngClass]="{ opened: datesFilterOpened }"
            (clickOutside)="onClickOutsideDatesDropdown($event)"
            *ngIf="!isCatalogView"
        >
            <div class="label" (click)="toggleDateFilterDropdown()">
                <span>Date</span><span class="actif" *ngIf="hasDatesFilters()"> (Actif)</span>
                <div class="carrot">
                    <span class="icon-deroulant"></span>
                </div>
            </div>
            <div
                class="dropdown-content"
                #libraryDatesFilterDropdownContent
                [ngClass]="{ opened: datesFilterOpened }"
            >
                <div class="creation-date-filter" *ngIf="!isReportView">
                    <div class="filter-label">
                        <span class="icon-nouveau"></span>
                        <span>Création</span>
                        <span
                            class="icon-Valider"
                            *ngIf="creationDateStart || creationDateEnd"
                            (click)="resetDates('creation')"
                        ></span>
                    </div>
                    <div class="dates-selection">
                        Du
                        <input
                            [matDatepicker]="creationDateStartInput"
                            [(ngModel)]="creationDateStart"
                            (ngModelChange)="updateFilters('creationDateStart')"
                            class="datepicker"
                            placeholder=" - "
                            (focus)="creationDateStartInput.open()"
                        />
                        <mat-datepicker #creationDateStartInput></mat-datepicker>
                        au
                        <input
                            [matDatepicker]="creationDateEndInput"
                            [(ngModel)]="creationDateEnd"
                            (ngModelChange)="updateFilters('creationDateEnd')"
                            class="datepicker"
                            placeholder=" - "
                            (focus)="creationDateEndInput.open()"
                        />
                        <mat-datepicker #creationDateEndInput></mat-datepicker>
                    </div>
                </div>
                <div class="modification-date-filter" *ngIf="!isReportView">
                    <div class="filter-label">
                        <span class="icon-misajour"></span>
                        <span>Mise-à-jour</span>
                        <span
                            class="icon-Valider"
                            *ngIf="modificationDateStart || modificationDateEnd"
                            (click)="resetDates('modification')"
                        ></span>
                    </div>
                    <div class="dates-selection">
                        Du
                        <input
                            [matDatepicker]="modificationDateStartInput"
                            [(ngModel)]="modificationDateStart"
                            (ngModelChange)="updateFilters('modificationDateStart')"
                            class="datepicker"
                            placeholder=" - "
                            (focus)="modificationDateStartInput.open()"
                        />
                        <mat-datepicker #modificationDateStartInput></mat-datepicker>
                        au
                        <input
                            [matDatepicker]="modificationDateEndInput"
                            [(ngModel)]="modificationDateEnd"
                            (ngModelChange)="updateFilters('modificationDateEnd')"
                            class="datepicker"
                            placeholder=" - "
                            (focus)="modificationDateEndInput.open()"
                        />
                        <mat-datepicker #modificationDateEndInput></mat-datepicker>
                    </div>
                </div>
                <div class="report-date-filter" *ngIf="isReportView">
                    <div class="filter-label">
                        <span class="icon-flag"></span>
                        <span>Signalement</span>
                        <span
                            class="icon-Valider"
                            *ngIf="reportDateStart || reportDateEnd"
                            (click)="resetDates('report')"
                        ></span>
                    </div>
                    <div class="dates-selection">
                        Du
                        <input
                            [matDatepicker]="reportDateStartInput"
                            [(ngModel)]="reportDateStart"
                            (ngModelChange)="updateFilters('reportDateStart')"
                            class="datepicker"
                            placeholder=" - "
                            (focus)="reportDateStartInput.open()"
                        />
                        <mat-datepicker #reportDateStartInput></mat-datepicker>
                        au
                        <input
                            [matDatepicker]="reportDateEndInput"
                            [(ngModel)]="reportDateEnd"
                            (ngModelChange)="updateFilters('reportDateEnd')"
                            class="datepicker"
                            placeholder=" - "
                            (focus)="reportDateEndInput.open()"
                        />
                        <mat-datepicker #reportDateEndInput></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>
        <lib-uimm-dropdown
            [label]="'Bibliothèque'"
            [labelWidth]="'200px'"
            [(items)]="library"
            (itemsChange)="updateFilters('library')"
            *ngIf="showLibraryDropdown && !isReportView && !isCatalogView"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Catalogue'"
            [labelWidth]="'200px'"
            [(items)]="catalog"
            [multiselect]="true"
            (itemsChange)="updateFilters('catalog')"
            *ngIf="isCatalogView"
        ></lib-uimm-dropdown>

        <lib-uimm-dropdown
            [label]="'Structure'"
            [(items)]="localStructure"
            (itemsChange)="updateFilters('localStructure')"
            labelMinWidth="110px"
            *ngIf="showStructuresDropdown || (isReportView && isNationalAdmin())"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Auteur'"
            [(items)]="authors"
            (itemsChange)="updateFilters('author')"
            [headers]="{ search: true, count: true }"
            labelMinWidth="110px"
            *ngIf="!isArchiveView && !isReportView && !isCatalogView"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Auteur'"
            [(items)]="archivedAuthors"
            (itemsChange)="updateFilters('archivedAuthors')"
            [headers]="{ search: true, count: true }"
            labelMinWidth="110px"
            *ngIf="isArchiveView"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            label="Statut"
            labelWidth="160px"
            [(items)]="reportStatusList"
            (itemsChange)="updateFilters('status')"
            [multiselect]="false"
            uniformWidth="true"
            *ngIf="isReportView"
        ></lib-uimm-dropdown>
        <div class="my-reports" *ngIf="isReportView && (isNationalAdmin() || isLocalAdmin())">
            <span
                [ngClass]="myReportsOnly ? 'icon-fichier-selected' : 'icon-fichier-select'"
                (click)="updateFilters('myReportsOnly')"
            ></span
            >Mes signalements
        </div>
        <div
            class="icon-Supprimerdugroupe"
            *ngIf="showCancelFilters"
            (click)="cancelFilters(true)"
            tooltip="Annuler les filtres"
        ></div>
    </div>
    <div *ngIf="getSelectionLength() <= 0 && !isCatalogView" class="library-header-counters">
        <div class="category-counter">
            <div class="icon icon-parcours-cercle"></div>
            <div class="label">
                <b>{{ counts.parcours }}</b> Parcours
            </div>
        </div>
        <div class="category-counter">
            <div class="icon icon-bloc-cercle"></div>
            <div class="label">
                <b>{{ counts.bloc }}</b> Blocs
            </div>
        </div>
        <div class="category-counter">
            <div class="icon icon-competence-cercle"></div>
            <div class="label">
                <b>{{ counts.competence }}</b> Compétences
            </div>
        </div>
        <div class="category-counter">
            <div class="icon icon-module-cercle"></div>
            <div class="label">
                <b>{{ counts.module }}</b> Modules
            </div>
        </div>
        <div class="category-counter">
            <div class="icon icon-sequence-cercle"></div>
            <div class="label">
                <b>{{ counts.sequence }}</b> Séquences
            </div>
        </div>
        <div class="category-counter">
            <div class="icon icon-activite-cercle"></div>
            <div class="label">
                <b>{{ counts.activity }}</b> Activités
            </div>
        </div>
    </div>
    <div class="library-header-counters" *ngIf="getSelectionLength() > 0">
        <div class="icon-Supprimerdugroupe" (click)="unselectAll()"></div>
        <div class="label" *ngIf="getSelectionLength() > 1">
            <strong>{{ getSelectionLength() }}</strong> éléments sélectionnés
        </div>
        <div class="label" *ngIf="getSelectionLength() === 1">
            <strong>{{ getSelectionLength() }}</strong> élément sélectionné
        </div>
    </div>
</div>
