<div class="header">
    <span class="icon-echanges"></span>
    <span class="title">Échanges</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <span class="legend" *ngIf="!data.firstname"
        >Voici tous les moyens que vous avez d'échanger avec ce groupe de personnes.</span
    >
    <span class="legend" *ngIf="data.firstname"
        >Voici tous les moyens que vous avez d'échanger avec cette personne.</span
    >

    <div class="easi-call" *ngIf="canView('easicall')">
        <span class="label">Call</span>
        <div class="button" (click)="goToApp('easicall')">
            <span class="icon icon-easi-call-line"></span>
            <span class="label" *ngIf="!data.firstname"
                >Créer une visioconférence avec le groupe dans <b>EASi CALL</b></span
            >
            <span class="label" *ngIf="data.firstname"
                >Créer une visioconférence avec cet utilisateur dans <b>EASi CALL</b></span
            >
            <span class="icon-multi-ecran-bis"></span>
        </div>
    </div>
    <div class="easi-forum" *ngIf="canView('easiforum')">
        <span class="label">Forum</span>
        <div class="button" (click)="goToApp('easiforum')">
            <span class="icon icon-easi-forum-line"></span>
            <span class="label" *ngIf="!data.firstname">
                Créer un forum pour le groupe dans <b>EASi FORUM</b>
            </span>
            <span class="label" *ngIf="data.firstname">
                Créer un forum pour cet utilisateur dans <b>EASi FORUM</b>
            </span>
            <span class="icon-multi-ecran-bis"></span>
        </div>
    </div>
    <div class="easi-chat" *ngIf="canView('easichat')">
        <span class="label">Chat</span>
        <div class="button" (click)="goToApp('easichat')">
            <span class="icon icon-Messages"></span>
            <span class="label" *ngIf="!data.firstname">
                Créer une conversation avec le groupe dans <b>EASi CHAT</b>
            </span>
            <span class="label" *ngIf="data.firstname">
                Créer une conversation avec cet utilisateur dans <b>EASi CHAT</b>
            </span>
            <span class="icon-multi-ecran-bis"></span>
        </div>
    </div>
</div>
