import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LibraryService } from '../../services/library.service';

import { Content } from '../../structures/content';

import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-minor-update-change',
    templateUrl: './minor-update-change.component.html',
    styleUrls: ['./minor-update-change.component.scss']
})
export class MinorUpdateChangeComponent implements OnInit {
    changes: any;

    constructor(
        public dialogRef: MatDialogRef<MinorUpdateChangeComponent>,
        @Inject(MAT_DIALOG_DATA) public content: Content,
        private libraryService: LibraryService
    ) {}

    ngOnInit() {
        this.libraryService.getMinorUpdateChange(this.content.id).subscribe((data) => {
            this.changes = data;
        });
    }

    closeDialog() {
        this.dialogRef.close(false);
    }

    validatePublication() {
        this.dialogRef.close(true);
    }
}
