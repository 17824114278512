<div class="header">
    <span class="icon-acces-devoirs"></span>
    <span class="label">
        {{ data.user ? 'Déposer le devoir corrigé' : 'Rendre un corrigé général' }}</span
    >
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="assignment">
    <div class="assignment-header">
        <div class="assignment">
            <div class="assignment-icon" [ngClass]="getIconStyle()"></div>
            <div
                class="assignment-title"
                tooltip="{{ getAssignmentTitle() }}"
                tooltipPosition="left"
            >
                {{ getAssignmentTitle() }}
            </div>
            <div
                class="assignment-target"
                tooltip="{{ getAssignmentTarget() }}"
                tooltipPosition="left"
            >
                {{ getAssignmentTarget() }}
            </div>
            <div class="assignment-information">{{ getAssignmentInformation() }}</div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="assignment-body">
        <div class="assignment-body-label">Déposer votre corrigé dans la zone ci-dessous.</div>
        <div
            class="assignment-body-container"
            (dragover)="(false)"
            (dragend)="(false)"
            (drop)="onFilesAdded($event)"
        >
            <div class="file" *ngIf="fileToUpload">
                <span class="icon icon-document"></span>
                <span class="name">{{ fileToUpload.name }}</span>
                <span class="icon-close" (click)="deleteFile()"></span>
            </div>
            <div class="icon-Importer" *ngIf="!fileToUpload"></div>
            <input type="file" #fileElement style="display: none" (change)="onFilesAdded($event)" />
        </div>
        <div class="assignment-body-footer">
            <div class="import-button" (click)="uploadFile()">Parcourir</div>
        </div>
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="upload-button" (click)="uploadDevoir()">Envoyer</div>
</div>
