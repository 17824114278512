import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class ReportingService {
    constructor(private http: HttpClient) {}

    @log()
    getCSVReporting(filters: any): Observable<any> {
        const params: any = {};

        if (filters.groupId) {
            params.group = filters.groupId;
        } else if (filters.userId) {
            params.user = filters.userId;
        }

        if (filters.contentId) {
            params.content = filters.contentId;
        }
        if (filters.type) {
            params.type = filters.type;
        }

        return this.http
            .get(`/reports/full`, { params })
            .pipe(map((data) => this.jsonToCsv(data, !!filters.groupId)));
    }

    jsonToCsv(json, forGroup): string {
        let csvContent = '';

        // Header
        csvContent +=
            "ID;Ucode;Niveau;Structure;Sites;Groupes;Nom;Prénom;Parcours;Bloc;Compétence;Module;Sequence;Activité;Catégorie d'activité;Type d'activité;Description;Assigné par;Date d'assignation;Etat;Dernière consultation;Statut;Statut forcé;Forcé par;Nombre de tentatives;Score;Temps passé;Temps passé total;Durée;% progression";
        csvContent += forGroup ? ';Meilleur score groupe;Moyenne score groupe' : '';
        csvContent += '\n';

        // Content
        for (const i in json) {
            if (json[i]) {
                let ligne = '';
                for (const j in json[i]) {
                    if (json[i][j] || json[i][j] === 0) {
                        if (this.isISODate(json[i][j])) {
                            const date = new Date(json[i][j]);
                            ligne += date.toLocaleString('fr-FR') + ';';
                        } else {
                            ligne += '"' + String(json[i][j]).replace(/"/gi, '""') + '"' + ';';
                        }
                    } else {
                        ligne += 'NA;';
                    }
                }
                ligne += json[i].maxScoreForGroup === undefined && forGroup ? 'NA;NA;' : '';
                ligne = ligne.replace(/[\r\n]+/g, ' ');
                ligne = ligne.replace(/#/g, '_');
                ligne = ligne.substring(0, ligne.length - 1);
                csvContent += ligne + '\n';
            }
        }

        return csvContent;
    }

    isISODate(string: string): boolean {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(string)) {
            return false;
        }
        const d = new Date(string);
        return d.toISOString() === string;
    }
}
