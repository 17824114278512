import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class QuizService {

    constructor( private http: HttpClient ) {}

    @log() getQuiz(quizPath: string): Observable<any> { return this.http.get(quizPath); }
    @log() exportQuiz(quizId): Observable<any> { return this.http.get(`/quiz/${quizId}/export`); }
    @log() saveQuiz(quizId): Observable<any> { return this.http.get(`/quiz/${quizId}/export`); }
}
