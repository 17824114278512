<div class="header">
    <div class="icon-calendrier"></div>
    <div class="title">Ajouter une restriction calendaire</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content">
    <div class="top">
        <ng-container *ngIf="!isAssignmentArray()">
            <div class="entry">
                <div class="entry-icon" [ngClass]="getIcon()"></div>
                <div class="entry-title">{{ assignment.title }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="isAssignmentArray()">
            <div class="entry" *ngFor="let assignment of initialAssignment">
                <div class="entry-icon" [ngClass]="getIcon()"></div>
                <div class="entry-title">{{ assignment.title }}</div>
            </div>
        </ng-container>
    </div>
    <div class="separator"></div>
    <div class="main-body">
        <div class="datepicker-start-container">
            <div class="label">Date et heure de début</div>
            <input
                type="text"
                class="start-date-input"
                [matDatepicker]="startDateInput"
                [(ngModel)]="startDate"
                placeholder="Date"
            />
            <input type="time" required class="start-time-input" [(ngModel)]="startTime" />
            <mat-calendar [(selected)]="startDate"></mat-calendar>
        </div>
        <div class="datepicker-end-container">
            <div class="label">Date et heure de fin</div>
            <input
                type="text"
                class="end-date-input"
                [matDatepicker]="endDateInput"
                [(ngModel)]="endDate"
                placeholder="Date"
            />
            <input
                type="time"
                [required]="!!endDate"
                class="end-time-input"
                [(ngModel)]="endTime"
            />
            <div
                class="no-end-date"
                (click)="removeEndDate()"
                [ngClass]="{ selected: endDate === null }"
            >
                Sans date de fin
            </div>
            <mat-calendar [(selected)]="endDate" (selectedChange)="setEndTime()"></mat-calendar>
        </div>
        <div class="options" [ngClass]="{ disabled: !endDate }">
            <div class="option lock-content-after-enddate">
                <div
                    [ngClass]="assignment.lock_after_enddate ? 'icon-selected' : 'icon-select'"
                    (click)="toggleLockAfterEndDate($event)"
                ></div>
                <div class="label">Contenu bloqué une fois l'échéance passée</div>
            </div>
            <div class="option automatic-remind-mail">
                <div
                    [ngClass]="assignment.enddate_reminders ? 'icon-selected' : 'icon-select'"
                    (click)="toggleAutoRemindMail($event)"
                ></div>
                <div class="label">
                    Activer l'envoi automatique d'un rappel par mail 72h et 24h avant l'échéance de
                    fin
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="button" (click)="setDisponibilityToAssignment()" *ngIf="!isLoading()">
        Enregistrer
    </div>
    <div class="button" *ngIf="isLoading()"><app-loading></app-loading></div>
</div>
