<div class="header">
    <div class="title">
        Révéler l'identité des apprenants et afficher les notes
    </div>
</div>
<mat-dialog-content class="content" [innerHTML]="getMainMessage()"> </mat-dialog-content>
<div class="footer" *ngIf="firstQuestionConfirmed">
    <div class="button zero" (click)="noteToZero()">Noter à 0</div>
    <div class="button no-note" (click)="giveNoNote()">Ne pas noter</div>
    <div class="cancel full-width button" (click)="closeDialog()">Annuler</div>
</div>
<div class="footer"*ngIf="!firstQuestionConfirmed">
    <div class="confirm button" *ngIf="!firstQuestionConfirmed" (click)="action()">OUI</div>
    <div class="cancel button" (click)="closeDialog()">Annuler</div>
</div>
