<div class="library" [ngClass]="{ hidden: isParentOfView }">
    <app-library-header
        [filters]="filters"
        (filtersChange)="updateFilters($event)"
        [isCatalogView]="isCatalogView"
        [isFavoritesView]="isFavoritesView"
        [isArchiveView]="isArchiveView"
        [isReportView]="isReportView"
        [isDisabledView]="isDisabledView"
        [isPreviousVersionView]="isPreviousVersionView"
        (viewChange)="handleViewChange($event)"
        [counts]="counts"
        [showCancelFilters]="isAnyFilterSet()"
        (downloadReportedContents)="downloadReportedContents()"
        (downloadCatalogContents)="downloadCatalogContents()"
    ></app-library-header>
    <div
        class="scrollbar-container"
        *ngIf="!isLoading()"
        infiniteScroll
        #scrollbarContainer
        cdkScrollable
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="getContents()"
        (scroll)="onScroll($event)"
    >
        <div class="library-content">
            <div class="no-results" *ngIf="!hasResults()">
                <p>
                    Pour afficher le contenu de la bibliothèque dont vous avez besoin, utilisez les
                    filtres et le moteur de recherche ci-dessus.
                    <br /><br />
                    Vous pouvez utiliser l'ID ou le CODE d'un contenu pour le retrouver encore plus
                    facilement.
                </p>
                <div>
                    <img src="./assets/img/library-default.png" />
                </div>
            </div>
            <div *ngIf="hasResults()">
                <app-library-entry
                    *ngFor="let entry of contents"
                    [entryContent]="entry"
                    [isArchiveView]="isArchiveView"
                    [isReportView]="isReportView"
                    [isDisabledView]="isDisabledView"
                    [isCatalogView]="isCatalogView"
                    [isPreviousVersionView]="isPreviousVersionView"
                    (parentOf)="getParentOf($event)"
                    [parentOfMode]="false"
                    (searchInLibrary)="searchInLibrary($event)"
                >
                </app-library-entry>
            </div>
        </div>
    </div>
    <div class="scrollbar-container" *ngIf="isLoading()">
        <app-loading></app-loading>
    </div>
    <app-loading *ngIf="isLoadingNextPage()"></app-loading>
</div>

<!-- View Parent Of -->
<div class="library parent-of" *ngIf="isParentOfView">
    <div class="header-parent-of">
        <div class="icon-back" (click)="exitParentOfView()"></div>
        <div class="label">Parents de</div>
        <div class="content">
            <div class="icon" [ngClass]="getParentOfIconStyle()"></div>
            <div class="title-container">
                <div class="title">{{ getParentOfContentTitle() }}</div>
                <div class="type">{{ getParentOfContentType() }}</div>
            </div>
        </div>
    </div>
    <div
        class="scrollbar-container"
        infiniteScroll
        #scrollbarContainer
        cdkScrollable
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="nextParentOf()"
    >
        <div class="library-content">
            <div class="no-results" *ngIf="!hasParentOfResults() && !isLoading()">
                <div class="no-assignments">Aucun parent trouvé pour ce contenu</div>
            </div>
            <div *ngIf="hasParentOfResults() && !isLoading()">
                <app-library-entry
                    *ngFor="let child of contentParents"
                    [entryContent]="child"
                    [parentOfMode]="true"
                    [parentOfId]="parentOfContent.id"
                    (parentOf)="getParentOf($event)"
                ></app-library-entry>
            </div>
            <app-loading *ngIf="isLoading()"></app-loading>
        </div>
    </div>
    <app-loading *ngIf="isLoadingNextPage()"></app-loading>
</div>
<div class="multiselection-container" *ngIf="!isSelectionEmpty()">
    <!-- Réactiver -->
    <div
        class="multiselection-action position2 orange"
        [ngClass]="{ show: !isArchiveView && !isReportView && isSelectionDisabled() }"
        tooltip="Réactiver les contenus sélectionnés"
        (click)="enableSelection()"
    >
        <span class="icon-Reactiver"></span>
    </div>

    <!-- Archiver -->
    <div
        class="multiselection-action position3"
        [ngClass]="{ show: !isArchiveView && !isReportView && isSelectionDisabled() }"
        tooltip="Archiver les contenus sélectionnés"
        (click)="archiveSelection()"
    >
        <span class="icon-Miseenapplcation"></span>
    </div>

    <!-- Modifier -->
    <div
        class="multiselection-action position1"
        [ngClass]="{ show: !isArchiveView && !isReportView }"
        tooltip="Copier les contenus sélectionnés"
        (click)="editSelection()"
    >
        <span class="icon-Cloner"></span>
    </div>

    <!-- Désactiver -->
    <div
        class="multiselection-action position2"
        [ngClass]="{ show: !isArchiveView && !isReportView && !isSelectionDisabled() }"
        tooltip="Désactiver les contenus sélectionnés"
        (click)="disableSelection()"
    >
        <span class="icon-DesactiverContenu"></span>
    </div>

    <!-- Assigner -->
    <div
        class="multiselection-action position3 orange"
        [ngClass]="{ show: !isArchiveView && !isReportView && isAllSelectionEnabled() }"
        tooltip="Assigner les contenus sélectionnés"
        (click)="assignSelection()"
    >
        <span class="icon-Assignationmultiple"></span>
    </div>

    <!-- Supprimer -->
    <div
        class="multiselection-action position1"
        [ngClass]="{ show: isArchiveView && !isReportView }"
        tooltip="Supprimer les contenus sélectionnés"
        (click)="deleteSelection()"
    >
        <span class="icon-bin"></span>
    </div>

    <!-- Désarchiver -->
    <div
        class="multiselection-action position2 orange"
        [ngClass]="{ show: isArchiveView && !isReportView }"
        tooltip="Désarchiver les contenus sélectionnés"
        (click)="unarchiveSelection()"
    >
        <span class="icon-archiver"></span>
    </div>
</div>

<!-- <app-dragover-info *ngIf="tooltipMessage" [text]="tooltipMessage"></app-dragover-info> -->
