import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';

import { DndModule } from 'ngx-drag-drop';
import { DropdownModule } from 'libs/uimm-dropdown/src/public-api';
import { TooltipModule } from 'libs/uimm-tooltip/src/public-api';

import { LoadingModule } from '../modules/loading.module';
import { SharedModule } from './shared.module';

import { CreationSpaceSearchPipe } from '../pipes/creation-space-search.pipe';

import { CreationSpaceComponent } from '../components/teacher/creation-space/creation-space.component';
import { ActivityCreationComponent } from '../components/teacher/creation-space/activity-creation/activity-creation.component';
import { AssemblyCreationComponent } from '../components/teacher/creation-space/assembly-creation/assembly-creation.component';
import { ContentUpdateComponent } from '../components/teacher/creation-space/content-update/content-update.component';

import { ValidationSpaceComponent } from '../components/teacher/validation-space/validation-space.component';
import { SentToValidationComponent } from '../components/teacher/validation-space/sent-to-validation/sent-to-validation.component';
import { WaitingForApprovalComponent } from '../components/teacher/validation-space/waiting-for-approval/waiting-for-approval.component';

import { AddValidatorComponent } from '../dialogs/add-validator/add-validator.component';
import { ApproveContentComponent } from '../dialogs/approve-content/approve-content.component';
import { AssemblyConfirmationComponent } from '../dialogs/assembly-confirmation/assembly-confirmation.component';
import { AssemblyConfirmationEntryComponent } from '../dialogs/assembly-confirmation/assembly-confirmation-entry/assembly-confirmation-entry.component';

import { ContentCreationComponent } from '../dialogs/content-creation/content-creation.component';
import { ContentHistoryComponent } from '../dialogs/content-history/content-history.component';
import { ContentSelectionComponent } from '../dialogs/content-selection/content-selection.component';
import { DelegateValidationComponent } from '../dialogs/delegate-validation/delegate-validation.component';
import { MassImportComponent } from '../dialogs/mass-import/mass-import.component';
import { MassUpdateComponent } from '../dialogs/mass-update/mass-update.component';
import { RedirectMediaComponent } from '../dialogs/redirect-media/redirect-media.component';
import { RejectContentComponent } from '../dialogs/reject-content/reject-content.component';
import { SelectPublicationComponent } from '../dialogs/select-publication/select-publication.component';
import { SelectValidatorComponent } from '../dialogs/select-validator/select-validator.component';

import { FileDropzoneDirective } from '../directives/file-dropdown.directive';

@NgModule({
    declarations: [
        CreationSpaceComponent,
        ActivityCreationComponent,
        AssemblyCreationComponent,
        ContentUpdateComponent,
        ValidationSpaceComponent,
        SentToValidationComponent,
        WaitingForApprovalComponent,
        AddValidatorComponent,
        ApproveContentComponent,
        AssemblyConfirmationComponent,
        AssemblyConfirmationEntryComponent,
        ContentCreationComponent,
        ContentHistoryComponent,
        ContentSelectionComponent,
        DelegateValidationComponent,
        MassImportComponent,
        MassUpdateComponent,
        RedirectMediaComponent,
        RejectContentComponent,
        SelectPublicationComponent,
        SelectValidatorComponent,
        CreationSpaceSearchPipe,
        FileDropzoneDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatExpansionModule,
        MatDialogModule,
        DndModule,
        TooltipModule,
        DropdownModule,
        LoadingModule,
        SharedModule
    ],
    exports: [
        CreationSpaceComponent,
        ActivityCreationComponent,
        AssemblyCreationComponent,
        ContentUpdateComponent,
        ValidationSpaceComponent,
        SentToValidationComponent,
        WaitingForApprovalComponent,
        AddValidatorComponent,
        ApproveContentComponent,
        AssemblyConfirmationComponent,
        AssemblyConfirmationEntryComponent,
        ContentCreationComponent,
        ContentHistoryComponent,
        ContentSelectionComponent,
        DelegateValidationComponent,
        MassImportComponent,
        MassUpdateComponent,
        RedirectMediaComponent,
        RejectContentComponent,
        SelectPublicationComponent,
        SelectValidatorComponent,
        CreationSpaceSearchPipe,
        FileDropzoneDirective
    ],
    providers: []
})
export class CreationSpaceModule {}
