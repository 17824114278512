<div class="header">
    <div class="icon-Publier"></div>
    <div class="title">{{ getDialogTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading()">
    <div class="entry-container">
        <div class="entry">
            <div class="entry-icon" [ngClass]="getIcon()"></div>
            <div class="entry-title">{{ getTitle() }}</div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="main-container">
        <div class="label">Choisissez un mode de publication</div>

        <div class="comment" *ngIf="canPublishMinorUpdate() || canPublishMajorUpdate()">
            <div class="label">Commentaire :</div>
            <textarea
                [(ngModel)]="comment"
                placeholder="Saisissez ici votre commentaire (128 caractères maximum)"
                maxlength="128"
            ></textarea>
        </div>

        <div class="new" *ngIf="canPublishNew()">
            <div class="button" (click)="setPublicationMode('new')">
                Publier en tant que nouveau contenu
            </div>
            <div class="legend">{{ getPublishNewText() }}</div>
        </div>
        <div class="minor-update" *ngIf="canPublishMinorUpdate()">
            <div class="button" (click)="setPublicationMode('minor-update')">
                Publier en tant que mise à jour mineure
            </div>
            <div class="legend">
                Tous les apprenants auront accès à cette version, y compris ceux à qui ce contenu
                avait déjà été assigné.
            </div>
        </div>
        <div class="major-update" *ngIf="canPublishMajorUpdate()">
            <div class="button" (click)="setPublicationMode('major-update')">
                Publier en tant que mise à jour majeure
            </div>
            <div class="legend">
                Les apprenants qui avaient déjà ce contenu assigné verront toujours l'ancienne
                version.
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content" *ngIf="isLoading()">
    <app-loading></app-loading>
</mat-dialog-content>
