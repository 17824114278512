import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from 'src/app/services/login.service';

import { DialogService } from '../../../../services/dialog.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { AssignmentService } from '../../../../services/assignment.service';
import { GroupService } from '../../../../services/group.service';

import { Assignment } from 'src/app/structures/assignment';
import { Group } from '../../../../structures/group';

import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-role-group',
    templateUrl: './role-group.component.html',
    styleUrls: ['./role-group.component.scss']
})
export class RoleGroupComponent {
    @Input() roleGroup: Group;

    @Output() favoriteAdded: EventEmitter<any> = new EventEmitter();
    @Output() favoriteRemoved: EventEmitter<any> = new EventEmitter();

    opened: boolean;

    constructor(
        private router: Router,
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService,
        private loginService: LoginService,
        private assignmentService: AssignmentService,
        private groupService: GroupService
    ) {}

    getRoleGroupName(): string {
        return this.roleGroup.name;
    }

    goToGroupAssignment($event) {
        $event.stopImmediatePropagation();
        if (this.loginService.getUser().roles.tutor) {
            this.router.navigate(['/tutor/group/' + this.roleGroup.id], {
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate(['/teacher/group/' + this.roleGroup.id], {
                queryParamsHandling: 'merge'
            });
        }
    }

    checkAssignContentToGroup() {
        const draggedElement = JSON.parse(
            JSON.stringify(this.assignmentService.getDraggedElement())
        );
        if (draggedElement.hasprice) {
            this.dialogService.openSelectFIFCDialog().subscribe((FI: boolean) => {
                this.assignContentToGroup(draggedElement, FI);
            });
        } else {
            this.assignContentToGroup(draggedElement, undefined);
        }
    }

    assignContentToGroup(element: Assignment, FI: boolean) {
        this.assignmentService
            .assignContentToGroup(element, this.roleGroup, undefined, undefined, FI)
            .subscribe((data: any) => {
                if (data.show_warning_cron) {
                    this.dialogService.openInfo(
                        "Pour ne pas vous bloquer dans votre navigation et vous permettre d'utiliser EASi sans interruption, Cette demande sera traitée par nos systèmes dans les meilleurs délais."
                    );
                    this.assignmentService.clearDraggedElement();
                    this.assignmentService.clearDraggedMode();
                    return;
                }
                this.flashMessageService.flash(
                    `Le contenu <b>${
                        element.title
                    }</b> a été assigné au groupe <b>${this.getRoleGroupName()}</b>`
                );
                if (element.type === 'devoir') {
                    this.dialogService.openDevoirConfirmation().subscribe((userInput: any) => {
                        if (userInput) {
                            this.groupService
                                .markAssignmentAsAnonymous(data.assignment.assignmentId)
                                .subscribe(() => {
                                    this.flashMessageService.flash(
                                        `L'assignation a été marqué comme anonyme`
                                    );
                                });
                        }
                    });
                }
                this.dialogService.openAddDisponibility(data.assignment).subscribe((data2: any) => {
                    if (data2) {
                        this.flashMessageService.flash('La restriction calendaire a été ajoutée');
                    }
                });
                this.assignmentService.clearDraggedElement();
                this.assignmentService.clearDraggedMode();
            });
    }

    addToFavorites($event) {
        $event.stopImmediatePropagation();
        this.favoriteAdded.emit({ type: 'role-group', element: this.roleGroup });
    }

    removeFromFavorites($event) {
        $event.stopImmediatePropagation();
        this.favoriteRemoved.emit({ type: 'role-group', element: this.roleGroup });
    }
}
