<div class="role-group-container" dndDropzone (dndDrop)="checkAssignContentToGroup()">
    <div class="label">
        <div class="icon-porte-voix"></div>
        <div class="name">
            {{ getRoleGroupName() }}
        </div>
    </div>
    <div class="icons">
        <div class="hover-icons">
            <div
                class="icon-contenusassignes"
                [tooltip]="'Voir les contenus assignés communs au groupe'"
                (click)="goToGroupAssignment($event)"
            ></div>
            <div
                class="icon-favoris"
                [tooltip]="'Ajouter aux favoris'"
                *ngIf="!roleGroup.favorite"
                (click)="addToFavorites($event)"
            ></div>
            <div
                class="icon-Retirerdesfavoris"
                [tooltip]="'Retirer des favoris'"
                *ngIf="roleGroup.favorite"
                (click)="removeFromFavorites($event)"
            ></div>
        </div>
        <div class="default-icons">
            <div class="icon-favoris" [tooltip]="'Groupe favori'" *ngIf="roleGroup.favorite"></div>
        </div>
    </div>
</div>
