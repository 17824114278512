<div class="header">
    <span class="icon-rejeter"></span>
    <span class="title">Approuver le contenu</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <div class="entry-container">
        <div class="entry">
            <span class="entry-icon" [ngClass]="getIcon()"></span>
            <span class="entry-title">{{ getTitle() }}</span>
        </div>
    </div>
    <div class="separator"></div>
    <div class="main-body">
        <div class="main-body-label">
            Message à destination du créateur du
            contenu *
        </div>
        <textarea [(ngModel)]="comment" placeholder="Ecrivez ici votre message"></textarea>
    </div>
</div>
<div class="footer">
    <div class="button" (click)="approveContent()" [ngClass]="{ disabled: !canApproveContent() }">
        Approuver le contenu
    </div>
</div>
