import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { WarningComponent } from '../dialogs/warning/warning.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { ConfirmationComponent } from '../dialogs/confirmation/confirmation.component';

import { DatesPipe } from '../pipes/dates.pipe';
import { UserSearchPipe } from '../pipes/user-search.pipe';
import { InfoComponent } from '../dialogs/info/info.component';

@NgModule({
    declarations: [
        DatesPipe,
        UserSearchPipe,
        WarningComponent,
        ErrorComponent,
        ConfirmationComponent,
        InfoComponent
    ],
    imports: [CommonModule, MatDialogModule],
    exports: [DatesPipe, UserSearchPipe, WarningComponent, ErrorComponent, ConfirmationComponent]
})
export class SharedModule {}
