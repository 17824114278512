import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    constructor(
        private http: HttpClient,
    ) {}

    @log() createDomain(name: string) { return this.http.post(`/domains`, { name }); }

    @log() updateDomain(oldName: string, newName: string) { return this.http.put(`/domains/${oldName}`, { name: newName }); }

    @log() createProvider(name: string) { return this.http.post(`/providers`, { name }); }
}
