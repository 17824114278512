import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { LoginService } from './login.service';

import { Content } from '../structures/content';
import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class ArchiveService {
    constructor(private http: HttpClient, private loginService: LoginService) {}

    contentAddedToArchive: Subject<Content>;
    contentRemovedFromArchive: Subject<Content>;

    @log() getArchivedContents(filters: any) {
        let params = {
            offset: filters.offset,
            limit: filters.limit
        };

        if (
            this.loginService.getUser().roles.externalTeacher ||
            this.loginService.getUser().roles.siteTeacher ||
            this.loginService.getUser().roles.corporationTeacher
        ) {
            filters.localStructure = this.loginService.getUser().localStructure;
        } else if (!this.loginService.getUser().roles.nationalAdmin) {
            filters.localStructure = '|' + this.loginService.getUser().localStructure;
        }

        params = this.parseParamsForGetContents(filters, params);

        return this.http.get(`/contents/archive`, { params });
    }

    @log() getArchivedContentsCount(filters: any) {
        let params = {
            offset: filters.offset,
            limit: filters.limit
        };

        if (
            this.loginService.getUser().roles.externalTeacher ||
            this.loginService.getUser().roles.siteTeacher ||
            this.loginService.getUser().roles.corporationTeacher
        ) {
            filters.localStructure = this.loginService.getUser().localStructure;
        } else if (!this.loginService.getUser().roles.nationalAdmin) {
            filters.localStructure = '|' + this.loginService.getUser().localStructure;
        }

        params = this.parseParamsForGetContents(filters, params);

        return this.http.get(`/contents/archive?count`, { params });
    }

    @log() archiveContent(content: Content, comment?: string): Observable<boolean> {
        return this.http.post<boolean>(`/contents/archive/${content.id}/archived`, { comment });
    }

    @log() unarchiveContent(content: Content): Observable<boolean> {
        const params = {};
        return this.http.post<boolean>(
            `/contents/archive/${content.id}/unarchived`,
            {},
            { params }
        );
    }

    parseParamsForGetContents(filters: object, params: any) {
        for (const prop in filters) {
            if (
                Object.prototype.hasOwnProperty.call(filters, prop) &&
                filters[prop] &&
                filters[prop] !== '' &&
                filters[prop].length > 0
            ) {
                if (
                    prop === 'timecreatedstart' ||
                    prop === 'timecreatedend' ||
                    prop === 'timemodifiedstart' ||
                    prop === 'timemodifiedend'
                ) {
                    params = {
                        ...params,
                        [prop]: filters[prop]
                    };
                } else if (prop === 'searchTerm') {
                    params = {
                        ...params,
                        search: filters[prop]
                    };
                } else if (Array.isArray(filters[prop])) {
                    params = {
                        ...params,
                        [prop]: filters[prop].join('|')
                    };
                } else if (typeof filters[prop] === 'object' && typeof filters[prop] !== null) {
                    let req = '';
                    for (const key in filters[prop]) {
                        if (filters[prop].hasOwnProperty(key)) {
                            req += filters[prop][key] + '|';
                        }
                    }
                    params = {
                        ...params,
                        [prop]: req.substring(0, req.length - 1)
                    };
                } else {
                    params = {
                        ...params,
                        [prop]: filters[prop]
                    };
                }
            }
        }
        return params;
    }

    emitContentAddedToArchive(content: Content) {
        this.contentAddedToArchive.next(content);
    }

    emitContentRemovedFromArchive(content: Content) {
        this.contentRemovedFromArchive.next(content);
    }
}
