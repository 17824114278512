<div class="header">
    <div class="title">Téléchargement d'une bibliothèque</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<div class="content">
    <div class="label">
        Télécharger ici la liste des contenus de la bibliothèque locale ou nationale.<br />
        <i>Le fichier des contenus des bibliothèque sont générés chaque nuit.</i>
    </div>
    <div class="buttons">
        <div class="button" (click)="downloadLocalLibrary()" *ngIf="!disabledLocalLibrary">
            <div *ngIf="!loadingLocal">Bibliothèque locale</div>
            <app-loading *ngIf="loadingLocal"></app-loading>
        </div>
        <div
            class="button disabled"
            *ngIf="disabledLocalLibrary"
            [tooltip]="'Veuillez sélectionner une structure dans la bibliothèque.'"
            tooltipPosition="below-right"
        >
            <div *ngIf="!loadingLocal">Bibliothèque locale</div>
            <app-loading *ngIf="loadingLocal"></app-loading>
        </div>
        <div class="button" (click)="downloadNationalLibrary()">
            <div *ngIf="!loadingNational">Bibliothèque nationale</div>
            <app-loading *ngIf="loadingNational"></app-loading>
        </div>
    </div>
</div>
