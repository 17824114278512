<div class="header">
    <div class="icon-Corrige"></div>
    <div class="label">Suivi du groupe</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading('mainViewGroupTracking')">
    <div class="entry-container">
        <div class="entry">
            <div [ngClass]="getContentIcon()"></div>
            <div class="entry-title">{{ assignment.title }}</div>
            <div class="entry-dates" *ngIf="assignment.timeend">
                Devoir à rendre du
                {{ getDevoirDates(assignment.timestart) | date: 'dd/MM/yyyy' }} au
                {{ getDevoirDates(assignment.timeend) | date: 'dd/MM/yyyy' }}
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="search">
        <div class="search-stats" [ngSwitch]="assignment.type">
            <div
                class="icon-desassigne"
                tooltip="Désassigner"
                *ngIf="canHideAssignment()"
                (click)="hideAssignment()"
            ></div>
            <div
                class="icon-assigner"
                tooltip="Réassigner"
                *ngIf="!canHideAssignment()"
                (click)="showAssignment()"
            ></div>

            <div
                class="icon-message"
                tooltip="Envoyer un rappel au groupe"
                (click)="sendReminder()"
            ></div>
            <div
                class="icon-download"
                tooltip="Télécharger le rapport au format .CSV"
                (click)="downloadCSVReport()"
            ></div>
            <ng-container *ngSwitchCase="undefined">
                <div
                    class="icon-radar"
                    tooltip="Voir le rapport des tests du groupe"
                    (click)="openGroupTestReporting()"
                ></div>
            </ng-container>
            <ng-container *ngSwitchCase="'devoir'">
                <a [href]="getDownloadAllDevoirsLink()" *ngIf="getNumberOfCompleted() > 0">
                    <div class="icon-download" tooltip="Télécharger tous les devoirs"></div>
                </a>
                <div
                    class="icon-Miseenapplcation"
                    [tooltip]="getTooltipUploadDevoir()"
                    (click)="openUploadDevoirCorrectionGroup()"
                ></div>
                <div
                    class="icon-rafraichir"
                    tooltip="Proposer un nouveau dépôt au groupe"
                    (click)="resetGroupTracking()"
                ></div>
            </ng-container>
            <ng-container *ngSwitchCase="'quiz'">
                <div
                    class="icon-radar"
                    tooltip="Voir le rapport du test"
                    (click)="openGroupTestReporting()"
                ></div>
                <div
                    class="icon-rafraichir"
                    tooltip="Proposer une nouvelle tentative au groupe"
                    (click)="resetGroupTracking()"
                ></div>
            </ng-container>
        </div>
        <div class="search-container">
            <input type="text" [(ngModel)]="searchInput" placeholder="Chercher..." />
            <div class="icon-search" ng-show="searchInput.length == 0"></div>
            <div class="icon-close" (click)="cancelSearch()" ng-show="searchInput.length > 0"></div>
        </div>
    </div>
    <div class="learner-container">
        <ng-container *ngFor="let user of tracking">
            <div
                class="learner"
                *ngIf="inSearchResult(user)"
                [ngClass]="{ anonymous: isAnonymous(), hidden: user.hidden }"
            >
                <div class="learner-wrapper">
                    <div class="icon-apprenant" *ngIf="!isAnonymous()"></div>
                    <div class="icon-logAs" *ngIf="isAnonymous()"></div>
                    <div class="learner-name">
                        {{ user.lastname.toUpperCase() }} {{ user.firstname }}
                    </div>
                </div>
                <div class="icons" [ngSwitch]="assignment.type">
                    <div
                        class="icon-desassigne"
                        *ngIf="!isAssignmentDisabled(user)"
                        tooltip="Désassigner"
                        (click)="hideAssignment(user)"
                    ></div>
                    <div
                        class="icon-assigner"
                        *ngIf="isAssignmentDisabled(user)"
                        [tooltip]="'Réassigner'"
                        (click)="showAssignment(user)"
                    ></div>
                    <div
                        class="icon-message"
                        tooltip="Envoyer un rappel à l'apprenant"
                        (click)="sendReminder(user)"
                    ></div>
                    <div
                        class="icon-Valide"
                        tooltip="Forcer la validation"
                        (click)="validateAssignment(user)"
                        *ngIf="canForceAssignment() && !isAssignmentForced(user)"
                    ></div>
                    <div
                        class="icon-ForcerInvalidation"
                        tooltip="Annuler : Forcer la validation"
                        (click)="invalidateAssignment(user)"
                        *ngIf="canForceAssignment() && isAssignmentForced(user)"
                    ></div>
                    <ng-container *ngSwitchCase="undefined">
                        <div
                            class="icon-radar"
                            tooltip="Voir le rapport des tests de l'utilisateur"
                            (click)="openUserTestReporting(user)"
                        ></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'devoir'">
                        <div
                            class="icon-Corrige"
                            tooltip="Noter le devoir"
                            *ngIf="user.submitted_file && !(isAnonymous() && user.score)"
                            (click)="toggleUserBeingNoted(user)"
                        ></div>
                        <span
                            class="icon-Miseenapplcation"
                            [tooltip]="getTooltipCorrectionUser(user)"
                            *ngIf="user.submitted_file"
                            (click)="openUploadDevoirCorrectionUser(user)"
                        ></span>
                        <a
                            [href]="user.submitted_file"
                            target="_blank"
                            *ngIf="user.status === 'completed' && user.submitted_file"
                            ><div class="icon-devoir-actif" [tooltip]="'Voir le devoir'"></div>
                        </a>
                        <div
                            class="icon-rafraichir"
                            tooltip="Proposer un nouveau dépôt"
                            *ngIf="user.submitted_file && !(isAnonymous() && user.score)"
                            (click)="resetTracking(user)"
                        ></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'presentiel'">
                        <div
                            class="icon-Corrige"
                            tooltip="Noter le TP (présentiel)"
                            (click)="toggleUserBeingNoted(user)"
                        ></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'quiz'">
                        <div
                            class="icon-Miseenapplcation"
                            *ngIf="canStartReview(user)"
                            (click)="startReview(user)"
                            [tooltip]="'Voir la copie de l\'apprenant'"
                        ></div>
                        <div
                            class="icon-radar"
                            tooltip="Voir le rapport du test de l'apprenant"
                            (click)="openUserTestReporting(user)"
                        ></div>
                        <div
                            class="icon-rafraichir"
                            *ngIf="canResetTracking(user)"
                            (click)="resetTracking(user)"
                            tooltip="Proposer une nouvelle tentative"
                        ></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'scorm'">
                        <div
                            class="icon-rafraichir"
                            *ngIf="canResetTracking(user)"
                            (click)="resetTracking(user)"
                            tooltip="Proposer une nouvelle tentative"
                        ></div>
                    </ng-container>
                </div>
                <div class="hash" *ngIf="user.hash && isAnonymous()">{{ user.hash }}</div>
                <div class="upload-date">
                    <span *ngIf="showDateTooltip()" [tooltip]="getDateTooltip(user)">
                        {{ getDateToShow(user) }}
                    </span>
                    <span *ngIf="!showDateTooltip()">
                        {{ getDateToShow(user) }}
                    </span>
                </div>

                <div class="tracking">
                    <ng-container [ngSwitch]="assignment.type">
                        <ng-container *ngSwitchCase="undefined">
                            <div class="score">({{ user.completion }}%)</div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'scorm'">
                            <ng-container *ngIf="user.score">
                                <div class="score">({{ user.score }}/100)</div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'quiz'">
                            <div class="score" [tooltip]="getQuizTooltip(user)">
                                {{ user.score }}/100
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'presentiel'">
                            <ng-container *ngIf="user.isBeingNoted">
                                <div class="notation-input">
                                    <input
                                        type="number"
                                        name=""
                                        min="0"
                                        max="100"
                                        step="1"
                                        [(ngModel)]="user.score"
                                    />
                                </div>
                                <div class="notation-button" (click)="postNote(user, true)">
                                    Réussi
                                </div>
                                <div class="notation-button failed" (click)="postNote(user, false)">
                                    Non réussi
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!user.isBeingNoted">
                                <div class="score">{{ user.score }}/100</div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'devoir'">
                            <ng-container *ngIf="user.isBeingNoted">
                                <div class="notation-input">
                                    <input
                                        type="number"
                                        name=""
                                        min="0"
                                        max="100"
                                        step="1"
                                        [(ngModel)]="user.temporaryScore"
                                    />
                                </div>
                                <div class="notation-button" (click)="postNoteDevoir(user)">
                                    Noter
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!user.isBeingNoted">
                                <div class="score" *ngIf="user.score">{{ user.score }}/100</div>
                                <div
                                    class="score temporary"
                                    *ngIf="!user.score && user.temporaryScore"
                                >
                                    {{ user.temporaryScore }}/100
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <div
                        class="icon"
                        [ngClass]="getIconTracking(user)"
                        [tooltip]="getTooltipTracking(user)"
                    ></div>
                </div>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content" *ngIf="isLoading('mainViewGroupTracking')">
    <app-loading></app-loading>
</mat-dialog-content>
<div class="footer">
    <div
        class="final-notes-checkbox-container"
        *ngIf="!isAnonymous() && assignment.type === 'devoir' && showNoteDefinitiveCheckbox()"
    >
        <div class="checkbox-label">
            Afficher les notes, les devoirs corrigés et le corrigé général aux apprenants. Afficher
            les notes dans la vue formateur et dans le tracking.
        </div>
        <div
            class="checkbox"
            [ngClass]="saveNotesAsDefinitive ? 'icon-selected' : 'icon-select'"
            (click)="toggleNotesDefinitive()"
        ></div>
    </div>
    <div class="button" *ngIf="!isLoading('groupTrackingButton')" (click)="gradeGroup()">OK</div>
    <div
        class="button anonymous-definitive-discover"
        [ngClass]="{ disabled: isRevealButtonDisabled() }"
        *ngIf="isAnonymous() && !assignment.is_revealed"
        (click)="openAnonymousRevealConfirmation()"
    >
        Révéler l’identité des apprenants et afficher les notes
    </div>
    <div class="button" *ngIf="isLoading('groupTrackingButton')"><app-loading></app-loading></div>
</div>
