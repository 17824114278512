import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { Group } from '../structures/group';
import { Assignment } from '../structures/assignment';
import { log } from './decorators/log.decorator';
import { User } from '../structures/user';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class GroupService {
    constructor(private http: HttpClient, private configService: ConfigService) {}

    /**
     * @deprecated
     */
    @log() getGroupDeprecated(id: any) {
        return this.http.get(`/groups/${id}`);
    }

    @log() getGroup(groupid: number, without_users?): Observable<Group> {
        const group$: Subject<Group> = new Subject();
        const params: any = {
            events: true,
            from: 'training',
            enabled: true,
            groupid
        };

        if (without_users) {
            params.without_users = true;
            params.with_users_count = true;
        }
        const timeStamp = Date.now();
        (<HTMLIFrameElement>document.getElementById('header-container')).contentWindow.postMessage(
            { getGroup: true, params, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                group$.next(event.data.group);
            }
        });
        return group$.asObservable();
    }

    @log() getGroups(filters: any): Observable<Group[]> {
        const params: any = {
            offset: filters.offset ? filters.offset : 0,
            limit: filters.limit ? filters.limit : 30,
            ...filters
        };

        return this.http.get<Group[]>(`/groups`, { params });
    }

    @log() getGroupsCount(filters: any): Observable<{ count: number }> {
        const params: any = {
            count: true,
            ...filters
        };

        return this.http.get<{ count: number }>(`/groups`, { params });
    }

    @log() getGroupCompletion(groupId: number, groupAssignmentId?): Observable<any> {
        const params: any = {};
        if (groupAssignmentId) {
            params.assignmentgroupid = groupAssignmentId;
        }
        return this.http.get(`/groups/${groupId}/completion`, { params });
    }

    @log() getGroupAssignments(groupId: number, filters?: any): Observable<any> {
        const params: any = {};
        if (filters) {
            params.referent = filters.referents
                .filter((referent) => referent.selected)
                .map((referent) => referent.key)
                .join('|');
            params.domain = filters.domains
                .filter((domain) => domain.selected)
                .map((domain) => domain.key)
                .join('|');
            params.tracking = filters.status
                .filter((tracking) => tracking.selected)
                .map((tracking) => tracking.key)
                .join('|');
            params.level = filters.categories
                .filter(
                    (level) =>
                        level.selected &&
                        [
                            'bloc',
                            'parcours',
                            'competence',
                            'module',
                            'sequence',
                            'activity'
                        ].indexOf(level.key) > -1
                )
                .map((level) => level.key)
                .join('|');
            params.category = filters.categories
                .filter(
                    (level) =>
                        level.selected &&
                        [
                            'bloc',
                            'parcours',
                            'competence',
                            'module',
                            'sequence',
                            'activity'
                        ].indexOf(level.key) === -1
                )
                .map((level) => level.key)
                .join('|');
            if (filters.search) {
                params.search = filters.search;
            }
            if (filters.withLog) {
                params.log = true;
            }
        }

        return this.http.get<{ referents: User[]; tree: Assignment[] }>(
            `/groups/${groupId}/assignments`,
            { params }
        );
    }

    @log() validateContentForGroup(groupId: number, contentId: number) {
        return this.http.post(`/groups/${groupId}/assignments/${contentId}/tracking`, {
            status: undefined,
            forcedStatus: 'completed'
        });
    }

    @log() invalidateContentForGroup(groupId: number, contentId: number) {
        return this.http.post(`/groups/${groupId}/assignments/${contentId}/tracking`, {
            status: undefined,
            forcedStatus: 'cancel'
        });
    }

    @log() deleteGroupAssignments(assignmentId: number) {
        return this.http.delete(`/assignments/${assignmentId}?group`);
    }

    @log() setDisponibilityToGroupAssignment(
        assignmentId: number,
        timestart: string,
        timeend: string,
        lock_after_enddate: boolean = false,
        enddate_reminders: boolean = false
    ) {
        const body: any = {
            timestart,
            timeend,
            lock_after_enddate,
            enddate_reminders
        };

        if (!timeend) {
            body.timeend = '';
        }

        return this.http.post(`/assignments/${assignmentId}/group/set_period`, body);
    }

    @log() markAsReferent(assignmentId: number) {
        return this.http.post(`/assignments/${assignmentId}/group/become_referent`, {});
    }

    @log() unmarkAsReferent(assignmentId: number) {
        return this.http.post(`/assignments/${assignmentId}/group/unbecome_referent`, {});
    }

    @log() markAssignmentAsAnonymous(assignmentId: number) {
        return this.http.post(`/assignments/${assignmentId}/group/anonymous`, {
            is_anonymous: true
        });
    }

    @log() hideGroupAssignment(assignmentId: number) {
        const params: any = { mask: true, group: true };

        return this.http.post(`/assignments/${assignmentId}`, {}, { params });
    }

    @log() showGroupAssignment(assignmentId: number) {
        const params: any = { mask: false, group: true };

        return this.http.post(`/assignments/${assignmentId}`, {}, { params });
    }

    @log() getGroupDevoirTracking(assignmentId: number) {
        return this.http.get(`/assignments/${assignmentId}/group/get_assignment_tracking`);
    }

    getGroupDevoirDownloadAllLink(assignmentId: number): string {
        return `${this.configService.getEndPoint()}/assignments/${assignmentId}/group/download_assigns`;
    }

    @log() saveGroupDevoirCorrection({
        assignment,
        groupId,
        correctionFile
    }: {
        assignment: Assignment;
        groupId: number;
        correctionFile: File;
    }): Observable<any> {
        const body = new FormData();
        body.append('correction', correctionFile, correctionFile.name);

        return this.http.post<any>(
            `/contents/${assignment.id}/submit_group_correction/${groupId}`,
            body
        );
    }

    @log() removeGroupTracking(groupId: number, contentId: number) {
        return this.http.post(`/groups/${groupId}/assignments/${contentId}/tracking`, {
            status: undefined,
            forcedStatus: 'not attempted'
        });
    }
}
