<div class="student-container" dndDropzone (dndDrop)="checkAssignContentToUser()">
    <div
        class="icon"
        [ngClass]="[getIcon(), getConnectionStatusIcon()]"
        [tooltip]="getConnectionStatusTooltip()"
    ></div>
    <div class="label">
        <div class="name">
            {{ getUserName() }}
            <div class="completion" *ngIf="user.completion !== undefined">
                {{ getUserCompletion() }}
            </div>
        </div>
    </div>
    <div class="icons">
        <div class="hover-icons">
            <div
                class="icon-easi-call-line"
                (click)="callUser()"
                *ngIf="canCallUser()"
                tooltip="Appeler l'utilisateur"
            ></div>
            <div
                class="icon-Messages"
                (click)="chatUser()"
                *ngIf="canChatUser()"
                tooltip="Envoyer un message à l'utilisateur"
            ></div>

            <div
                class="icon-FicheUser"
                *ngIf="!hasIncompleteEvents(user) && canOpenUserDialog(user)"
                [tooltip]="'Voir la fiche'"
                (click)="openUserDialog()"
            ></div>

            <div
                class="icon-contenusassignes"
                [tooltip]="'Voir les contenus assignés'"
                (click)="goToUserAssignment()"
            ></div>

            <div
                class="icon-Voirplus"
                tooltip="Voir toutes les actions"
                [more-actions]="this"
                [more-actions-origin]="'user'"
                [more-actions-class]="className"
                (click)="$event.stopImmediatePropagation()"
            ></div>
        </div>
        <div class="default-icons">
            <div
                class="icon-FicheUser with-warning"
                *ngIf="hasIncompleteEvents(user) && canOpenUserDialog(user)"
                (click)="openUserDialog()"
                tooltip="Activation du compte non finalisée : consulter la fiche pour plus d'information"
            ></div>
            <div class="icon-favoris" [tooltip]="'Utilisateur favori'" *ngIf="user.favorite"></div>
        </div>
    </div>
</div>
