import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ConfigService } from '../../services/config.service';

/**
 * Composant gérant l'affichage du bandeau easi-shared
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    constructor(
        private sanitizer: DomSanitizer,
        private configService: ConfigService,
    ) {}

    iframe_url: SafeResourceUrl;

    ngOnInit() {
        this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.configService.getEasiSharedEndPoint() + '/index.html?chatOnly=true'
        );
    }
}
