<div class="header">
    <div class="title">Modifier l'auteur du contenu</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>

<mat-dialog-content class="content">
    <ng-container *ngIf="!isLoading()">
        <div class="content-header">
            <div class="search-container">
                <input
                    class="library-search-field"
                    [(ngModel)]="search"
                    placeholder="Chercher..."
                    (keyup.enter)="getUsers()"
                />
                <span class="icon-search" (click)="getUsers()"></span>
                <span class="icon-Fermerdetails" *ngIf="search" (click)="cancelSearch()"></span>
            </div>
        </div>
        <div class="teacher" *ngFor="let user of users">
            <div class="icon" [ngClass]="getUserIcon(user)"></div>
            <div class="user-name">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</div>
            <div class="checkbox" (click)="markAsAuthor(user)">Définir comme auteur</div>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoading()">
        <app-loading></app-loading>
    </ng-container>
</mat-dialog-content>
