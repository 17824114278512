import { Pipe, PipeTransform } from '@angular/core';
import { Content } from '../structures/content';

@Pipe({
    name: 'creationSpaceSearch'
})
export class CreationSpaceSearchPipe implements PipeTransform {
    /**
     * @param { Array<Structure> } structures Un tableau contenant l'ensemble des structures sur lequel on souhaite filtrer
     * @param { string } filter Le filtre que l'on souhaite appliquer sur les structures
     * Un pipe permettant de filtrer les structures sur leur nom
     */
    transform(contents: Array<Content>, filter: string): Array<Content> {
        if (!filter) {
            return contents;
        }

        const res: Array<Content> = [];
        for (const content of contents) {
            if (
                content.title.toLowerCase().includes(filter.toLowerCase()) ||
                content.description.toLowerCase().includes(filter.toLowerCase()) ||
                content.keywords.findIndex(
                    (keyword) => keyword.toLowerCase() === filter.toLowerCase()
                ) !== -1
            ) {
                res.push(content);
            } else {
                if (this.transform(content.children, filter).length) {
                    res.push({
                        ...content,
                        children: this.transform(content.children, filter)
                    });
                }
            }
        }
        return res;
    }
}
