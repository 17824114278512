import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import * as FileSaver from 'file-saver';

import { AssignmentService } from '../../../services/assignment.service';
import { GroupService } from '../../../services/group.service';
import { LibraryService } from '../../../services/library.service';
import { LoadingService } from '../../../services/loading.service';
import { ReportingService } from '../../../services/reporting.service';
import { ConfigService } from '../../../services/config.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { MultiselectService } from '../../../services/library-multiselect.service';
import { DialogService } from '../../../services/dialog.service';

import { Group } from '../../../structures/group';
import { User } from '../../../structures/user';
import { Assignment } from '../../../structures/assignment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-group-assignments',
    templateUrl: './group-assignments.component.html',
    styleUrls: ['./group-assignments.component.scss']
})
export class GroupAssignmentsComponent implements OnInit, OnDestroy {
    getGroupAssignments$ = new Subscription();

    group: Group;
    referents: Array<User>;
    assignments: Array<Assignment>;
    assignmentsToDisplay: Array<Assignment>;

    pageIndex: number;

    filters: any = {
        domains: [],
        categories: [],
        referents: [],
        status: [],
        search: ''
    };

    subscriptions = new Subscription();

    constructor(
        private assignmentService: AssignmentService,
        private groupService: GroupService,
        private loadingService: LoadingService,
        private libraryService: LibraryService,
        private reportingService: ReportingService,
        private flashMessageService: FlashMessageService,
        private configService: ConfigService,
        private multiselectService: MultiselectService,
        private dialogService: DialogService,
        private location: Location,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.loadingService.startLoading('groupAssignments', 'getGroup');
        this.subscriptions.add(
            this.groupService
                .getGroup(+this.route.snapshot.paramMap.get('groupId'), true)
                .subscribe(
                    (data: Group) => {
                        this.loadingService.stopLoading('groupAssignments', 'getGroup');
                        this.group = data;
                        this.loadingService.startLoading('groupAssignments', 'getGroupCompletion');
                        this.subscriptions.add(
                            this.groupService
                                .getGroupCompletion(+this.route.snapshot.paramMap.get('groupId'))
                                .subscribe(
                                    (completion: any) => {
                                        this.loadingService.stopLoading(
                                            'groupAssignments',
                                            'getGroupCompletion'
                                        );
                                        this.group.completion = completion.completion;
                                    },
                                    (error: HttpErrorResponse) => {
                                        this.loadingService.stopLoading('groupAssignments');
                                    }
                                )
                        );
                        this.getGroupAssignments(true);
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('groupAssignments');
                    }
                )
        );
        this.assignmentService
            .getGroupFilter(+this.route.snapshot.paramMap.get('groupId'))
            .subscribe((data: any) => {
                this.filters.domains = data.domain
                    .map((item) => ({
                        title: item,
                        key: item
                    }))
                    .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));

                this.filters.categories = [
                    ...data.level.map((item) => ({
                        title: this.libraryService.getCategoryAndLevelLabelFromKey(item),
                        key: item,
                        icon: this.libraryService.getIcon({ level: item })
                    })),
                    {
                        separator: true
                    },
                    ...data.category.map((item) => ({
                        title: this.libraryService.getCategoryAndLevelLabelFromKey(item),
                        key: item,
                        icon: this.libraryService.getIcon({ category: item })
                    }))
                ];

                this.filters.status = data.tracking.map((status: string) => {
                    return {
                        key: status,
                        title: (() => {
                            switch (status) {
                                case 'not attempted':
                                    return 'Non commencé';
                                case 'failed':
                                    return 'Non réussi';
                                case 'passed':
                                    return 'Réussi';
                                case 'completed':
                                    return 'Terminé';
                                case 'opened':
                                    return 'En cours';
                            }
                        })()
                    };
                });

                this.filters.referents = data.referent.map((referent: any) => {
                    return {
                        title: referent.lastname.toUpperCase() + ' ' + referent.firstname,
                        key: referent.id,
                        icon: 'icon-formateur-referent'
                    };
                });
            });
    }

    ngOnDestroy() {
        this.multiselectService.clearAssignmentSelection();
    }

    showResetFilters() {
        if (
            this.filters.domains.filter((domain) => domain.selected === true).length ||
            this.filters.categories.filter((category) => category.selected === true).length ||
            this.filters.referents.filter((referent) => referent.selected === true).length ||
            this.filters.status.filter((status) => status.selected === true).length ||
            this.filters.search !== ''
        ) {
            return true;
        }
        return false;
    }

    resetFilters() {
        this.filters.domains.map((domain) => (domain.selected = false));
        this.filters.categories.map((category) => (category.selected = false));
        this.filters.referents.map((referent) => (referent.selected = false));
        this.filters.status.map((status) => (status.selected = false));
        this.filters.search = '';
        this.getGroupAssignments();
    }

    getGroupAssignments(withLog?: boolean) {
        if (this.getGroupAssignments$) {
            this.getGroupAssignments$.unsubscribe();
            this.loadingService.stopLoading('groupAssignments', 'getGroupAssignments');
        }
        this.loadingService.startLoading('groupAssignments', 'getGroupAssignments');
        const filters = {
            ...this.filters,
            withLog: withLog
        };
        this.getGroupAssignments$ = this.groupService
            .getGroupAssignments(+this.route.snapshot.paramMap.get('groupId'), filters)
            .subscribe(
                (assignments: any) => {
                    this.assignmentsToDisplay = [];
                    this.pageIndex = 0;
                    this.referents = assignments.referents;
                    this.assignments = assignments.tree;
                    this.assignments.map((assignment: Assignment, index: number) => {
                        if (index < 30) {
                            this.assignmentsToDisplay.push(assignment);
                        }
                    });
                    this.loadingService.stopLoading('groupAssignments', 'getGroupAssignments');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('groupAssignments');
                }
            );
        this.subscriptions.add(this.getGroupAssignments$);
    }

    getGroupReporting($event) {
        $event.stopImmediatePropagation();

        if (
            this.group.type === 'role' &&
            this.group.users_count > this.configService.getConfig().maxUserRolegroupDownloadReport
        ) {
            this.dialogService
                .openWarning(
                    `Compte tenu du nombre importants d'utilisateurs dans ce groupe, un rapport simplifié sera mis à votre disposition. Ce rapport contiendra uniquement la progression des assignations à la racine.`
                )
                .subscribe(() => {
                    this.reportingService
                        .getCSVReporting({ groupId: this.group.id })
                        .subscribe((csvText: string) => {
                            const date =
                                (new Date().getDate() < 10
                                    ? '0' + new Date().getDate()
                                    : new Date().getDate()) +
                                '-' +
                                (new Date().getMonth() + 1 < 10
                                    ? '0' + (new Date().getMonth() + 1)
                                    : new Date().getMonth() + 1) +
                                '-' +
                                new Date().getFullYear();
                            const csvName = 'reporting_' + this.group.name + '_' + date + '.csv';
                            const blob = new Blob(['\ufeff', csvText], {
                                type: 'text/plain;charset=iso-8859-1;'
                            });
                            FileSaver.saveAs(blob, csvName);

                            this.flashMessageService.flash("L'export CSV est terminé");
                        });
                });
        } else {
            this.dialogService.openSelectGroupReportingDialog().subscribe((data) => {
                if (data === 'individual') {
                    this.reportingService
                        .getCSVReporting({ groupId: this.group.id, type: 'users' })
                        .subscribe((csvText: string) => {
                            const date =
                                (new Date().getDate() < 10
                                    ? '0' + new Date().getDate()
                                    : new Date().getDate()) +
                                '-' +
                                (new Date().getMonth() + 1 < 10
                                    ? '0' + (new Date().getMonth() + 1)
                                    : new Date().getMonth() + 1) +
                                '-' +
                                new Date().getFullYear();
                            const csvName = 'reporting_' + this.group.name + '_' + date + '.csv';
                            const blob = new Blob(['\ufeff', csvText], {
                                type: 'text/plain;charset=iso-8859-1;'
                            });
                            FileSaver.saveAs(blob, csvName);

                            this.flashMessageService.flash("L'export CSV est terminé");
                        });
                } else if (data === 'group') {
                    this.reportingService
                        .getCSVReporting({ groupId: this.group.id })
                        .subscribe((csvText: string) => {
                            const date =
                                (new Date().getDate() < 10
                                    ? '0' + new Date().getDate()
                                    : new Date().getDate()) +
                                '-' +
                                (new Date().getMonth() + 1 < 10
                                    ? '0' + (new Date().getMonth() + 1)
                                    : new Date().getMonth() + 1) +
                                '-' +
                                new Date().getFullYear();
                            const csvName = 'reporting_' + this.group.name + '_' + date + '.csv';
                            const blob = new Blob(['\ufeff', csvText], {
                                type: 'text/plain;charset=iso-8859-1;'
                            });
                            FileSaver.saveAs(blob, csvName);

                            this.flashMessageService.flash("L'export CSV est terminé");
                        });
                }
            });
        }
    }

    goToStudentPanel() {
        this.location.back();
        // this.router.navigate(['/teacher/panel'], { queryParamsHandling: 'merge' });
    }

    openGroupTestReporting($event) {
        $event.stopImmediatePropagation();
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?groupId=' +
                this.group.id +
                '&select=all',
            '_blank'
        );
    }

    getSortedAssignments() {
        return this.assignmentsToDisplay.sort((a: Assignment, b: Assignment) => {
            if (a.position < b.position) {
                return -1;
            }
            if (a.position > b.position) {
                return 1;
            }
            return 0;
        });
    }

    getPreviousAssignment() {
        if (this.pageIndex >= 2) {
            const assignmentsToAdd = this.assignments.slice(
                (this.pageIndex - 2) * 30,
                (this.pageIndex - 1) * 30
            );
            // Remove the 30 previous assignments
            const tata = this.assignmentsToDisplay.splice(30, 30);

            this.assignmentsToDisplay = assignmentsToAdd.concat(this.assignmentsToDisplay);
            this.pageIndex--;
        }
    }

    getNextAssignment() {
        const assignmentsToAdd = this.assignments.slice(
            (this.pageIndex + 1) * 30,
            (this.pageIndex + 2) * 30
        );
        if (assignmentsToAdd.length > 0) {
            // Remove the 30 previous assignments
            if (this.pageIndex >= 1) {
                this.assignmentsToDisplay.splice(0, 30);
            }

            this.assignmentsToDisplay = this.assignmentsToDisplay.concat(assignmentsToAdd);
            this.pageIndex++;
        }
    }

    addToAssignments($event: Assignment) {
        if (!$event.parents) {
            // assignation à la racine
            this.assignmentsToDisplay.push($event);
            this.assignments.push($event);
        } else {
            // assignationdans un parent qui est à la racine (#257978)
            const parent = this.assignments.find((asst) => asst.assignmentId === $event.parents);
            if (!parent) {
                this.assignmentsToDisplay.push($event);
                this.assignments.push($event);
            }
            parent.children.push($event);
            parent.children.sort((a, b) => b.position - a.position);
        }
    }

    moveAssignment($event: Assignment) {
        this.assignments.map((assignment: Assignment) => {
            if ($event.assignmentId === assignment.assignmentId) {
                assignment.position = $event.position;
            }
            return assignment;
        });
        this.assignmentsToDisplay.map((assignment: Assignment) => {
            if ($event.assignmentId === assignment.assignmentId) {
                assignment.position = $event.position;
            }
            return assignment;
        });
    }

    removeAssignment(assignment: Assignment) {
        for (const i in this.assignments) {
            if (this.assignments[i].assignmentId === assignment.assignmentId) {
                this.assignments.splice(+i, 1);
            }
        }
        for (const i in this.assignmentsToDisplay) {
            if (this.assignmentsToDisplay[i].assignmentId === assignment.assignmentId) {
                this.assignmentsToDisplay.splice(+i, 1);
            }
        }
    }

    getGroupName() {
        if (this.group) {
            return this.group.name;
        }
    }

    getGroupCompletion() {
        if (this.group && this.group.completion) {
            return `(${this.group.completion}%)`;
        }
    }

    cancelSearch() {
        this.filters.search = '';
    }

    openGroupReporting() {
        window.open(
            this.configService.getReportFrontEndpoint() + '#/?groupId=' + this.group.id,
            '_blank'
        );
    }

    isLoading() {
        return this.loadingService.isLoading('groupAssignments');
    }

    isSelectionEmpty() {
        return this.multiselectService.getSelectedAssignment().length === 0;
    }

    addDisponibility(): void {
        this.subscriptions.add(this.multiselectService.addDisponibility());
    }
}
