<div class="header">
    <div class="title">{{ isCopy() ? 'Copier le contenu' : 'Modifier le contenu' }}</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<div class="content" *ngIf="isCopy()">
    Le contenu sélectionné va être copié dans votre espace de création avec le préfixe "Copie
    de".<br />
    Vous pouvez éditer ce contenu et modifier sa fiche puis publier en tant que nouveau contenu dans
    easi training.<br />
    <br />
    Dans le cadre de cette copie, n'oubliez pas de personnaliser le titre avant publication en tant
    que nouveau contenu.
</div>

<div class="content" *ngIf="!isCopy()">
    Le contenu sélectionné va entrer en modification dans votre espace de création. Vous pouvez
    éditer ce contenu et modifier sa fiche puis publier une mise à jour depuis easi training.<br />
    <br />
    N.B : Seuls les administrateurs et l'auteur d'un contenu peuvent faire des mises à jour.
</div>
<div class="footer">
    <div class="button blue" (click)="goTo()">Ok</div>
</div>
