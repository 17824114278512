<div class="validation-space" *ngIf="!isLoading()">
    <mat-expansion-panel
        class="sent-to-validation-container"
        *ngIf="showSendToValidation()"
        [expanded]="true"
    >
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
            >Contenus envoyés en validation ({{
                getSentToValidationCount()
            }})</mat-expansion-panel-header
        >
        <app-sent-to-validation
            *ngFor="let content of sentToValidation"
            [content]="content"
            (contentMovedToDraft)="moveContentToDraft($event)"
        ></app-sent-to-validation>
    </mat-expansion-panel>
    <mat-expansion-panel
        class="waiting-for-approval-container"
        *ngIf="showWaitingApproval()"
        [expanded]="true"
    >
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
            >Contenus en attente de ma validation ({{
                getWaitingForApprovalCount()
            }})</mat-expansion-panel-header
        >
        <app-waiting-for-approval
            *ngFor="let content of waitingForApproval"
            [content]="content"
            (contentRemoved)="removeWaitingForApproval($event)"
            (contentModified)="dispatchModifiedContent($event)"
        ></app-waiting-for-approval>
    </mat-expansion-panel>
    <mat-expansion-panel
        class="waiting-for-approval-container"
        *ngIf="showAdminWaitingForApproval()"
        [expanded]="true"
    >
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
            >Contenus en attente de validation ({{
                getAdminWaitingForApprovalCount()
            }})</mat-expansion-panel-header
        >
        <app-waiting-for-approval
            *ngFor="let content of adminWaitingForApproval"
            [content]="content"
            (contentRemoved)="removeWaitingForApproval($event)"
            (contentModified)="dispatchModifiedContent($event)"
        ></app-waiting-for-approval>
    </mat-expansion-panel>
</div>
<div class="validation-space" *ngIf="isLoading()">
    <app-loading></app-loading>
</div>
