import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

/**
 * Composant gérant l'affichage de la fenêtre
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-copy-content',
    templateUrl: './copy-content.component.html',
    styleUrls: ['./copy-content.component.scss']
})
export class CopyContentComponent {
    constructor(
        public dialogRef: MatDialogRef<CopyContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    goTo() {
        this.dialogRef.close('OK');
    }

    isCopy() {
        return this.data.isCopy;
    }

    /**
     * Ferme la fenêtre
     */
    closeDialog() {
        this.dialogRef.close(false);
    }
}
