import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingComponent } from '../components/loading/loading.component';
import { AssigningComponent } from '../components/assigning/assigning.component';
import { FlashMessageComponent } from '../components/flash-message/flash-message.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

@NgModule({
    declarations: [FlashMessageComponent, LoadingComponent, AssigningComponent],
    imports: [CommonModule, LoadingBarHttpClientModule],
    exports: [
        FlashMessageComponent,
        LoadingBarHttpClientModule,
        LoadingComponent,
        AssigningComponent
    ]
})
export class LoadingModule {}
