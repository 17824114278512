import { Component, Input, OnInit } from '@angular/core';

import { LibraryService } from '../../../services/library.service';

import { Content } from '../../../structures/content';

@Component({
    selector: 'app-assembly-confirmation-entry',
    templateUrl: './assembly-confirmation-entry.component.html',
    styleUrls: ['./assembly-confirmation-entry.component.scss']
})
export class AssemblyConfirmationEntryComponent implements OnInit {
    @Input() content: Content;
    @Input() mode: string;

    opened: boolean;

    constructor(private libraryService: LibraryService) {}

    ngOnInit() {
        this.opened = true;
    }

    toggleIcon() {
        this.opened = !this.opened;
    }

    getIcon(): string {
        if (this.content.status === 'approved') {
            return this.libraryService.getIcon(this.content);
        } else {
            return this.libraryService.getDraftIcon(this.content);
        }
    }

    getContentClass(): string {
        if (this.content.status !== 'approved') {
            switch (this.mode) {
                case 'delete': {
                    return 'red';
                }
                case 'publish': {
                    return 'green';
                }
                case 'copy': {
                    return 'blue';
                }
            }
        }
    }

    isAssembly(): boolean {
        return !!this.content.level;
    }
}
