<div class="header">
    <div class="icon-flag"></div>
    <div class="title">Détails du signalement</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content">
    <div class="entry-container">
        <div class="entry">
            <div class="entry-icon" [ngClass]="getIcon()"></div>
            <div class="entry-title">{{ getTitle() }}</div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="main-container">
        <div class="report-status {{ content.report.status }}">
            {{ getReportStatusDescription() }}
        </div>
        <ng-container *ngIf="content.report.answer">
            <div class="legend answer">Mise à jour du {{ getAnswerDate() }}</div>
            <div class="message-container">
                <span class="message" [innerHTML]="getAnswerMessageAsHTML()"></span>
            </div>
        </ng-container>
        <div class="legend">
            Signalement du {{ getReportDate() }} par
            {{ content.report.author.lastname.toUpperCase() }} {{ content.report.author.firstname }}
        </div>
        <div class="message-container">
            <span class="message" [innerHTML]="getReportMessageAsHTML()"></span>
            <a
                [href]="content.report.fileURL"
                class="file-link-simple"
                target="_blank"
                *ngIf="content.report.fileURL && !nationalAdminMode"
            >
                <span class="icon-trombonne" tooltip="Télécharger la pièce jointe"></span>
            </a>
        </div>
        <textarea
            [(ngModel)]="comment"
            *ngIf="canEditReport()"
            placeholder="Entrez un commentaire qui sera envoyé par mail à l'auteur du signalement..."
        ></textarea>
        <div class="button" *ngIf="content.report.fileURL && nationalAdminMode">
            <div class="icon-trombonne"></div>
            <a [href]="content.report.fileURL" target="_blank">
                <div class="file-name">
                    Télécharger la pièce jointe : {{ content.report.fileURL }}
                </div>
            </a>
        </div>
    </div>
</mat-dialog-content>
<div class="footer national-admin-mode" *ngIf="canEditReport()">
    <div class="button" (click)="closeReport()">Indiquer ce signalement comme corrigé</div>
    <div class="button" (click)="cancelReport()" [ngClass]="{ disabled: !comment }">
        Indiquer ce signalement comme non-corrigé (commentaire obligatoire)
    </div>
</div>

<div class="footer" *ngIf="!canEditReport()">
    <div class="button" (click)="closeDialog()">OK</div>
</div>
