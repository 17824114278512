<div class="header">
    <div class="title" [innerHTML]="getTitle()"></div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content">
    <div class="legend" [ngClass]="getLegendClass()">
        <div class="blue" *ngIf="mode === 'copy'">
            Seuls les contenus affichés en <b><i>bleu</i></b> seront copiés à la racine de votre
            espace de création.<br />
            Les autres contenus seront instanciés (reprise du contenu de la bibliothèque).
        </div>
        <div class="green" *ngIf="mode === 'publish'">
            Seuls les contenus affichés en <b><i>vert</i></b> seront publiés dans la bibliothèque de
            votre structure.<br />
            Les autres contenus sont déja publiés.
        </div>
        <div class="red" *ngIf="mode === 'delete'">
            Les contenus affichés en <b><i>rouge</i></b> seront
            <i>supprimés définitivement</i>.<br />
            Les autres contenus seront supprimés de votre espace de création mais existeront
            toujours dans la bibliothèque.
        </div>
    </div>
    <div class="count" [ngClass]="getLegendClass()">
        {{ getCount() }} :
        <i>
            <b *ngIf="activeContentCount.bloc > 0">{{ activeContentCount.bloc }} Bloc(s)</b>
            <b *ngIf="activeContentCount.parcours > 0"
                >{{ activeContentCount.parcours }} Parcours</b
            >
            <b *ngIf="activeContentCount.competence > 0"
                >{{ activeContentCount.competence }} Compétence(s)</b
            >
            <b *ngIf="activeContentCount.module > 0">{{ activeContentCount.module }} Module(s)</b>
            <b *ngIf="activeContentCount.sequence > 0"
                >{{ activeContentCount.sequence }} Séquence(s)</b
            >
        </i>
    </div>
    <div class="content-container">
        <app-assembly-confirmation-entry
            [content]="content"
            [mode]="mode"
        ></app-assembly-confirmation-entry>
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="confirm button" (click)="action()">{{ getButton() }}</div>
    <div class="cancel button" (click)="closeDialog()">Annuler</div>
</div>
