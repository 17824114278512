<div id="activity-rating">
    <div class="activity-rating_header">
        <span class="icon-thumbup"></span>
        <span class="title">Noter l'activité</span>
        <span class="icon-close" (click)="closeDialog()"></span>
    </div>
    <div class="activity-rating_main">
        <div class="activity-card">
            <div [ngClass]="getIcon()"></div>
            {{ data.activity.title }}
        </div>
        <div class="my-rating">
            <div class="my-rating_title">Votre note</div>
            <div class="my-rating_thumbs-container" [ngClass]="{'noted' : note !== undefined, 'modified': activityHasBeenUpdated}">
                <div class="icon-thumbup{{ note && note > 0 ? '-plein' : '' }}" (click)="setNoteTo(1)"></div>
                <div class="icon-thumbup{{ note && note > 1 ? '-plein' : '' }}" (click)="setNoteTo(2)"></div>
                <div class="icon-thumbup{{ note && note > 2 ? '-plein' : '' }}" (click)="setNoteTo(3)"></div>
                <div class="icon-thumbup{{ note && note > 3 ? '-plein' : '' }}" (click)="setNoteTo(4)"></div>
                <div class="icon-thumbup{{ note && note > 4 ? '-plein' : '' }}" (click)="setNoteTo(5)"></div>
            </div>
            <div class="my-rating_message" *ngIf="activityHasBeenUpdated">Le contenu a été mis à jour, vous pouvez modifier votre note si vous le souhaitez.</div>
        </div>
        <div class="average-rating" *ngIf="data.activity.note_count > 0">
            <div class="average-rating_counters"><strong>{{ data.activity.note_count }}</strong> note{{ data.activity.note_count > 1 ? 's' : '' }} : moyenne <strong>{{ data.activity.note_avg }}</strong>/5</div>
            <div class="average-rating_thumbs-container">
                <div class="icon-thumbup{{ fillTheAverageNoteClassName(1) }}"></div>
                <div class="icon-thumbup{{ fillTheAverageNoteClassName(2) }}"></div>
                <div class="icon-thumbup{{ fillTheAverageNoteClassName(3) }}"></div>
                <div class="icon-thumbup{{ fillTheAverageNoteClassName(4) }}"></div>
                <div class="icon-thumbup{{ fillTheAverageNoteClassName(5) }}"></div>
            </div>
        </div>
    </div>
    <div class="activity-rating_footer">
        <div
            class="rate-button"
            [ngClass]="{ disabled: !canRate() }"
            (click)="rate()"
            >
            {{ getValidationButtonText() }}
        </div>
    </div>
</div>