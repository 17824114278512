import { Component, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { AdminService } from '../../services/admin.service';
import { LoadingService } from '../../services/loading.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-create-provider',
    templateUrl: './create-provider.component.html',
    styleUrls: ['./create-provider.component.scss']
})
export class CreateProviderComponent {
    private subscriptions$: Subscription = new Subscription();

    provider: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<CreateProviderComponent>,
        private adminService: AdminService,
        private loadingService: LoadingService
    ) {}

    getTitle() {
        return 'Créer un fournisseur';
    }

    getFooter() {
        return 'Créer';
    }

    isLoading() {
        return this.loadingService.isLoading('createProvider');
    }

    action() {
        this.loadingService.startLoading('createProvider');
        this.subscriptions$.add(
            this.adminService.createProvider(this.provider).subscribe(
                () => {
                    this.loadingService.stopLoading('createProvider');
                    this.dialogRef.close(this.provider);
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('createProvider');
                }
            )
        );
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
