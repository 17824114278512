import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DndModule } from 'ngx-drag-drop';
import { DropdownModule } from 'libs/uimm-dropdown/src/public-api';
import { TooltipModule } from 'libs/uimm-tooltip/src/public-api';

import { LoadingModule } from './loading.module';
import { SharedModule } from './shared.module';

import { GroupAssignmentsComponent } from '../components/teacher/group-assignments/group-assignments.component';
import { UserAssignmentsComponent } from '../components/teacher/user-assignments/user-assignments.component';
import { GroupAssignmentsEntryComponent } from '../components/teacher/group-assignments/group-assignments-entry/group-assignments-entry.component';
import { UserAssignmentsEntryComponent } from '../components/teacher/user-assignments/user-assignments-entry/user-assignments-entry.component';

import { AdvancedAssignationComponent } from '../dialogs/advanced-assignation/advanced-assignation.component';
import { SelectFIFCComponent } from '../dialogs/select-fi-fc/select-fi-fc.component';
import { AddDisponibilityComponent } from '../dialogs/add-disponibility/add-disponibility.component';
import { PersonnalizeGroupAssignmentComponent } from '../dialogs/personnalize-group-assignment/personnalize-group-assignment.component';
import { MinorUpdateChangeComponent } from '../dialogs/minor-update-change/minor-update-change.component';
import { DevoirConfirmationComponent } from '../dialogs/devoir-confirmation/devoir-confirmation.component';
import { UserTrackingComponent } from '../dialogs/user-tracking/user-tracking.component';
import { GroupTrackingComponent } from '../dialogs/group-tracking/group-tracking.component';
import { UploadDevoirCorrectionComponent } from '../dialogs/upload-devoir-correction/upload-devoir-correction.component';
import { RevealAnonymousDevoirConfirmationComponent } from '../dialogs/reveal-anonymous-devoir-confirmation/reveal-anonymous-devoir-confirmation.component';
import { SelectGroupReporting } from '../dialogs/select-group-reporting/select-group-reporting.component';

@NgModule({
    declarations: [
        UserAssignmentsComponent,
        GroupAssignmentsComponent,
        GroupAssignmentsEntryComponent,
        UserAssignmentsEntryComponent,
        AdvancedAssignationComponent,
        SelectFIFCComponent,
        AddDisponibilityComponent,
        PersonnalizeGroupAssignmentComponent,
        MinorUpdateChangeComponent,
        UserTrackingComponent,
        GroupTrackingComponent,
        UploadDevoirCorrectionComponent,
        RevealAnonymousDevoirConfirmationComponent,
        DevoirConfirmationComponent,
        SelectGroupReporting
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatExpansionModule,
        MatDialogModule,
        MatDatepickerModule,
        InfiniteScrollModule,
        DndModule,
        TooltipModule,
        DropdownModule,
        LoadingModule,
        SharedModule
    ],
    exports: [
        UserAssignmentsComponent,
        GroupAssignmentsComponent,
        GroupAssignmentsEntryComponent,
        UserAssignmentsEntryComponent,
        AdvancedAssignationComponent,
        SelectFIFCComponent,
        AddDisponibilityComponent,
        PersonnalizeGroupAssignmentComponent,
        MinorUpdateChangeComponent,
        UserTrackingComponent,
        UploadDevoirCorrectionComponent,
        RevealAnonymousDevoirConfirmationComponent,
        DevoirConfirmationComponent,
        SelectGroupReporting
    ]
})
export class AssignmentModule {}
