import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    ElementRef,
    ViewChild,
    Renderer2
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../../services/dialog.service';
import { LibraryService } from '../../../../services/library.service';
import { LoginService } from '../../../../services/login.service';
import { MultiselectService } from '../../../../services/library-multiselect.service';
import { PanelService } from '../../../../services/panel.service';

import { Structure } from '../../../../structures/structure';
import { Filter } from '../../../../structures/filter';
import {
    levelsArray,
    librariesArray,
    catalogArray,
    ratesArray,
    typesArray,
    reportStatusList
} from './arrays-for-filters-dropdown';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReportStatus } from 'src/app/structures/content';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-library-header',
    templateUrl: './library-header.component.html',
    styleUrls: ['./library-header.component.scss']
})
export class LibraryHeaderComponent implements OnInit {
    @ViewChild('libraryDatesFilter') libraryDatesfilterRef: ElementRef;
    @ViewChild('libraryDatesFilterDropdownContent')
    libraryDatesfilterDropdownContentRef: ElementRef;
    @Input() filters: Filter;
    @Output() filtersChange: EventEmitter<any> = new EventEmitter();
    @Input() isCatalogView: boolean;
    @Input() isFavoritesView: boolean;
    @Input() isArchiveView: boolean;
    @Input() isReportView: boolean;
    @Input() isDisabledView: boolean;
    @Input() isPreviousVersionView: boolean;
    @Output() viewChange: EventEmitter<any> = new EventEmitter();
    @Output() isPreviousVersionViewChange: EventEmitter<boolean> = new EventEmitter();
    @Output() downloadReportedContents: EventEmitter<undefined> = new EventEmitter();
    @Output() downloadCatalogContents: EventEmitter<undefined> = new EventEmitter();

    @Input() counts: {
        parcours: number;
        bloc: number;
        competence: number;
        module: number;
        sequence: number;
        activity: number;
    };

    @Input() showCancelFilters: boolean;

    creationDateStart: Date;
    creationDateEnd: Date;
    modificationDateStart: Date;
    modificationDateEnd: Date;
    reportDateStart: Date;
    reportDateEnd: Date;
    datesFilterOpened = false;

    className = 'library-header';

    private subscriptions = new Subscription();

    localStructure: Array<Structure>;
    showStructuresDropdown: boolean;
    showLibraryDropdown: boolean;

    domain: Array<any> = [];
    level: Array<any> = [];
    previousCategory: Array<any> = [];
    category: Array<any> = [];
    type: Array<any> = [];
    library: Array<any> = [];
    catalog: Array<any> = [];
    authors: Array<any> = [];
    archivedAuthors: Array<any> = [];
    search: string;
    rating: any = [];
    reportStatusList: any[];
    myReportsOnly = false;

    reduced = false;

    constructor(
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private loginService: LoginService,
        private multiselectService: MultiselectService,
        private panelService: PanelService,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.showStructuresDropdown = false;
        this.showLibraryDropdown = !(
            this.loginService.getUser().roles.siteTeacher ||
            this.loginService.getUser().roles.corporationTeacher
        );
        this.level = levelsArray;
        this.type = typesArray;
        this.rating = ratesArray;
        this.library = librariesArray;
        this.catalog = catalogArray;
        this.reportStatusList = reportStatusList;

        this.subscriptions.add(
            this.libraryService.getDomains().subscribe((data: any) => {
                this.domain = data
                    .map((item) => ({
                        title: item.name,
                        key: item.name
                    }))
                    .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
            })
        );

        this.subscriptions.add(
            this.libraryService.getCategories().subscribe((data: any) => {
                this.category = [
                    ...this.level,
                    {
                        separator: true
                    },
                    ...data.map((item) => ({
                        title: item,
                        key: item,
                        icon: this.libraryService.getIcon({ category: item })
                    }))
                ];
                this.category = this.category.map((item) => ({
                    ...item,
                    key: item.title,
                    title: this.libraryService.getCategoryAndLevelLabelFromKey(item.title)
                }));
                this.previousCategory = JSON.parse(JSON.stringify(this.category));
            })
        );

        this.subscriptions.add(
            this.loginService.getStructures().subscribe((data: any) => {
                this.localStructure = data.map((item) => ({
                    title: item.name,
                    key: item.id
                }));
            })
        );

        this.subscriptions.add(
            this.libraryService.getAuthors().subscribe((data: any) => {
                this.authors = data.map((item) => ({
                    title: item.lastname.toUpperCase() + ' ' + item.firstname,
                    key: item.id
                }));
            })
        );

        this.subscriptions.add(
            this.libraryService.getArchivedAuthors().subscribe((data: any) => {
                this.archivedAuthors = data.map((item) => ({
                    title: item.lastname.toUpperCase() + ' ' + item.firstname,
                    key: item.id
                }));
            })
        );

        this.libraryService.viewInLibrary.subscribe((data: any) => {
            this.search = data;
            this.toggleLibraryView();
            this.updateFilters('search');
        });

        this.libraryService.setNationalCatalog.subscribe(() => {
            this.catalog[0].selected = false;
            this.catalog[1].selected = true;
            this.updateFilters('catalog');
        });

        if (
            !this.loginService.getUser().roles.nationalAdmin &&
            !this.loginService.getUser().roles.nationalTeacher
        ) {
            this.catalog[0].selected = true;
            this.updateFilters('catalog');
        }
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isLocalAdmin() {
        return this.loginService.getUser().roles.localAdmin;
    }

    getTitle() {
        if (this.isCatalogView) {
            return 'Catalogue';
        } else if (this.isFavoritesView) {
            return 'Contenus favoris';
        } else if (this.isArchiveView) {
            return 'Contenus archivés';
        } else if (this.isReportView) {
            return 'Contenus signalés';
        } else if (this.isDisabledView) {
            return 'Contenus désactivés';
        } else if (this.isPreviousVersionView) {
            return 'Versions précédentes';
        } else {
            return 'Bibliothèque';
        }
    }

    getDownloadIconTooltip() {
        if (this.isCatalogView) {
            return 'Télécharger le catalogue de la bibliothèque';
        } else if (this.isReportView) {
            return 'Télécharger la liste des contenus signalés';
        } else {
            return 'Télécharger la liste des contenus des bibliothèques';
        }
    }

    getHeaderIcon() {
        if (this.isCatalogView) {
            return 'icon-Applications';
        } else if (this.isFavoritesView) {
            return 'icon-contenusfavoris';
        } else if (this.isArchiveView) {
            return 'icon-archiver';
        } else if (this.isReportView) {
            return 'icon-flag';
        } else if (this.isDisabledView) {
            return 'icon-DesactiverContenu';
        } else if (this.isPreviousVersionView) {
            return 'icon-Nouvelleversioncontenu';
        } else {
            return 'icon-contenusbibliotheque';
        }
    }

    showDownloadButton() {
        return (
            (this.isLibraryView() && !this.loginService.getUser().roles.corporationTeacher) ||
            this.isReportView ||
            this.isCatalogView
        );
    }

    handleDownLoadButtonClick(): void {
        if (this.isLibraryView()) {
            this.dialogService.openExportCatalog();
        } else if (this.isCatalogView) {
            this.downloadCatalogContents.emit();
        } else if (this.isReportView) {
            this.downloadReportedContents.emit();
        }
    }

    hasSeenCatalog() {
        return this.loginService.getUser().ed_has_seen_catalog;
    }

    toggleLibrary() {
        this.reduced = !this.reduced;
        this.panelService.toggleRightPanel();
    }

    /**
     * Updates the this.filters array when selecting an filter in a dropdown
     * @param filter the name of the filter changed, given by the dropdownComponent emitting the event
     */
    updateFilters(filter: string) {
        switch (filter) {
            case 'domain': {
                this.filters.domain = this.domain
                    .filter((item) => item.selected)
                    .map((item: any) => {
                        return item.key;
                    });
                break;
            }
            case 'category': {
                let isLevelFilter = false;
                for (const i in this.previousCategory) {
                    if (
                        [
                            'parcours',
                            'bloc',
                            'competence',
                            'module',
                            'sequence',
                            'activity'
                        ].indexOf(this.previousCategory[i].key) > -1 &&
                        this.previousCategory[i].selected !== this.category[i].selected
                    ) {
                        isLevelFilter = true;
                    }
                }
                for (const i in this.category) {
                    if (isLevelFilter) {
                        if (
                            [
                                'parcours',
                                'bloc',
                                'competence',
                                'module',
                                'sequence',
                                'activity'
                            ].indexOf(this.category[i].key) === -1
                        ) {
                            this.category[i].selected = false;
                        }
                    } else {
                        if (
                            [
                                'parcours',
                                'bloc',
                                'competence',
                                'module',
                                'sequence',
                                'activity'
                            ].indexOf(this.category[i].key) > -1
                        ) {
                            this.category[i].selected = false;
                        }
                    }
                }
                this.previousCategory = JSON.parse(JSON.stringify(this.category));
                this.filters.level = this.category
                    .filter(
                        (item) =>
                            item.selected &&
                            [
                                'parcours',
                                'bloc',
                                'competence',
                                'module',
                                'sequence',
                                'activity'
                            ].indexOf(item.key) > -1
                    )
                    .map((item: any) => {
                        return item.key;
                    });
                this.filters.category = this.category
                    .filter(
                        (item) =>
                            item.selected &&
                            [
                                'parcours',
                                'bloc',
                                'competence',
                                'module',
                                'sequence',
                                'activity'
                            ].indexOf(item.key) === -1
                    )
                    .map((item: any) => {
                        return item.key;
                    });
                break;
            }
            case 'type': {
                this.filters.type = this.type
                    .filter((item) => item.selected)
                    .map((item: any) => {
                        return item.key;
                    });
                break;
            }
            case 'creationDateStart': {
                this.filters.timecreatedstart = this.creationDateStart.toISOString();
                break;
            }
            case 'creationDateEnd': {
                const tmp = this.creationDateEnd;
                tmp.setHours(23);
                tmp.setMinutes(59);
                tmp.setSeconds(59);
                this.filters.timecreatedend = tmp.toISOString();
                break;
            }
            case 'modificationDateStart': {
                this.filters.timemodifiedstart = this.modificationDateStart.toISOString();
                break;
            }
            case 'modificationDateEnd': {
                const tmp = this.modificationDateEnd;
                tmp.setHours(23);
                tmp.setMinutes(59);
                tmp.setSeconds(59);
                this.filters.timemodifiedend = tmp.toISOString();
                break;
            }
            case 'reportDateStart': {
                this.filters.timereportedstart = this.reportDateStart.toISOString();
                break;
            }
            case 'reportDateEnd': {
                const tmp = this.reportDateEnd;
                tmp.setHours(23);
                tmp.setMinutes(59);
                tmp.setSeconds(59);
                this.filters.timereportedend = tmp.toISOString();
                break;
            }
            case 'library': {
                this.showStructuresDropdown = false;
                this.filters.localStructure = '';
                this.library
                    .filter((item) => item.selected === true)
                    .map((item) => {
                        this.filters.localStructure = '|';
                        if (
                            !this.loginService.getUser().roles.nationalAdmin &&
                            !this.loginService.getUser().roles.nationalTeacher
                        ) {
                            this.filters.localStructure += this.loginService.getUser().localStructure;
                        }
                        if (item.key === 'national') {
                            this.showStructuresDropdown = false;
                            this.filters.localStructure = '|';
                        } else if (item.key === 'local') {
                            if (
                                this.loginService.getUser().roles.nationalAdmin ||
                                this.loginService.getUser().roles.nationalTeacher
                            ) {
                                this.showStructuresDropdown = true;
                                const currentSelectedStructure =
                                    this.localStructure &&
                                    this.localStructure.some((structure) => structure.selected)
                                        ? this.localStructure.find(
                                              (structure) => structure.selected
                                          )
                                        : undefined;
                                if (currentSelectedStructure) {
                                    this.filters.localStructure = currentSelectedStructure.title;
                                } else {
                                    this.filters.localStructure = '';
                                }
                            } else {
                                this.filters.localStructure = this.loginService.getUser().localStructure;
                            }
                        }
                    });
                break;
            }
            case 'catalog': {
                this.showStructuresDropdown = false;
                this.filters.catalogLocalStructure = '';

                this.catalog
                    .filter((item) => item.selected === true)
                    .map((item) => {
                        if (
                            !this.loginService.getUser().roles.nationalAdmin &&
                            !this.loginService.getUser().roles.nationalTeacher
                        ) {
                            if (item.key === 'national') {
                                this.filters.catalogLocalStructure += '|';
                            } else {
                                this.filters.catalogLocalStructure += this.loginService.getUser().localStructure;
                            }
                        } else {
                            if (item.key === 'national') {
                                this.filters.catalogLocalStructure += '|';
                            } else if (item.key === 'local') {
                                this.showStructuresDropdown = true;
                                const currentSelectedStructure =
                                    this.localStructure &&
                                    this.localStructure.some((structure) => structure.selected)
                                        ? this.localStructure.find(
                                              (structure) => structure.selected
                                          )
                                        : undefined;
                                if (currentSelectedStructure) {
                                    this.filters.catalogLocalStructure +=
                                        currentSelectedStructure.title;
                                }
                            }
                        }
                    });
                break;
            }
            case 'localStructure': {
                this.filters.localStructure = this.localStructure
                    .filter((item) => item.selected)
                    .map((item: any) => {
                        this.loginService.updateLastStructure({ id: item.key, name: item.title });
                        return item.title;
                    })[0];
                this.filters.structureid =
                    this.localStructure &&
                    this.localStructure.some((structure) => structure.selected)
                        ? this.localStructure.find((item) => item.selected).key
                        : undefined;

                if (this.catalog.find((item) => item.key === 'national').selected) {
                    this.filters.localStructure += '|';
                }
                break;
            }
            case 'author': {
                this.filters.author = this.authors
                    .filter((item) => item.selected)
                    .map((item: any) => {
                        return item.key;
                    });
                break;
            }
            case 'archivedAuthors': {
                this.filters.archivedAuthor = this.archivedAuthors
                    .filter((item) => item.selected)
                    .map((item: any) => {
                        return item.key;
                    });
                break;
            }
            case 'search': {
                this.filters.search = this.search;
                break;
            }
            case 'rating': {
                if (this.rating.some((r) => r.selected)) {
                    this.filters.note = this.rating
                        .filter((e) => e.selected)
                        .map((i) => i.queryValues);
                } else {
                    this.filters.note = undefined;
                }
                break;
            }
            case 'status': {
                if (this.reportStatusList.some((e) => e.selected)) {
                    this.filters.status = this.reportStatusList
                        .filter((e) => e.selected)
                        .map((s) => s.key)
                        .join('|');
                } else {
                    this.filters.status = undefined;
                }
                break;
            }
            case 'myReportsOnly': {
                this.myReportsOnly = !this.myReportsOnly;
                this.filters.mine = this.myReportsOnly;
            }
        }
        this.filtersChange.emit(this.filters);
    }

    cancelFilters(emit?: boolean) {
        this.domain = this.domain.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.level = this.level.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.category = this.category.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.previousCategory = JSON.parse(JSON.stringify(this.category));
        this.type = this.type.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.library = this.library.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.catalog = this.catalog.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.localStructure = this.localStructure.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.authors = this.authors.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.archivedAuthors = this.archivedAuthors.map((entry: any) => {
            return {
                ...entry,
                selected: false
            };
        });
        this.rating = this.rating.map((entry: any) => ({
            ...entry,
            selected: false
        }));
        this.creationDateStart = null;
        this.creationDateEnd = null;
        this.modificationDateStart = null;
        this.modificationDateEnd = null;
        this.reportDateStart = null;
        this.reportDateEnd = null;
        this.showStructuresDropdown = false;
        this.search = '';
        this.filters = {
            domain: [],
            level: [],
            category: [],
            type: [],
            localStructure: '',
            structureid: 0,
            search: '',
            status: ReportStatus.created
        };
        if (emit) {
            this.filtersChange.emit(this.filters);
        }
    }

    cancelSearch() {
        this.search = '';
        this.updateFilters('search');
    }

    /**
     * toggles on/off the display of the dates dropdown
     */
    toggleDateFilterDropdown() {
        this.datesFilterOpened = !this.datesFilterOpened;
        if (this.datesFilterOpened) {
            if (
                // On recale le contenu en cas d'overflow
                this.libraryDatesfilterRef.nativeElement.getBoundingClientRect().x +
                    this.libraryDatesfilterDropdownContentRef.nativeElement.getBoundingClientRect()
                        .width >
                window.innerWidth
            ) {
                this.renderer.setStyle(
                    this.libraryDatesfilterDropdownContentRef.nativeElement,
                    'right',
                    0
                );
            } else {
                this.renderer.setStyle(
                    this.libraryDatesfilterDropdownContentRef.nativeElement,
                    'right',
                    'auto'
                );
            }
        }
    }

    /**
     * resets a date filter when clicking on the icon-Valider
     * @param filter the name of the filter : creation | modification (string)
     */
    resetDates(filter: string) {
        if (filter === 'creation') {
            this.creationDateEnd = null;
            this.creationDateStart = null;
            this.filters.timecreatedstart = null;
            this.filters.timecreatedend = null;
        } else if (filter === 'modification') {
            this.modificationDateEnd = null;
            this.modificationDateStart = null;
            this.filters.timemodifiedstart = null;
            this.filters.timemodifiedend = null;
        } else if (filter === 'report') {
            this.reportDateEnd = null;
            this.reportDateStart = null;
            this.filters.timereportedstart = null;
            this.filters.timereportedend = null;
        }
        this.filtersChange.emit(this.filters);
    }

    hasDatesFilters(): boolean {
        if (
            this.filters.timecreatedstart ||
            this.filters.timecreatedend ||
            this.filters.timemodifiedstart ||
            this.filters.timemodifiedend
        ) {
            return true;
        }
        return false;
    }

    isLibraryView() {
        return (
            !this.isCatalogView &&
            !this.isFavoritesView &&
            !this.isArchiveView &&
            !this.isReportView &&
            !this.isDisabledView &&
            !this.isPreviousVersionView
        );
    }

    toggleLibraryView() {
        this.isCatalogView = false;
        this.isFavoritesView = false;
        this.isArchiveView = false;
        this.isReportView = false;
        this.isDisabledView = false;
        this.isPreviousVersionView = false;

        this.viewChange.emit({
            catalog: this.isCatalogView,
            favorites: this.isFavoritesView,
            archive: this.isArchiveView,
            report: this.isReportView,
            disabled: this.isDisabledView,
            previousVersion: this.isPreviousVersionView
        });
        this.filtersChange.emit(this.filters);
    }

    toggleCatalogView() {
        this.isCatalogView = !this.isCatalogView;
        this.isFavoritesView = false;
        this.isArchiveView = false;
        this.isReportView = false;
        this.isDisabledView = false;
        this.isPreviousVersionView = false;

        this.viewChange.emit({
            catalog: this.isCatalogView,
            favorites: this.isFavoritesView,
            archive: this.isArchiveView,
            report: this.isReportView,
            disabled: this.isDisabledView,
            previousVersion: this.isPreviousVersionView
        });
        this.filtersChange.emit(this.filters);
    }

    toggleFavoritesView() {
        this.isCatalogView = false;
        this.isFavoritesView = !this.isFavoritesView;
        this.isArchiveView = false;
        this.isReportView = false;
        this.isDisabledView = false;
        this.isPreviousVersionView = false;

        this.viewChange.emit({
            catalog: this.isCatalogView,
            favorites: this.isFavoritesView,
            archive: this.isArchiveView,
            report: this.isReportView,
            disabled: this.isDisabledView,
            previousVersion: this.isPreviousVersionView
        });
        this.filtersChange.emit(this.filters);
    }

    toggleArchiveView(): void {
        this.isCatalogView = false;
        this.isFavoritesView = false;
        this.isArchiveView = !this.isArchiveView;
        this.isReportView = false;
        this.isDisabledView = false;
        this.isPreviousVersionView = false;

        this.viewChange.emit({
            catalog: this.isCatalogView,
            favorites: this.isFavoritesView,
            archive: this.isArchiveView,
            report: this.isReportView,
            disabled: this.isDisabledView,
            previousVersion: this.isPreviousVersionView
        });
        this.filtersChange.emit(this.filters);
    }

    toggleReportView(): void {
        this.isCatalogView = false;
        this.isFavoritesView = false;
        this.isArchiveView = false;
        this.isReportView = !this.isReportView;
        this.isDisabledView = false;
        this.isPreviousVersionView = false;

        this.updateFilters('localStructure');
        this.updateFilters('library');

        this.viewChange.emit({
            catalog: this.isCatalogView,
            favorites: this.isFavoritesView,
            archive: this.isArchiveView,
            report: this.isReportView,
            disabled: this.isDisabledView,
            previousVersion: this.isPreviousVersionView
        });
        this.filtersChange.emit(this.filters);
    }

    toggleDisabledView(): void {
        this.isCatalogView = false;
        this.isFavoritesView = false;
        this.isArchiveView = false;
        this.isReportView = false;
        this.isDisabledView = !this.isDisabledView;
        this.isPreviousVersionView = false;

        this.viewChange.emit({
            catalog: this.isCatalogView,
            favorites: this.isFavoritesView,
            archive: this.isArchiveView,
            report: this.isReportView,
            disabled: this.isDisabledView,
            previousVersion: this.isPreviousVersionView
        });
        this.filtersChange.emit(this.filters);
    }

    togglePreviousVersionView(): void {
        this.isCatalogView = false;
        this.isFavoritesView = false;
        this.isArchiveView = false;
        this.isReportView = false;
        this.isDisabledView = false;
        this.isPreviousVersionView = !this.isPreviousVersionView;

        this.viewChange.emit({
            catalog: this.isCatalogView,
            favorites: this.isFavoritesView,
            archive: this.isArchiveView,
            report: this.isReportView,
            disabled: this.isDisabledView,
            previousVersion: this.isPreviousVersionView
        });
        this.filtersChange.emit(this.filters);
    }

    canShowReportView(): boolean {
        return (
            this.loginService.isNationalAdmin() ||
            this.loginService.isLocalAdmin() ||
            this.loginService.getUser().roles.internalTeacher
        );
    }

    /**
     * Closes the dates dropdown if click ouside while opened
     */
    onClickOutsideDatesDropdown($event) {
        if (
            this.datesFilterOpened &&
            $event.target.nodeName !== 'BODY' &&
            !$event.target.matches("[class*='mat-calendar']") &&
            !$event.target.matches("[class*='mat-button-wrapper']")
        ) {
            this.toggleDateFilterDropdown();
        }
    }

    getSelectionLength() {
        return this.multiselectService.getSelectedListLength();
    }

    unselectAll() {
        this.multiselectService.resetSelection();
        this.libraryService.emitDetectChanges();
    }
}
