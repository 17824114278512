import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { GroupService } from 'src/app/services/group.service';
import { LibraryService } from 'src/app/services/library.service';
import { UserService } from 'src/app/services/user.service';
import { Assignment } from 'src/app/structures/assignment';
import { Group } from 'src/app/structures/group';
import { User } from 'src/app/structures/user';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-upload-devoir-correction',
    templateUrl: './upload-devoir-correction.component.html',
    styleUrls: ['./upload-devoir-correction.component.scss']
})
export class UploadDevoirCorrectionComponent implements OnInit {
    @ViewChild('fileElement', { static: true }) fileElement: ElementRef;
    file: File;
    fileToUpload: File;

    uploadCorrection$: Subscription;

    constructor(
        private libraryService: LibraryService,
        private userService: UserService,
        private groupService: GroupService,
        private dialogRef: MatDialogRef<UploadDevoirCorrectionComponent>,
        private flashMessageService: FlashMessageService,
        @Inject(MAT_DIALOG_DATA) public data: { assignment: Assignment; user: User; group: Group }
    ) {}

    ngOnInit() {
        this.fileToUpload = undefined;
    }

    getIconStyle(): string {
        return this.libraryService.getIcon(this.data.assignment);
    }

    getAssignmentTitle(): string {
        return this.data.assignment.title;
    }

    getAssignmentTarget(): string {
        if (this.data.user) {
            return this.data.user.lastname.toUpperCase() + ' ' + this.data.user.firstname;
        } else {
            return this.data.assignment.source;
        }
    }

    getAssignmentInformation(): string {
        const options: any = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        };

        if (this.data.assignment.timestart && !this.data.assignment.timeend) {
            return `Devoir à rendre à partir du ${new Date(
                this.data.assignment.timestart
            ).toLocaleString('fr-FR', options)}`;
        } else if (this.data.assignment.timestart && this.data.assignment.timeend) {
            return `Devoir à rendre du ${new Date(this.data.assignment.timestart).toLocaleString(
                'fr-FR',
                options
            )} au ${new Date(this.data.assignment.timeend).toLocaleString('fr-FR', options)} `;
        } else if (!this.data.assignment.timestart && this.data.assignment.timeend) {
            return `Devoir à rendre jusqu'au ${new Date(
                this.data.assignment.timeend
            ).toLocaleString('fr-FR', options)} `;
        } else {
            return ``;
        }
    }

    uploadFile() {
        this.fileElement.nativeElement.click();
    }

    onFilesAdded(event: any) {
        event.preventDefault();
        if (event.dataTransfer) {
            this.fileToUpload = event.dataTransfer.files[0];
        } else if (event.target) {
            this.fileToUpload = event.target.files[0];
        }
        this.fileElement.nativeElement.value = '';
    }

    deleteFile() {
        this.fileToUpload = undefined;
        this.fileElement.nativeElement.value = '';
    }

    uploadDevoir() {
        if (this.data.user) {
            this.uploadCorrection$ = this.userService
                .saveUserDevoirCorrection({
                    assignment: this.data.assignment,
                    userId: this.data.user.userid,
                    correctionFile: this.fileToUpload
                })
                .subscribe((resp: any) => {
                    this.dialogRef.close(resp);
                    this.flashMessageService.flash('le corrigé a bien été enregistré');
                });
        } else {
            this.uploadCorrection$ = this.groupService
                .saveGroupDevoirCorrection({
                    assignment: this.data.assignment,
                    groupId: this.data.group.id,
                    correctionFile: this.fileToUpload
                })
                .subscribe((resp: any) => {
                    this.flashMessageService.flash('le corrigé général a bien été enregistré');
                    this.dialogRef.close(resp);
                });
        }
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
