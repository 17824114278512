<div class="main-container">
    <div class="video-container" *ngIf="!isLoading()">
        <vg-player>
            <vg-buffering></vg-buffering>
            <vg-overlay-play></vg-overlay-play>
            <vg-controls>
                <vg-play-pause></vg-play-pause>
                <vg-playback-button
                    [playbackValues]="['0.25', '0.5','1.0', '1.25', '1.5', '2.0',]"
                ></vg-playback-button>
                <vg-time-display vgProperty="current" vgFormat="hh:mm:ss"></vg-time-display>

                <vg-scrub-bar [vgSlider]="true">
                    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                </vg-scrub-bar>

                <vg-time-display vgProperty="total" vgFormat="hh:mm:ss"></vg-time-display>
                <vg-mute></vg-mute>
                <vg-volume></vg-volume>

                <vg-fullscreen></vg-fullscreen>
            </vg-controls>
            <video [vgMedia]="media" #media id="uimm-video" preload="auto">
                <source src="{{ getVideoSrc() }}" type="{{ getVideoType() }}" />
            </video>
        </vg-player>
    </div>
    <div class="description-container" *ngIf="!isLoading()">
        <div class="legend">Description</div>
        <div class="description-label" [innerHTML]="getDescription()"></div>
    </div>
    <app-loading *ngIf="isLoading()"></app-loading>
</div>
