<div class="providers-count">
    <b>{{ getProvidersCount() }}</b> fournisseur
</div>
<div class="scroll-container" *ngIf="!isLoading()">
    <div class="provider-entry" *ngFor="let provider of providers">
        <div class="icon-JustificationGauche"></div>
        <div class="label">{{ provider.name }}</div>
        <div class="count" [innerHTML]="getProvidersContentCount(provider)"></div>
    </div>
</div>
<app-loading *ngIf="isLoading()"></app-loading>
<div class="add-provider">
    <div
        class="icon-plus"
        [tooltip]="'Créer un nouveau fournisseur'"
        (click)="openCreateProvider()"
    ></div>
</div>
