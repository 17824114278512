<div class="header">
    <div class="icon-flag"></div>
    <div class="title">Signaler une erreur</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content">
    <div class="entry-container">
        <div class="entry">
            <div class="entry-icon" [ngClass]="getIcon()"></div>
            <div class="entry-title">{{ getTitle() }}</div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="main-container">
        <div class="legend">
            Soyez le plus précis et complet possible dans votre signalement. Par exemple, donnez le
            numro de diapositive ou de question.
        </div>
        <textarea [(ngModel)]="comment" placeholder="Ecrivez ici votre message"></textarea>
        <div class="button" (click)="openSelectFile()">
            <div class="icon-trombonne"></div>
            <div class="file-name" *ngIf="!file">Joindre un fichier</div>
            <div class="file-name" *ngIf="file">Fichier actuel : {{ file.name }}</div>
        </div>
        <input class="hidden" type="file" #fileInput (change)="uploadFile($event.target.files)" />
        <div class="legend">Tout signalement trop approximatif ne sera pas pris en compte.</div>
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="button" (click)="reportError()" [ngClass]="{ disabled: !canReportError() }">
        <span *ngIf="!isReportSending()"> Signaler l'activité </span>
        <span *ngIf="isReportSending()"><app-loading></app-loading></span>
    </div>
</div>
