<div class="header">
    <div class="icon-creer-assemblage"></div>
    <div class="title">{{ getTitle() }}</div>
    <div class="icon-close" (click)="closeDialog()" *ngIf="!isImportStarted()"></div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading('massImportMainView')">
    <div class="import">
        <div class="label">Fichier CSV</div>
        <div class="value" appFileDropzone (fileDropped)="uploadFile($event)">
            {{ getCurrentFileLabel() }}
        </div>
        <div
            class="icon-Importer"
            (click)="openSelectFile()"
            [tooltip]="'Sélectionner un fichier CSV'"
        ></div>
        <input class="hidden" type="file" #fileInput (change)="uploadFile($event.target.files)" />
    </div>
</mat-dialog-content>
<mat-dialog-content class="content loading" *ngIf="isLoading('massImportMainView')">
    <app-loading></app-loading>
</mat-dialog-content>
<div class="footer">
    <div
        class="button"
        (click)="startImport()"
        *ngIf="!isImportStarted() && !isLoading('massImportFooterView')"
    >
        Lancer l'import
    </div>
    <div class="button" *ngIf="isLoading('massImportFooterView')"><app-loading></app-loading></div>
    <div class="button" *ngIf="isImportFinished()" (click)="closeDialog()">Fermer</div>
</div>
