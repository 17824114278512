<div class="header">
    <span class="icon-Assignationmultiple"></span>
    <div class="title">Assignation avancée</div>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <div class="content-header">
        <div class="content-title" *ngFor="let content of data">
            <span class="icon" [ngClass]="getIcon(content)"></span>
            <span class="title">{{ getTitle(content) }}</span>
        </div>
    </div>
    <div class="main-content">
        <div class="filters-container">
            <div
                class="counter first"
                [ngClass]="{ disabled: !isView('student') }"
                (click)="toggleView('student')"
            >
                <strong>{{ getSelectedStudentsCount() }} / {{ getStudentsCount() }}</strong>
                Apprenants
            </div>
            <div
                class="counter second"
                [ngClass]="{ disabled: !isView('groupStudent') }"
                (click)="toggleView('groupStudent')"
            >
                <strong
                    >{{ getSelectedGroupsStudentCount() }} / {{ getGroupsStudentCount() }}</strong
                >
                Groupes d'apprenants
            </div>
            <div
                class="counter third"
                [ngClass]="{ disabled: !isView('teacher') }"
                (click)="toggleView('teacher')"
            >
                <strong>{{ getSelectedTeachersCount() }} / {{ getTeachersCount() }}</strong>
                Formateurs
            </div>
            <div
                class="counter forth"
                [ngClass]="{ disabled: !isView('groupTeacher') }"
                (click)="toggleView('groupTeacher')"
            >
                <strong
                    >{{ getSelectedGroupsTeacherCount() }} / {{ getGroupsTeacherCount() }}</strong
                >
                Groupes de formateurs
            </div>
            <div
                class="counter fifth"
                [ngClass]="{ disabled: !isView('groupRole') }"
                (click)="toggleView('groupRole')"
            >
                <strong>{{ getSelectedGroupsRoleCount() }} / {{ getGroupsRoleCount() }}</strong>
                Groupes de rôles
            </div>
            <div class="filter" *ngIf="showStructuresDropdown()">
                <lib-uimm-dropdown
                    [label]="'Structure'"
                    [items]="structures"
                    (itemsChange)="updateStructures($event)"
                    [labelWidth]="'100px'"
                ></lib-uimm-dropdown>
            </div>
            <div class="search-container">
                <input
                    class="library-search-field"
                    [(ngModel)]="searchTerm"
                    (keyup.enter)="refreshSearch()"
                    placeholder="Chercher..."
                />
                <span class="icon-search" (click)="refreshSearch()"></span>
                <span class="icon-Fermerdetails" *ngIf="searchTerm" (click)="cancelSearch()"></span>
            </div>
        </div>
        <div
            class="dialog-list"
            infiniteScroll
            [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="nextPage()"
        >
            <div *ngIf="isView('student')">
                <div class="student" *ngFor="let student of selectedStudents">
                    <div class="icon" [ngClass]="getUserIcon(student)"></div>
                    <div class="user-name">
                        {{ student.lastname.toUpperCase() }} {{ student.firstname }}
                    </div>
                    <div class="checkbox icon-selected" (click)="selectStudent(student)"></div>
                </div>
                <div *ngFor="let student of students">
                    <div class="student" *ngIf="!student.selected">
                        <div class="icon" [ngClass]="getUserIcon(student)"></div>
                        <div class="user-name">
                            {{ student.lastname.toUpperCase() }} {{ student.firstname }}
                        </div>
                        <div class="checkbox icon-select" (click)="selectStudent(student)"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="isView('groupStudent')">
                <div class="group" *ngFor="let groupStudent of selectedGroupsStudent">
                    <div class="icon-groupe"></div>
                    <div class="group-name">{{ groupStudent.name }}</div>
                    <div
                        class="checkbox icon-selected"
                        (click)="selectGroupStudent(groupStudent)"
                    ></div>
                    <div
                        class="icon-down"
                        [ngClass]="{ opened: groupStudent.opened }"
                        (click)="toggleGroupStudent($event, groupStudent)"
                    ></div>
                    <div
                        class="users-container"
                        [@slideToggle]="groupStudent.opened ? 'open' : 'closed'"
                    >
                        <div class="user" *ngFor="let user of groupStudent.users">
                            <div class="icon" [ngClass]="getUserIcon(user)"></div>
                            <div class="user-name">
                                {{ user.lastname.toUpperCase() }} {{ user.firstname }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngFor="let groupStudent of groupsStudent">
                    <div class="group" *ngIf="!groupStudent.selected">
                        <div class="icon-groupe"></div>
                        <div class="group-name">{{ groupStudent.name }}</div>
                        <div
                            class="checkbox icon-select"
                            (click)="selectGroupStudent(groupStudent)"
                        ></div>
                        <div
                            class="icon-down"
                            [ngClass]="{ opened: groupStudent.opened }"
                            (click)="toggleGroupStudent($event, groupStudent)"
                        ></div>
                        <div
                            class="users-container"
                            [@slideToggle]="groupStudent.opened ? 'open' : 'closed'"
                        >
                            <div class="user" *ngFor="let user of groupStudent.users">
                                <div class="icon" [ngClass]="getUserIcon(user)"></div>
                                <div class="user-name">
                                    {{ user.lastname.toUpperCase() }} {{ user.firstname }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isView('teacher')">
                <div class="student" *ngFor="let teacher of selectedTeachers">
                    <div class="icon" [ngClass]="getUserIcon(teacher)"></div>
                    <div class="user-name">
                        {{ teacher.lastname.toUpperCase() }} {{ teacher.firstname }}
                    </div>
                    <div class="checkbox icon-selected" (click)="selectTeacher(teacher)"></div>
                </div>
                <div *ngFor="let teacher of teachers">
                    <div class="student" *ngIf="!teacher.selected">
                        <div class="icon" [ngClass]="getUserIcon(teacher)"></div>
                        <div class="user-name">
                            {{ teacher.lastname.toUpperCase() }} {{ teacher.firstname }}
                        </div>
                        <div class="checkbox icon-select" (click)="selectTeacher(teacher)"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="isView('groupTeacher')">
                <div class="group" *ngFor="let groupTeacher of selectedGroupsTeacher">
                    <div class="icon-groupe"></div>
                    <div class="group-name">{{ groupTeacher.name }}</div>
                    <div
                        class="checkbox icon-selected"
                        (click)="selectGroupTeacher(groupTeacher)"
                    ></div>
                    <div
                        class="icon-down"
                        [ngClass]="{ opened: groupTeacher.opened }"
                        (click)="toggleGroupTeacher($event, groupTeacher)"
                    ></div>
                    <div
                        class="users-container"
                        [@slideToggle]="groupTeacher.opened ? 'open' : 'closed'"
                    >
                        <div class="user" *ngFor="let user of groupTeacher.users">
                            <div class="icon" [ngClass]="getUserIcon(user)"></div>
                            <div class="user-name">
                                {{ user.lastname.toUpperCase() }} {{ user.firstname }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngFor="let groupTeacher of groupsTeacher">
                    <div class="group" *ngIf="!groupTeacher.selected">
                        <div class="icon-groupe"></div>
                        <div class="group-name">{{ groupTeacher.name }}</div>
                        <div
                            class="checkbox icon-select"
                            (click)="selectGroupTeacher(groupTeacher)"
                        ></div>
                        <div
                            class="icon-down"
                            [ngClass]="{ opened: groupTeacher.opened }"
                            (click)="toggleGroupTeacher($event, groupTeacher)"
                        ></div>
                        <div
                            class="users-container"
                            [@slideToggle]="groupTeacher.opened ? 'open' : 'closed'"
                        >
                            <div class="user" *ngFor="let user of groupTeacher.users">
                                <div class="icon" [ngClass]="getUserIcon(user)"></div>
                                <div class="user-name">
                                    {{ user.lastname.toUpperCase() }} {{ user.firstname }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isView('groupRole')">
                <div class="group" *ngFor="let groupRole of selectedGroupsRole">
                    <div class="icon-groupe"></div>
                    <div class="group-name">{{ groupRole.name }}</div>
                    <div class="checkbox icon-selected" (click)="selectGroupRole(groupRole)"></div>
                </div>
                <div *ngFor="let groupRole of groupsRole">
                    <div class="group" *ngIf="!groupRole.selected">
                        <div class="icon-groupe"></div>
                        <div class="group-name">{{ groupRole.name }}</div>
                        <div
                            class="checkbox icon-select"
                            (click)="selectGroupRole(groupRole)"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <app-loading *ngIf="isLoading()"></app-loading>
    </div>
</div>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{ disabled: !canAssignContent() }"
        (click)="startAssign()"
    >
        Assigner
    </div>
</div>
