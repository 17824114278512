<div class="header">
    <span class="icon-personnaliserassignationgroupe"></span>
    <span class="title"
        >Personnaliser l'assignation du groupe {{ assignment.enrolmentSource }}</span
    >
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <div class="top">
        <div class="entry">
            <span class="entry-icon" [ngClass]="getIcon()"></span>
            <span class="entry-title">{{ assignment.title }}</span>
        </div>
        <div class="sub-top">
            <span
                >Assigné à <b>{{ getPersonnalizationCountMessage() }}</b> apprenants</span
            >
            <div class="float-container">
                <span class="assign-all" (click)="assignToAll()">
                    {{ getAssignAllMessage() }}
                    <span class="icon-assigner" *ngIf="!showAssignIcon()"></span>
                    <span class="icon-desassigne" *ngIf="showAssignIcon()"></span>
                </span>
                <div class="search-container">
                    <input type="text" [(ngModel)]="searchInput" placeholder="Chercher..." />
                    <span class="icon-search" *ngIf="searchInput.length == 0"></span>
                    <span
                        class="icon-Fermerdetails"
                        (click)="cancelSearch()"
                        *ngIf="searchInput.length > 0"
                    ></span>
                </div>
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="main-body">
        <ng-container *ngFor="let user of assignment.users_status">
            <div
                class="user-container"
                *ngIf="inSearchResult(user)"
                [ngClass]="{
                    modified: isModifiedStatus(user),
                    desassigned: isAssignmentHidden(user)
                }"
            >
                <span class="icon-apprenant"></span>
                <span class="user-name">{{ getUserName(user) }}</span>
                <div class="icon-container">
                    <span
                        class="icon-desassigne"
                        (click)="toggleAssignmentVisibility(user)"
                        *ngIf="isAssignmentHidden(user)"
                        [tooltip]="'Assigner'"
                    ></span>
                    <span
                        class="icon-assigner"
                        (click)="toggleAssignmentVisibility(user)"
                        *ngIf="!isAssignmentHidden(user)"
                        [tooltip]="'Désassigner'"
                    ></span>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="separator"></div>
    <div class="lower-message">
        Modifiez les assignations apprenant par apprenant. Les nouveaux membres du groupe se verront
        assigner ce contenu tant qu'il sera dans la liste des assignations de ce groupe.
    </div>
</div>
<div class="footer">
    <div class="button" (click)="applyPersonnalization()" *ngIf="!isLoading()">Appliquer</div>
    <div class="button" *ngIf="isLoading()"><app-loading></app-loading></div>
</div>
