<div class="header">
    <span class="icon-easi-media-line"></span>
    <div class="title">Création à partir d'easi media</div>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <div class="label">
        Vous vous apprêtez à ouvrir <b>easi media</b> pour créer une nouvelle activité.<br />
        Elle ne sera visible que par vous tant que vous ne l'aurez pas publiée dans
        <b>easi training</b>
    </div>
    <div class="toggle">
        <div
            class="icon"
            [ngClass]="isCookie() ? 'icon-selected' : 'icon-select'"
            (click)="toggleCookie()"
        ></div>
        <div class="legend">
            Ne plus me demander et ouvrir systématiquement dans <b>easi media</b>.
        </div>
    </div>
</div>
<div class="footer">
    <div class="button" (click)="goToMedia()">Ouvrir easi media</div>
</div>
