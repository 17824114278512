<div class="tabs-container" *ngIf="showTabsContainer()">
    <div
        class="tab"
        *ngIf="showAssignmentTabs()"
        [ngClass]="{ active: !isCreationSpace() && !isValidationSpace() }"
        (click)="goTo('teacher/panel')"
    >
        <div class="label">Assignations</div>
    </div>
    <div
        class="tab"
        *ngIf="showCreationTabs()"
        [ngClass]="{ active: isCreationSpace() }"
        (click)="goTo('teacher/creation')"
    >
        <div class="label">Création</div>
    </div>
    <div
        class="tab"
        *ngIf="showValidationTabs()"
        [ngClass]="{ active: isValidationSpace() }"
        (click)="goTo('teacher/validation')"
    >
        <div class="label">Validation</div>
    </div>
    <div
        class="icon-minimizebloc"
        (click)="toggleLeftPanel()"
        [ngClass]="{ closed: reduced }"
    ></div>
</div>
