<div class="student-panel-container" [ngClass]="{ hidden: isLoading('studentPanel') }">
    <div class="panel-header">
        <div
            class="header"
            [ngClass]="{ active: isCurrentView('student'), favorite: isFavorite() }"
            (click)="toggleView('student')"
        >
            <b>{{ getStudentsCount() }}</b> Apprenants
        </div>
        <div
            class="header"
            *ngIf="canViewGroups()"
            [ngClass]="{ active: isCurrentView('student-group'), favorite: isFavorite() }"
            (click)="toggleView('student-group')"
        >
            <b>{{ getStudentGroupsCount() }}</b> Groupes d'apprenants
        </div>
        <div
            class="header"
            *ngIf="canViewTeachers()"
            [ngClass]="{ active: isCurrentView('teacher'), favorite: isFavorite() }"
            (click)="toggleView('teacher')"
        >
            <b>{{ getTeachersCount() }}</b> Formateurs
        </div>
        <div
            class="header"
            *ngIf="canViewGroups() && canViewTeachers()"
            [ngClass]="{ active: isCurrentView('teacher-group'), favorite: isFavorite() }"
            (click)="toggleView('teacher-group')"
        >
            <b>{{ getTeacherGroupsCount() }}</b> Groupes de formateurs
        </div>
        <div class="splitter" *ngIf="canViewRoleGroups()">|</div>

        <div
            class="header"
            *ngIf="canViewRoleGroups()"
            [ngClass]="{ active: isCurrentView('role-group'), favorite: isFavorite() }"
            (click)="toggleView('role-group')"
        >
            <b>{{ getRoleGroupsCount() }}</b> Groupes de rôles
        </div>
        <lib-uimm-dropdown
            class="structures-dropdown"
            *ngIf="isNationalAdmin()"
            [label]="'Structures'"
            [(items)]="structures"
            (itemsChange)="updateStructure($event)"
            [labelWidth]="'120px'"
            [labelColor]="'white'"
            [maxWidth]="350"
            [maxHeight]="250"
            [design]="'boxes'"
            [headers]="{ search: true, count: true }"
            [customClass]="'right'"
        ></lib-uimm-dropdown>
    </div>
    <div class="panel-subheader">
        <div class="search-container" [ngClass]="{ favorite: isFavorite() }">
            <input
                class="library-search-field"
                [(ngModel)]="search"
                placeholder="Chercher..."
                (keyup.enter)="updateSearch()"
            />
            <span class="icon-search" (click)="updateSearch()"></span>
            <span class="icon-Fermerdetails" *ngIf="search" (click)="cancelSearch()"></span>
        </div>
        <div
            class="icon-contenusfavoris"
            [ngClass]="{ favorite: isFavorite() }"
            (click)="toggleFavorites()"
        ></div>
    </div>
    <div
        class="body"
        *ngIf="users"
        [ngClass]="{ hidden: isLoading('studentPanelBody') }"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="nextPage()"
    >
        <div
            class="container"
            [ngClass]="{
                hidden:
                    !isCurrentView('role-group') ||
                    (isCurrentView('role-group') && isFavoriteView())
            }"
        >
            <app-role-group
                *ngFor="let roleGroup of users.default.roleGroups"
                [roleGroup]="roleGroup"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-role-group>
        </div>
        <div
            class="container"
            [ngClass]="{
                hidden:
                    !isCurrentView('role-group') ||
                    (isCurrentView('role-group') && !isFavoriteView())
            }"
        >
            <app-role-group
                *ngFor="let roleGroup of users.favorites.roleGroups"
                [roleGroup]="roleGroup"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-role-group>
        </div>

        <div
            class="container"
            [ngClass]="{
                hidden: !isCurrentView('student') || (isCurrentView('student') && isFavoriteView())
            }"
        >
            <app-user
                *ngFor="let student of users.default.students"
                [user]="student"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-user>
        </div>
        <div
            class="container"
            [ngClass]="{
                hidden: !isCurrentView('student') || (isCurrentView('student') && !isFavoriteView())
            }"
        >
            <app-user
                *ngFor="let student of users.favorites.students"
                [user]="student"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-user>
        </div>

        <div
            class="container"
            [ngClass]="{
                hidden:
                    !isCurrentView('student-group') ||
                    (isCurrentView('student-group') && isFavoriteView())
            }"
        >
            <app-group
                *ngFor="let studentGroup of users.default.studentGroups"
                [group]="studentGroup"
                [displayCloudIcon]="shouldDisplayCloudIcon()"
                [nextcloudLink]="getNextCloudLink(studentGroup)"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-group>
        </div>
        <div
            class="container"
            [ngClass]="{
                hidden:
                    !isCurrentView('student-group') ||
                    (isCurrentView('student-group') && !isFavoriteView())
            }"
        >
            <app-group
                *ngFor="let studentGroup of users.favorites.studentGroups"
                [group]="studentGroup"
                [displayCloudIcon]="shouldDisplayCloudIcon()"
                [nextcloudLink]="getNextCloudLink(studentGroup)"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-group>
        </div>

        <div
            class="container"
            [ngClass]="{
                hidden: !isCurrentView('teacher') || (isCurrentView('teacher') && isFavoriteView())
            }"
        >
            <app-user
                *ngFor="let teacher of users.default.teachers"
                [user]="teacher"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-user>
        </div>
        <div
            class="container"
            [ngClass]="{
                hidden: !isCurrentView('teacher') || (isCurrentView('teacher') && !isFavoriteView())
            }"
        >
            <app-user
                *ngFor="let teacher of users.favorites.teachers"
                [user]="teacher"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-user>
        </div>

        <div
            class="container"
            [ngClass]="{
                hidden:
                    !isCurrentView('teacher-group') ||
                    (isCurrentView('teacher-group') && isFavoriteView())
            }"
        >
            <app-group
                *ngFor="let teacherGroup of users.default.teacherGroups"
                [group]="teacherGroup"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-group>
        </div>
        <div
            class="container"
            [ngClass]="{
                hidden:
                    !isCurrentView('teacher-group') ||
                    (isCurrentView('teacher-group') && !isFavoriteView())
            }"
        >
            <app-group
                *ngFor="let teacherGroup of users.favorites.teacherGroups"
                [group]="teacherGroup"
                (favoriteAdded)="addToFavorites($event)"
                (favoriteRemoved)="removeFromFavorites($event)"
            ></app-group>
        </div>
    </div>
    <app-loading class="body" *ngIf="isLoading('studentPanelBody')"></app-loading>
    <app-loading
        class="loading-next-page"
        *ngIf="isLoading('studentPanelBodyNextPage')"
    ></app-loading>
</div>
<app-loading class="student-panel-container" *ngIf="isLoading('studentPanel')"></app-loading>
