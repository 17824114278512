import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { LoginService } from '../../services/login.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-exchange',
    templateUrl: './exchange.component.html',
    styleUrls: ['./exchange.component.scss']
})
export class ExchangeComponent implements OnInit {
    applications = [];
    subscriptions = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<ExchangeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.subscriptions.add(
            this.loginService.getOurApplication(this.data).subscribe((data: any) => {
                this.applications = data;
            })
        );
    }

    canView = function (appid) {
        for (const i in this.applications) {
            if (this.applications[i].app_id === appid) {
                return true;
            }
        }
        return false;
    };

    goToApp = function (appid) {
        for (const i in this.applications) {
            if (this.applications[i].app_id === appid) {
                window.open(
                    `${this.applications[i].frontend_url}/#/?shared=true&type=${
                        this.data.firstname ? 'user' : 'group'
                    }&id=${this.data.id}`,
                    '_blank'
                );
            }
        }
    };

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
