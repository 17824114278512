import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LibraryService } from '../../../services/library.service';
import { DialogService } from '../../../services/dialog.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { LoadingService } from '../../../services/loading.service';

import { Provider } from '../../../structures/provider';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-providers',
    templateUrl: './providers.component.html',
    styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {
    providers: Array<Provider>;

    subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.providers = [];
        this.loadingService.startLoading('getProviders');
        this.subscriptions.add(
            this.libraryService.getProviders().subscribe((data: Array<Provider>) => {
                this.loadingService.stopLoading('getProviders');
                this.providers = data;
            })
        );
    }

    getProvidersContentCount(provider) {
        return `(<b>${provider.count.parcours}</b> parcours, <b>${provider.count.bloc}</b> blocs, <b>${provider.count.competence}</b> compétences, <b>${provider.count.module}</b> modules, <b>${provider.count.sequence}</b> séquences et <b>${provider.count.activity}</b> activités)`;
    }

    isLoading() {
        return this.loadingService.isLoading('getProviders');
    }

    getProvidersCount() {
        return this.providers.length;
    }

    openCreateProvider() {
        this.subscriptions.add(
            this.dialogService.openCreateProvider().subscribe((data: string) => {
                if (data) {
                    this.flashMessageService.flash(`Le fournisseur <b>${data}</b> a été créé`);
                }
            })
        );
    }
}
