import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-devoir-confirmation',
    templateUrl: './devoir-confirmation.component.html',
    styleUrls: ['./devoir-confirmation.component.scss']
})
export class DevoirConfirmationComponent {
    @Output() openConfirmationDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<DevoirConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    /**
     * Détermine le message à afficher dans le corps de la fenêtre
     * @returns {String} Le message à afficher dans le corps de la fenêtre
     */
    getMainMessage(): String {
        return this.data.message;
    }

    getTitle(): String {
        return this.data.title;
    }

    getYesButtonValue(): string {
        return this.data.buttonsValues ? this.data.buttonsValues.yes : 'OUI';
    }

    getNoButtonValue(): string {
        return this.data.buttonsValues ? this.data.buttonsValues.no : 'NON';
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Supprime le forum actuel
     */
    action() {
        this.dialogRef.close(true);
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close(false);
    }
}
